import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

const SignIn: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  }, []);

  return <div></div>;
};

export default SignIn;
