import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { faAt, faCircleInfo, faGlobe, faPerson, faPhone } from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import type { Client, Lead } from "../../../../../client";
import {
  ImageButton,
  Input,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  isEmailOrEmpty,
  isPhoneNumberOrEmpty,
  isUrlOrEmpty,
  trim,
} from "../../../../../components";
import { getImageUrl } from "../../../../../utils/image";
import { LeadRadioRowCardSection } from "../card/LeadRadioRowCardSection";
import { LeadRowCard } from "../card/LeadRowCard";
import { LeadRowCardSection } from "../card/LeadRowCardSection";
import { ExpandableSection } from "./ExpandableSection";
import { PanelContainer } from "./PanelContainer";

interface Props {
  client?: Client;
  clients?: Client[];
  lead?: Lead;
  onMutateLead: (lead: Partial<Lead>) => void;
  onMutateClient: (client: Partial<Client>) => void;
}

type SelectedClientType = "existing" | "new";

export const ClientPanel = ({ client, clients, lead, onMutateLead, onMutateClient }: Props) => {
  const [selectedClientType, setSelectedClientType] = useState<SelectedClientType>("existing");
  return (
    <PanelContainer>
      <div className="flex flex-col gap-4">
        <LeadRowCard type="solid">
          <LeadRadioRowCardSection
            title="Pick existing client"
            selected={selectedClientType === "existing"}
            onCheckedChange={(checked) => setSelectedClientType(checked ? "existing" : "new")}
          >
            <AnimatePresence>
              {selectedClientType === "existing" && (
                <ExpandableSection className="grid grid-cols-5 gap-4 w-full">
                  {clients?.map((c) => (
                    <TooltipProvider key={c.id} delayDuration={0}>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div className="w-min">
                            <ImageButton
                              alt={c.name}
                              imageUrl={c.logoId ? getImageUrl(c.logoId) : undefined}
                              active={c.id === client?.id}
                              onClick={() => onMutateLead({ clientId: c.id })}
                            />
                          </div>
                        </TooltipTrigger>
                        {c.name && (
                          <TooltipContent>
                            <p>{c.name}</p>
                          </TooltipContent>
                        )}
                      </Tooltip>
                    </TooltipProvider>
                  ))}
                </ExpandableSection>
              )}
            </AnimatePresence>
          </LeadRadioRowCardSection>
        </LeadRowCard>
        <LeadRowCard type="solid">
          <LeadRadioRowCardSection
            title="Add new client"
            selected={selectedClientType === "new"}
            onCheckedChange={(checked) => setSelectedClientType(checked ? "new" : "existing")}
          >
            <AnimatePresence>
              {selectedClientType === "new" && (
                <ExpandableSection className="flex flex-col gap-2">
                  <p className="text-sm">Add domain or e-mail to see magic!</p>
                  <Input
                    leftIcon={faAt}
                    label="E-mail"
                    transformValue={trim}
                    validate={isEmailOrEmpty}
                    type="email"
                  />
                  <Input
                    leftIcon={faGlobe}
                    label="Domain"
                    transformValue={trim}
                    validate={isUrlOrEmpty}
                    type="url"
                  />
                </ExpandableSection>
              )}
            </AnimatePresence>
          </LeadRadioRowCardSection>
        </LeadRowCard>
      </div>
      <LeadRowCard type="dashed">
        <LeadRowCardSection title="Client information">
          <Input
            leftIcon={faBuilding}
            label="Client name"
            value={client?.name}
            onFinishWriting={(clientName) => onMutateClient({ name: clientName })}
            transformValue={trim}
          />
          <Input
            leftIcon={faGlobe}
            label="Client website"
            value={client?.url}
            onFinishWriting={(clientUrl) => onMutateClient({ url: clientUrl })}
            transformValue={trim}
            validate={isUrlOrEmpty}
            rightButton={
              client?.url
                ? {
                    label: "Visit",
                    onClick: () => {
                      window.open(
                        // Ensure the URL starts with http:// or https://
                        client.url.startsWith("http") ? client.url : `https://${client.url}`,
                        "_blank"
                      );
                    },
                  }
                : undefined
            }
            type="url"
          />
          <Input
            leftIcon={faCircleInfo}
            label="Client description"
            value={client?.description}
            rows={3}
            onFinishWriting={(clientDescription) =>
              onMutateClient({ description: clientDescription })
            }
            transformValue={trim}
          />
        </LeadRowCardSection>
        <LeadRowCardSection title="Pick image" />
        <LeadRowCardSection title="Contact information">
          <Input
            leftIcon={faPerson}
            label="Contact name"
            value={lead?.contactPersonName}
            onFinishWriting={(contactPersonName) => onMutateLead({ contactPersonName })}
            transformValue={trim}
          />
          <Input
            leftIcon={faAt}
            label="E-mail"
            value={lead?.contactPersonEmail}
            onFinishWriting={(contactPersonEmail) => onMutateLead({ contactPersonEmail })}
            transformValue={trim}
            validate={isEmailOrEmpty}
            type="email"
          />
          <Input
            leftIcon={faPhone}
            label="Phone number"
            value={lead?.contactPersonPhone}
            onFinishWriting={(contactPersonPhone) => onMutateLead({ contactPersonPhone })}
            transformValue={trim}
            validate={isPhoneNumberOrEmpty}
            type="tel"
          />
        </LeadRowCardSection>
      </LeadRowCard>
    </PanelContainer>
  );
};

ClientPanel.displayName = "ClientPanel";
