import { useEffect, useState } from "react";
import SideProjectEditor from "./SideProjectEditor";
import SectionHolder from "./SectionHolder";

import {
  type SideProject,
  type ProfileComponentProps,
  type SideProjectsSession,
  Workplace,
  ProjectPhoto,
  type WorkplaceItem,
  type WorkplaceLinkConfig,
} from "../types";
import AddNewItemBlock from "./AddNewItemBlock";
import "./components.css";
import { DragDropContext, Draggable, Droppable, type DropResult } from "@hello-pangea/dnd";
import { AlignJustify, Plus } from "react-feather";
import ContentToggle from "./ContentToggle";
import EditorSkillTagButton from "./atoms/EditorSkillTagButton";
import WorkplaceEditor from "./WorkplaceEditor";
import dayjs from "dayjs";
import EditorTextIconButton from "./EditorTextIconButton";

interface WorkplaceSectionProps extends ProfileComponentProps {
  isEditable: boolean;
  sideProjectSection: SideProjectsSession;
  sectionCount: number;
  onAddItem: (workplace: WorkplaceItem) => void;
  onUpdateItem: (workplace: WorkplaceItem) => void;
  onRemoveItem: (workplace: WorkplaceItem) => void;
  onDeleteSectionClick: () => void;
  onShowDialog: (dialogType: string, workplaceId: string) => void;
  configurable: boolean;
  onProjectOrderChange: (projects: SideProject[]) => void;
  onToggle?: (workplace: WorkplaceItem, toggleState: boolean) => void;
  toggledWorkplaceIds?: string[];
  workplaces: WorkplaceItem[];
  workplaceLinkConfig?: WorkplaceLinkConfig;
  sideProjects?: SideProject[];
}

function WorkplaceSection({
  isEditable,
  sideProjectSection,
  sectionCount,
  onAddItem,
  onUpdateItem,
  onRemoveItem,
  onDeleteSectionClick,
  onShowDialog,
  isDarkModeActive,
  configurable,
  onProjectOrderChange,
  onToggle,
  toggledWorkplaceIds,
  workplaces,
  workplaceLinkConfig,
}: WorkplaceSectionProps) {
  const [isAddNewSkillProjectSelected, setIsAddNewSkillProjectSelected] = useState<boolean>(false);
  const [contentImprovementIsActive, setContentImprovementIsActive] = useState<boolean>(false);
  const sideProjects = sideProjectSection.side_projects;
  const [currentProfileSideProjects, setCurrentProfileSideProjects] = useState<SideProject[]>([]);
  const [currentWorkplaces, setCurrentWorkplaces] = useState<WorkplaceItem[]>([]);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const [workplaceSideProject, setWorkplaceSideProject] = useState<SideProject>({
    id: "",
    title: "",
    pitch: "",
    order: -1,
    githubUrl: "",
    websiteUrl: "",
    workplace: null,
    skillTags: [],
    isSideProject: false,
  });

  // a little function to help us with reordering the result
  function reorder(list: SideProject[], startIndex: number, endIndex: number) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  const parseDate = (dateString: string) => {
    if (dateString === "") {
      return "current";
    }

    return dayjs(dateString, "YYYY").format("YYYY");
  };

  useEffect(() => {
    setCurrentProfileSideProjects(sideProjects);
  }, []);

  useEffect(() => {
    setCurrentWorkplaces(workplaces != undefined ? workplaces : []);
  }, [workplaces]);

  function onDragEnd(result: DropResult) {
    // dropped outside the list
    setIsDragging(false);
    if (!result.destination) {
      return;
    }

    var currrentSideProjects = [...sideProjects];

    const items = reorder(currrentSideProjects, result.source.index, result.destination.index);

    var updatedSideProjects = items.map((item, index) => {
      item.order = index;
      return item;
    });

    onProjectOrderChange(updatedSideProjects);
  }

  if (configurable) {
    return (
      <div>
        {currentWorkplaces.map((wp, index) => {
          return (
            <ContentToggle
              key={index}
              toggled={(toggledWorkplaceIds?.includes(wp.id) || false) && !isDragging}
              renderToggleOnContent={() => {
                return (
                  <WorkplaceEditor
                    key={wp.id}
                    isEditable={false}
                    isDarkModeActive={isDarkModeActive}
                    workplace={wp}
                    onSaveItem={(newWorkplace) => {
                      setIsAddNewSkillProjectSelected(false);
                      //onAddSideProject(sideProject);
                    }}
                    isBeingEdited={false}
                    isNew={true}
                    onRemove={() => {}}
                    onCancelEdit={() => setIsAddNewSkillProjectSelected(false)}
                    onShowDialog={(dialogType: string) => {
                      //onShowDialog(dialogType, -1)
                    }}
                    isLastInOrder={false}
                    workplaceLinkConfig={workplaceLinkConfig}
                    numberOfConnectedProjects={
                      sideProjects.filter((sp) => sp.workplaceId === wp.id).length
                    }
                    removePadding={true}
                  />
                );
              }}
              renderToggleOffContent={() => {
                return (
                  <div className="mb-4">
                    <h4
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        lineHeight: 1,
                        marginBottom: 5,
                      }}
                    >
                      {wp.role}
                    </h4>
                    <label>{wp.name ? wp.name : ""}</label>
                  </div>
                );
              }}
              onToggleOn={() => {
                if (onToggle) {
                  onToggle(wp, true);
                }
              }}
              onToggleOff={() => {
                if (onToggle) {
                  onToggle(wp, false);
                }
              }}
            />
          );
        })}
      </div>
    );
  }

  if (!isEditable) {
    return (
      <SectionHolder
        isDarkModeActive={isDarkModeActive}
        onDeleteSectionClick={onDeleteSectionClick}
        title={sideProjectSection.title}
        order={sideProjectSection.order}
        totalSectionCount={sectionCount}
        isEditable={isEditable}
      >
        {currentWorkplaces.map((workplace, index) => {
          return (
            <WorkplaceEditor
              isEditable={isEditable}
              isDarkModeActive={isDarkModeActive}
              key={index}
              isBeingEdited={true}
              isNew={false}
              workplace={workplace}
              onSaveItem={(workplaceItem: WorkplaceItem) => {
                onUpdateItem(workplaceItem);
                //onUpdateSideProject(savedSideProject);
              }}
              onCancelEdit={() => {}}
              onRemove={() => {}}
              onShowDialog={() => {}}
              isLastInOrder={false}
              onContentImprovementStarted={() => {}}
              onContentImprovementEnded={() => {}}
              workplaceLinkConfig={workplaceLinkConfig}
            />
          );
        })}
      </SectionHolder>
    );
  }

  return (
    <SectionHolder
      isDarkModeActive={isDarkModeActive}
      onDeleteSectionClick={onDeleteSectionClick}
      title={sideProjectSection.title}
      order={sideProjectSection.order}
      totalSectionCount={sectionCount}
      isEditable={isEditable}
    >
      {isEditable ? (
        <div style={{ marginBottom: sideProjects.length > 0 ? "15px" : "0px" }}>
          {isAddNewSkillProjectSelected ? (
            <WorkplaceEditor
              key="new-project-editor"
              isEditable={!isEditable}
              isDarkModeActive={isDarkModeActive}
              workplace={null}
              onSaveItem={(newWorkplace) => {
                setIsAddNewSkillProjectSelected(false);
                //onAddSideProject(sideProject);
              }}
              isBeingEdited={false}
              isNew={true}
              onRemove={() => {}}
              onCancelEdit={() => setIsAddNewSkillProjectSelected(false)}
              onShowDialog={(dialogType: string) => {
                //onShowDialog(dialogType, -1)
              }}
              isLastInOrder={false}
            />
          ) : (
            <div>
              <EditorTextIconButton
                text="Add new workplace"
                handleClick={() => {
                  setIsAddNewSkillProjectSelected(false);
                  const newWorkplace: WorkplaceItem = {
                    id: "",
                    name: "",
                    startDate: "",
                    endDate: "",
                    role: "",
                    description: "",
                    order: 0,
                    skillTags: [],
                  };
                  onAddItem(newWorkplace);
                }}
                iconChild={
                  <div className="bg-op-dark max-w-[18px] max-h-[18px] min-w-[18px] min-h-[18px] flex justify-center items-center">
                    <Plus size={15} color="#fefefe" />
                  </div>
                }
              />
            </div>
          )}
        </div>
      ) : null}

      {currentWorkplaces.map((workplace, index) => {
        return (
          <WorkplaceEditor
            isEditable={isEditable}
            isDarkModeActive={isDarkModeActive}
            key={workplace.id}
            isBeingEdited={true}
            isNew={false}
            workplace={workplace}
            onSaveItem={(workplaceItem: WorkplaceItem) => {
              onUpdateItem(workplaceItem);
              //onUpdateSideProject(savedSideProject);
            }}
            onCancelEdit={() => {}}
            onRemove={() => {
              onRemoveItem(workplace);
            }}
            onShowDialog={(dialogType: string) => {
              onShowDialog(dialogType, workplace.id);
            }}
            isLastInOrder={index === sideProjects.length - 1}
            onContentImprovementStarted={() => {
              setContentImprovementIsActive(true);
            }}
            onContentImprovementEnded={() => {
              setContentImprovementIsActive(false);
            }}
          />
        );
      })}
    </SectionHolder>
  );
}

export default WorkplaceSection;
