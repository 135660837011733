import type React from "react";
import { useState } from "react";
import withCustomStyling from "./HOC/withCustomStyling";

interface LockableInputProps {
  icon: React.ReactNode;
  placeholder?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  locked?: boolean;
  onActionButtonClick: () => void;
}

const LockableInput: React.FC<LockableInputProps> = ({
  icon,
  placeholder,
  value,
  onChange,
  onBlur,
  locked,
  onActionButtonClick,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  return (
    <div className="flex items-center border rounded-lg p-2">
      <div className="mr-2">{icon}</div>
      <input
        type="text"
        className={
          "flex-1 outline-none h-[40px] bg-transparent text-md " +
          (locked && !editMode ? "text-gray-400" : "")
        }
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={locked === true && !editMode}
      />
      <div>
        <button
          className="black underline font-semibold text-sm cursor-pointer hover:font-bold"
          onClick={() => {
            if (locked) {
              setEditMode(true);
            } else {
              onActionButtonClick();
            }
          }}
        >
          {locked && !editMode ? "Edit" : "Go"}
        </button>
      </div>
    </div>
  );
};

export default withCustomStyling(LockableInput);
