import { useRef, useState, useEffect } from "react";
import "./vk-textarea.css";
import "../../types";
import { darkModeBlack } from "../../colors";
import { Settings } from "react-feather";
import LinkButton from "../LinkButton";
import { getContentImprovementSuggestion } from "../../requests";
import EditorTextButton from "../atoms/EditorTextButton";
const VkTextArea = (props) => {
  const [input, setInput] = useState("");
  const [currentInput, setCurrentInput] = useState(null);
  const [isMouseHovering, setIsMouseHovering] = useState(false);
  const [isOverMaxLength, setIsOverMaxLenght] = useState(false);
  const [contentSuggestionRequestBody, setContentSuggestionRequestBody] = useState(null);
  const bottomBorderLine = useRef();
  const taRef = useRef();

  useEffect(() => {
    update();
  });

  const onInputFocus = (e) => {
    if (props.onTextAreaFocus && props.inputType) {
      props.onTextAreaFocus(props.inputType);
    }

    if (props.onFocus) {
      props.onFocus();
    }
    setCurrentInput(props.value);
  };

  const onHover = (e) => {
    setIsMouseHovering(true);
    if (props.onHoverChange) {
      props.onHoverChange(true);
    }
  };

  const onHoverLeave = (e) => {
    setIsMouseHovering(false);
    if (props.onHoverChange) {
      props.onHoverChange(false);
    }
  };

  const onBlur = (e) => {
    props.onLeaveField(currentInput);
    setCurrentInput(null);
  };

  var clientWidth = null;
  const heightOffset = null;
  const cachedHeight = null;

  function getParentOverflows(el) {
    const arr = [];

    while (el && el.parentNode && el.parentNode instanceof Element) {
      if (el.parentNode.scrollTop) {
        arr.push({
          node: el.parentNode,
          scrollTop: el.parentNode.scrollTop,
        });
      }
      el = el.parentNode;
    }

    return arr;
  }

  function Clear() {
    setCurrentInput(null);
  }

  function handleEnterKey(onKeyDownEvent) {
    if (onKeyDownEvent.key === "Enter") {
      onKeyDownEvent.preventDefault();
      taRef.current.blur();
    }
  }

  function resize() {
    if (taRef.current.scrollHeight === 0) {
      // If the scrollHeight is 0, then the element probably has display:none or is detached from the DOM.
      return;
    }
    const overflows = getParentOverflows(taRef.current);
    const docTop = document.documentElement && document.documentElement.scrollTop; // Needed for Mobile IE (ticket #240)

    taRef.current.style.height = "";
    if (taRef.current.scrollHeight + heightOffset < 48) {
      taRef.current.style.height = "48px";
    } else {
      taRef.current.style.height = taRef.current.scrollHeight + heightOffset + "px";
    }

    // used to check if an update is actually necessary on window.resize
    clientWidth = 600;

    // prevents scroll-position jumping
    overflows.forEach((el) => {
      el.node.scrollTop = el.scrollTop;
    });

    if (docTop) {
      document.documentElement.scrollTop = docTop;
    }
  }

  function update() {
    resize();

    const styleHeight = Math.round(Number.parseFloat(taRef.current.style.height));
    const computed = 0; //window.getComputedStyle(taRef.current, null);

    // Using offsetHeight as a replacement for computed.height in IE, because IE does not account use of border-box
    var actualHeight =
      computed.boxSizing === "content-box"
        ? Math.round(Number.parseFloat(computed.height))
        : taRef.current.offsetHeight;

    // The actual height not matching the style height (set via the resize method) indicates that
    // the max-height has been exceeded, in which case the overflow should be allowed.
    if (actualHeight < styleHeight) {
      if (computed.overflowY === "hidden") {
        //changeOverflow('scroll');
        resize();
        actualHeight =
          computed.boxSizing === "content-box"
            ? Math.round(Number.parseFloat(window.getComputedStyle(taRef.current, null).height))
            : taRef.current.offsetHeight;
      }
    } else {
      // Normally keep overflow set to hidden, to avoid flash of scrollbar as the textarea expands.
      if (computed.overflowY !== "hidden") {
        //changeOverflow('hidden');
        resize();
        actualHeight =
          computed.boxSizing === "content-box"
            ? Math.round(Number.parseFloat(window.getComputedStyle(taRef.current, null).height))
            : taRef.current.offsetHeight;
      }
    }

    /*
    if (cachedHeight !== actualHeight) {
      cachedHeight = actualHeight;
      const evt = createEvent('autosize:resized');
      try {
        ta.dispatchEvent(evt);
      } catch (err) {
        // Firefox will throw an error on dispatchEvent for a detached element
        // https://bugzilla.mozilla.org/show_bug.cgi?id=889376
      }
    }
    */
  }

  return (
    <div onMouseEnter={onHover} onMouseLeave={onHoverLeave} className="flex flex-row relative">
      <div className="flex flex-1 flex-col transition-all">
        <textarea
          ref={taRef}
          className="vk-textarea"
          onFocus={onInputFocus}
          onBlur={onBlur}
          onKeyDown={props.blurOnEnter === true ? handleEnterKey : undefined}
          onChange={(inputEvent) => {
            if (inputEvent.target.value.length === 0 && props.onEmpty) {
              props.onEmpty();
            }
            if (inputEvent.target.value.length === 1 && props.onFirstInput) {
              props.onFirstInput();
            }

            if (props.maxLength) {
              if (inputEvent.target.value.length > props.maxLength) {
                setIsOverMaxLenght(true);
              } else {
                setIsOverMaxLenght(false);
              }
            }

            setCurrentInput(inputEvent.target.value);
          }}
          placeholder={props.placeholder}
          onInput={update}
          value={currentInput ?? props.value}
          disabled={!props.isEditable}
          style={props.isLocked === true ? { color: "gray" } : {}}
        />

        {(isMouseHovering && !props.disableHoverUnderline) || isOverMaxLength ? (
          <div
            x-show="{(isMouseHovering && !props.disableHoverUnderline) ||
          isOverMaxLength}"
            className="transition-all"
          >
            <div
              style={{
                minHeight: 1,
                width: "100%",
                background: isOverMaxLength ? "#C21616" : "#000",
              }}
              className="text-white transition-all text-sm"
            ></div>
            {isOverMaxLength ? (
              <label className="text-sm">
                We recommended keeping the description lenght to below 250 character. You can use
                our writing assistant to generate shorter versions of your text.
              </label>
            ) : null}
          </div>
        ) : null}
        {props.maxLength !== undefined ? (
          <div className="flex flex-1 justify-end">
            <label>
              {currentInput !== null ? currentInput.length + "/" + props.maxLength : ""}
            </label>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default VkTextArea;
