import type { Options } from "@hey-api/client-fetch";
import { type UseMutationOptions, useMutation } from "@tanstack/react-query";
import type {
  PostV1AssignmentData,
  PostV1AssignmentError,
  PostV1AssignmentResponse,
} from "../../client";
import { postV1AssignmentMutation } from "../../client/@tanstack/react-query.gen";

const mutationKey = ["requestAssignmentInfo"];

export const useRequestAssignmentInfo = (
  opts?: UseMutationOptions<
    PostV1AssignmentResponse,
    PostV1AssignmentError,
    Options<PostV1AssignmentData>
  >
) => {
  return useMutation({
    ...postV1AssignmentMutation(),
    mutationKey,
    ...(opts ?? {}),
  });
};
