import type { Options } from "@hey-api/client-fetch";
import { type UseMutationOptions, useMutation, useQueryClient } from "@tanstack/react-query";
import type {
  PostV1CommentsData,
  PostV1CommentsError,
  PostV1CommentsResponse,
} from "../../../client";
import { postV1CommentsMutation } from "../../../client/@tanstack/react-query.gen";
import { getCommentsOptions } from "./get-comments";

const mutationKey = ["postComment"];

const getCommentsQueryKey = (resourceID: string) => getCommentsOptions(resourceID).queryKey;

export const usePostComment = (
  options: UseMutationOptions<
    PostV1CommentsResponse,
    PostV1CommentsError,
    Options<PostV1CommentsData>
  > = {}
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...postV1CommentsMutation(),
    mutationKey,
    ...options,
    onSettled: (data, error, vars, context) => {
      queryClient.invalidateQueries({
        queryKey: getCommentsQueryKey(vars.body.resourceId),
      });

      options?.onSettled?.(data, error, vars, context);
    },
  });
};
