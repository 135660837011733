import "./components.css";
import { useMediaQuery } from "react-responsive";
import { useState, useRef } from "react";
import headerLogo from "../assets/op_header_black.png";
import ThreeDotsIcon from "../assets/three_dots_icon.png";
import ThreeDotsIconWhite from "../assets/three_dots_icon_white.png";
import { useSelector, useDispatch } from "react-redux";
import type { UserState } from "../userReducer";
import Button, { ButtonStyleType } from "../components/atoms/Button";

import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

interface PageHeaderProps {
  title?: string;
  isAnalyticsPanelOpen?: boolean;
  isSiteNavigationOpen: boolean;
  currentPath: string;
  isSignedIn?: boolean;
  logoHeader?: boolean;
  onHoverStart?: () => void;
  onHoverEnd?: () => void;
  onMenuClick?: () => void;
}

export default function PageHeader({
  title,
  currentPath,
  isSignedIn,
  isSiteNavigationOpen,
  isAnalyticsPanelOpen,
  logoHeader,
  onHoverStart,
  onHoverEnd,
  onMenuClick,
}: PageHeaderProps) {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" });
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const [isDesktopMenuOpen, setIsDesktopMenuOpen] = useState<boolean>(false);
  const [isHoveringMenuButton, setIsHoveringMenuButton] = useState<boolean>(false);
  const navigate = useNavigate();
  const userState: UserState = useSelector<UserState, UserState>((state) => state);

  const { loginWithRedirect, getAccessTokenSilently, logout, isAuthenticated } = useAuth0();

  const siteNavigationRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const navigateToStartPage = () => {
    const currentOrgId = localStorage.getItem("current-org-id");
    if (currentOrgId !== null && currentOrgId !== "") {
      navigate("/organizations/" + currentOrgId);
      return;
    }

    const currentProfileId = localStorage.getItem("current-profile-id");
    navigate("/profiles/" + currentProfileId);
  };

  /*
  States:
  - Not signed in, mobile
  - Signed in, mobile
  - Not signed in, desktop
  - Signed in, desktop
  */
  return (
    <div className="flex flex-1 flex-row justify-between items-center">
      <img
        alt=""
        src={headerLogo}
        id="page-header-logo"
        onClick={() => {
          navigateToStartPage();
        }}
      />
      {isSignedIn ? (
        <div>
          <label>menu</label>
        </div>
      ) : (
        <Button
          text="Sign in"
          onButtonClick={() => {
            loginWithRedirect();
          }}
          style={ButtonStyleType.primary}
        />
      )}
    </div>
  );

  return (
    <div id="page-header" className="px-4 md:px-0 border-2 border-red-400">
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {logoHeader ? (
          <img
            alt=""
            src={headerLogo}
            id="page-header-logo"
            onClick={() => {
              navigateToStartPage();
            }}
          />
        ) : title ? (
          <h2>{title}</h2>
        ) : null}

        {!isMobile && !isAuthenticated ? (
          <div>
            <label>Not authed</label>
          </div>
        ) : (
          <div
            className="pt-0 md:pt-4"
            style={{ height: 60 }}
            onClick={() => {
              if (onMenuClick) {
                onMenuClick();
              }
            }}
          >
            {!isSiteNavigationOpen ? (
              <img alt="" src={ThreeDotsIcon} style={{ maxWidth: 32 }} />
            ) : (
              <img alt="" src={ThreeDotsIconWhite} style={{ maxWidth: 32 }} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
