import { type HTMLAttributes, useReducer } from "react";
import { cn } from "../utils/cn";

interface Props extends HTMLAttributes<HTMLImageElement> {
  src?: string;
  alt: string;
  className?: string;
}

export const Image = ({ src, className, alt, ...props }: Props) => {
  const [loading, onLoad] = useReducer(() => false, true);
  return (
    <div
      className={cn("relative overflow-hidden bg-gray-3", loading && "animate-pulse", className)}
    >
      {src && (
        <img
          className={cn("w-full h-full object-cover")}
          onLoad={onLoad}
          {...props}
          src={src}
          alt={alt}
        />
      )}
    </div>
  );
};

Image.displayName = "Image";
