import type { ComponentProps } from "react";
import { Drawer as DrawerPrimitive } from "vaul";

const Drawer = ({
  shouldScaleBackground = true,
  ...props
}: ComponentProps<typeof DrawerPrimitive.Root>) => (
  <DrawerPrimitive.Root shouldScaleBackground={shouldScaleBackground} {...props} />
);

Drawer.displayName = "Drawer";

const DrawerTrigger = DrawerPrimitive.Trigger;

const DrawerPortal = DrawerPrimitive.Portal;

const DrawerClose = DrawerPrimitive.Close;

const DrawerTitle = DrawerPrimitive.Title;

const DrawerDescription = DrawerPrimitive.Description;

export { Drawer, DrawerClose, DrawerDescription, DrawerPortal, DrawerTitle, DrawerTrigger };
