import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type ComponentProps, type MouseEventHandler, forwardRef } from "react";
import { cn } from "../../utils/cn";

type Size = "small" | "medium";

interface Props {
  icon: IconProp;
  active?: boolean;
  size?: Size;
  className?: string;
  iconProps?: Omit<ComponentProps<typeof FontAwesomeIcon>, "icon">;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const IconButton = forwardRef<HTMLButtonElement, Props>(
  ({ icon, active, size = "medium", className, iconProps, onClick }, ref) => (
    <button
      type="button"
      ref={ref}
      className={cn(
        "group flex items-center justify-center transition-all",
        size === "medium" && "w-14 h-14 p-2",
        size === "small" && "w-8 h-8 p-1",
        "hover:p-0",
        active && "p-0",
        className
      )}
      onClick={onClick}
    >
      <div
        className={cn(
          "w-full h-full flex items-center justify-center transition-all",
          "group-hover:bg-gray-3 group-active:scale-90",
          size === "medium" && "rounded-2xl",
          size === "small" && "rounded-lg",
          active && "bg-gray-4 group-hover:bg-gray-4"
        )}
      >
        <FontAwesomeIcon
          icon={icon}
          {...iconProps}
          className={cn(
            "text-black",
            size === "medium" && "w-5 h-5",
            size === "small" && "w-5 h-5",
            iconProps?.className
          )}
        />
      </div>
    </button>
  )
);

IconButton.displayName = "IconButton";
