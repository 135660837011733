import type React from "react";
import { useEffect, useState } from "react";
import type { Client, Lead } from "../types";
import IconInput from "./IconInput";
import { AtSign, Globe, Home, Info, Mail, User } from "react-feather";
import {
  createClient,
  createLead,
  getClientByUrl,
  getClientPreviewData,
  updateClient,
  updateLead,
} from "../requests";
import IconTextArea from "./IconTextArea";
import Lottie from "react-lottie";
import animationData from "../assets/spinner-animation.json";
import AvatarImage from "./AvatarImage";
import { useCurrentOrgId } from "../hooks/hooks";
import LockableInput from "./LockableInput";
import EditorTitle from "./EditorTitle";
import Button, { ButtonSize, ButtonStyleType } from "./atoms/Button";

interface ClientEditorTabProps {
  client?: Client;
  lead: Lead;
  onClientSave: (client: Client) => void;
  onLeadSave: (lead: Lead) => void;
  onLeadCreated: (newLead: Lead, newClient: Client) => void;
  orgClients?: Client[];
}

const ClientEditorTab: React.FC<ClientEditorTabProps> = ({
  client,
  lead,
  onLeadSave,
  onLeadCreated,
  onClientSave,
  orgClients,
}) => {
  const [currentClient, setCurrentClient] = useState<Client | null>(null);
  const [currentLead, setCurrentLead] = useState<Lead | null>(null);

  const [emailInputValue, setEmailInputValue] = useState<string>("");
  const [urlInputValue, setUrlInputValue] = useState<string>("");

  //Manual values
  const [contactPersonNameInputValue, setContactPersonNameInputValue] = useState<string>("");
  const [contactPersonEmailInputValue, setContactPersonEmailInputValue] = useState<string>("");

  const [isLoadingClientPreviewData, setIsLoadingClientPreviewData] = useState<boolean>(false);
  const [logoSuggestionUrls, setLogoSuggestionUrls] = useState<string[]>([]);

  const currentOrgId = useCurrentOrgId();

  useEffect(() => {
    if (client) {
      setCurrentClient({ ...client });
      if (client.logoSuggestions && client.logoSuggestions.length > 0) {
        setLogoSuggestionUrls(client.logoSuggestions);
      }

      if (
        client.id !== "" &&
        (client.logoId === null || client.logoId === "" || client.logoId === undefined) &&
        client.url !== ""
      ) {
        reloadLogoOptions(client.url);
      }
    } else {
      const newClient: Client = {
        id: "",
        name: "",
        organizationId: localStorage.getItem("current-org-id")!,
        url: "",
        description: "",
      };
      setCurrentClient(newClient);
    }
  }, [client]);

  useEffect(() => {
    if (lead) {
      setCurrentLead(lead);
      setEmailInputValue(lead?.contactPersonEmail ?? "");
      setContactPersonEmailInputValue(lead?.contactPersonEmail ?? "");
      setContactPersonNameInputValue(lead?.contactPersonName ?? "");
    }
  }, [lead]);

  const formatContactPersonNameFromEmail = (email: string) => {
    if (email.split("@").length > 1) {
      const namePart = email.split("@")[0];
      if (namePart.indexOf(".") > -1 && namePart.split(".").length > 1) {
        return (
          capitalizeFirstLetter(namePart.split(".")[0]) +
          " " +
          capitalizeFirstLetter(namePart.split(".")[1])
        );
      } else {
        return capitalizeFirstLetter(namePart);
      }
    }
    return "";
  };

  const reloadLogoOptions = (url: string) => {
    getClientPreviewData(url).then((previewData) => {
      if (previewData.logoImageUrls && previewData.logoImageUrls.length > 0) {
        setLogoSuggestionUrls(previewData.logoImageUrls);
      }
    });
  };

  const addClient = (client: Client, contactEmail: string, contactName: string) => {
    createClient(currentOrgId!, client)
      .then((client: Client) => {
        const newLead = { ...currentLead! };
        newLead.clientId = client.id;
        newLead.contactPersonEmail = contactEmail;
        newLead.contactPersonName = contactName;

        if (currentLead?.id === "") {
          createLead(currentOrgId!, newLead)
            .then((lead: Lead) => {
              onLeadCreated(lead, client);
              setCurrentLead(lead);
            })
            .catch((err) => {
              console.error("Lead creation process failed:", err);
            });
        } else {
        }
      })
      .catch((error) => {
        console.error("Client creation process failed:", error);
      });
  };

  const saveLead = () => {
    if (currentLead) {
      const updatedLead = { ...currentLead };
      updatedLead.contactPersonEmail = contactPersonEmailInputValue;
      updatedLead.contactPersonName = contactPersonNameInputValue;

      updateLead(currentOrgId!, lead.id, updatedLead)
        .then((l: Lead) => {
          setCurrentLead(l);
          onLeadSave(lead);
        })
        .catch((err) => {
          console.error("Lead creation process failed:", err);
        });
    }
  };

  const saveClient = (client: Client) => {
    updateClient(currentOrgId!, client)
      .then((savedClient) => {
        onClientSave(savedClient);
      })
      .catch((err) => {});
  };

  const fetchClientInfo = (url: string, contactEmail: string, contactName: string) => {
    if (!isLoadingClientPreviewData) {
      setIsLoadingClientPreviewData(true);
      getClientByUrl(currentOrgId!, url).then((clientData: Client) => {
        setIsLoadingClientPreviewData(false);
        if (currentClient?.id === "") {
          const newClient = { ...currentClient! };

          newClient.id = clientData.id;
          newClient.name = clientData.name;
          newClient.logoId = clientData.logoId;
          newClient.logoSuggestions = clientData.logoSuggestions;
          newClient.description = clientData.description;
          newClient.url = url;

          if (clientData.logoSuggestions && clientData.logoSuggestions.length > 0) {
            setLogoSuggestionUrls(clientData.logoSuggestions);
          }

          setCurrentClient(newClient);

          if (newClient.id === "") {
            //If client id empty it it not currently in the organisations clients so we can it add it
            addClient(newClient, contactEmail, contactName);
          } else {
            onClientSave(newClient);
            if (currentLead?.id === "") {
              const newLead = { ...currentLead! };
              newLead.clientId = newClient.id;
              newLead.contactPersonEmail = contactEmail;
              newLead.contactPersonName = contactName;
              createLead(currentOrgId!, newLead)
                .then((lead: Lead) => {
                  setCurrentLead(lead);
                  onLeadCreated(lead, newClient);
                })
                .catch((err) => {
                  console.error("Lead creation process failed:", err);
                });
            }
          }
        }
      });
    }
  };

  const handleEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInputValue(event.target.value);
  };

  const renderMultipleLogoOptions = () => {
    return (
      <div>
        {logoSuggestionUrls.length > 0 ? (
          <div>
            <label className="text-sm">Which logo do you prefer?</label>
            <div className="flex flex-row mt-2">
              {logoSuggestionUrls.map((logoUrl, i) => {
                return (
                  <div
                    key={i}
                    className="w-[60px] h-[60px] flex items-center justify-center bg-gray-200 overflow-hidden rounded-md mr-2 p-2 hover:p-0"
                    onClick={() => {
                      const changedClient = { ...currentClient! };
                      changedClient.logoId = logoUrl;
                      saveClient(changedClient);
                      setLogoSuggestionUrls([logoUrl]);
                    }}
                  >
                    <img
                      src={logoUrl ?? ""}
                      alt="Logo"
                      className="object-cover w-full h-full cursor-pointer"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const renderClientPreviewData = () => {
    return (
      <div className="flex-1">
        <EditorTitle title="Client information" />
        <IconInput
          icon={<Home />}
          placeholder="Client name..."
          value={currentClient?.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setCurrentClient({ ...currentClient!, name: event.target.value })
          }
          onBlur={() => saveClient(currentClient!)}
          className="mt-2 ml-2"
        />
        <IconInput
          icon={<Globe />}
          placeholder="Client website"
          value={currentClient?.url}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setCurrentClient({ ...currentClient!, url: event.target.value })
          }
          onBlur={() => saveClient(currentClient!)}
          className="mt-2 ml-2"
        />

        <IconTextArea
          icon={<Info />}
          placeholder="About the client"
          value={currentClient?.description}
          className="mt-2 ml-2"
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
            setCurrentClient({
              ...currentClient!,
              description: event.target.value,
            })
          }
          onBlur={() => saveClient(currentClient!)}
        />

        <div className="flex flex-row items-center align-center mt-2">
          <EditorTitle title="Client logo" className="mr-2" />
          {currentClient && currentClient.url && logoSuggestionUrls.length === 0 ? (
            <Button
              text="View logo options"
              style={ButtonStyleType.actionLink}
              onButtonClick={() => {
                setCurrentClient({ ...currentClient, logoId: "" });
                reloadLogoOptions(currentClient.url);
              }}
              size={ButtonSize.small}
              viewStyle={{ marginTop: "3px" }}
            />
          ) : null}
        </div>

        <div className="ml-4">
          {currentClient?.logoId && currentClient.logoId !== "" ? (
            <AvatarImage photoId={currentClient.logoId} />
          ) : (
            renderMultipleLogoOptions()
          )}
        </div>

        <EditorTitle title="Contact person" className="mt-2" />

        <IconInput
          key="contact-person-mail"
          icon={<Mail />}
          placeholder="Email of contact person"
          value={contactPersonEmailInputValue}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setContactPersonEmailInputValue(event.target.value)
          }
          onBlur={() => {
            saveLead();
          }}
          className="mt-2"
        />
        <IconInput
          key="contact-person-name"
          icon={<User />}
          placeholder="Name of contact person"
          value={contactPersonNameInputValue}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setContactPersonNameInputValue(event.target.value);
          }}
          onBlur={() => {
            saveLead();
          }}
          className="mt-2"
        />
      </div>
    );
  };

  const handleInputClientTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentClient) {
      setCurrentClient({
        ...currentClient,
        name: event.target.value, // Update the title field with the new value
      });
    }
  };

  function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <div className="mt-6 flex flex-row">
      <div className="flex-1 p-2">
        <div className="flex flex-1 flex-col">
          <LockableInput
            icon={<AtSign size={16} />}
            placeholder="Contact person email"
            value={emailInputValue}
            onChange={handleEmailInputChange}
            className="mt-2 flex-1"
            onActionButtonClick={() => {
              if (emailInputValue.indexOf("@") > -1) {
                //Extract the url and fetch client info
                const url = emailInputValue.split("@")[1];

                setContactPersonEmailInputValue(emailInputValue);
                setContactPersonNameInputValue(formatContactPersonNameFromEmail(emailInputValue));

                fetchClientInfo(
                  url,
                  emailInputValue,
                  formatContactPersonNameFromEmail(emailInputValue)
                );
                setUrlInputValue(url);
              }
            }}
            locked={(currentClient && currentClient.id !== "") ?? false}
          />
          <div className="flex  mr-2 ml-2">
            <label>or</label>
          </div>

          <LockableInput
            icon={<Globe size={16} />}
            placeholder="Client website domain"
            value={urlInputValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setUrlInputValue(event.target.value);
            }}
            className="mt-2 flex-1"
            onActionButtonClick={() => {
              fetchClientInfo(urlInputValue, "", "");
            }}
            locked={(currentClient && currentClient.id !== "") ?? false}
          />
          {currentClient?.id === "" ? (
            <div>
              <EditorTitle title="Use existing client" className="mt-12" />

              <div className="container mx-auto p-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  {orgClients?.map((existingClient) => {
                    return (
                      <div
                        onClick={() => {
                          onClientSave(existingClient);
                          const newLead = { ...currentLead! };
                          newLead.clientId = existingClient.id;
                          createLead(currentOrgId!, newLead)
                            .then((lead: Lead) => {
                              setCurrentLead(lead);
                              onLeadCreated(lead, existingClient);
                            })
                            .catch((err) => {
                              console.error("Lead creation process failed:", err);
                            });
                        }}
                      >
                        <AvatarImage photoId={existingClient.logoId} title={existingClient.name} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex flex-2">
        {isLoadingClientPreviewData ? (
          <Lottie style={{ width: 80 }} options={{ animationData: animationData }} />
        ) : (
          renderClientPreviewData()
        )}
      </div>
    </div>
  );
};

export default ClientEditorTab;
