import type React from "react";
import { useState } from "react";

interface ExpandableListProps {
  items: string[];
  initialDisplayCount?: number;
}

const ExpandableList: React.FC<ExpandableListProps> = ({ items, initialDisplayCount = 3 }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const displayedItems = expanded ? items : items.slice(0, initialDisplayCount);

  return (
    <div>
      <ul className="list-disc pl-4">
        {displayedItems.map((item, index) => (
          <li className="text-sm" key={index}>
            {item}
          </li>
        ))}
      </ul>
      {items.length > initialDisplayCount && (
        <button
          className="black underline font-semibold text-sm cursor-pointer hover:font-bold"
          onClick={handleExpandClick}
        >
          {expanded ? "Show Less" : "Show (" + (items.length - initialDisplayCount) + ") More"}
        </button>
      )}
    </div>
  );
};

export default ExpandableList;
