import { useState, useRef, useEffect } from "react";
import { Camera } from "react-feather";

const Avatar = ({ onShowCropTool, imageString, isEditable, roundMode }) => {
  const fileInputRef = useRef();
  const imgRef = useRef();
  const [isMouseHovering, setIsMouseHovering] = useState(false);
  const onClick = () => {
    fileInputRef.current.click();
  };

  const onInputChange = (event) => {
    const reader = new FileReader();
    reader.onerror = (ev) => {};
    reader.onloadend = (ev) => {
      var bytes = new Uint8Array(ev.currentTarget.result);
      imgRef.current.src = "data:image/png;base64," + encode(bytes);
      onShowCropTool("data:image/png;base64," + encode(bytes));
    };
    reader.readAsArrayBuffer(event.target.files[0]);
  };

  // public method for encoding an Uint8Array to base64
  function encode(input) {
    var keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    var output = "";
    var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    var i = 0;

    while (i < input.length) {
      chr1 = input[i++];
      chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index
      chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }
      output +=
        keyStr.charAt(enc1) + keyStr.charAt(enc2) + keyStr.charAt(enc3) + keyStr.charAt(enc4);
    }
    return output;
  }

  if (!isEditable) {
    return (
      <div className="">
        <img className="max-w-[50px]" src={imageString} alt="" ref={imgRef} />
      </div>
    );
  }

  return (
    <div
      className=""
      onClick={onClick}
      onMouseEnter={() => setIsMouseHovering(true)}
      onMouseLeave={() => setIsMouseHovering(false)}
    >
      <img className="" src={imageString} alt="" ref={imgRef} />
      <input
        id="file-input"
        type="file"
        name="name"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={onInputChange}
      />
      <div
        style={{
          position: "absolute",
          bottom: -2,
          right: 0,
          width: "100%",
          backgroundColor: "black",
          height: "35px",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          visibility: isMouseHovering ? "visible" : "hidden",
        }}
      >
        <label style={{ color: "#fff", fontSize: "12px", textAlign: "center" }}>
          Click to change
        </label>
      </div>
    </div>
  );
};

export default Avatar;
