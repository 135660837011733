import React, { useState, useRef, useEffect, type FC, type MouseEvent } from "react";
import { Archive, Briefcase, MoreHorizontal, Trash } from "react-feather";

export enum RowMenuAction {
  ArchiveLead = 0,
  DeleteComment = 1,
  CreateContract = 2,
}

interface RowMenuProps {
  availableActions: RowMenuAction[];
  onActionClick: (action: RowMenuAction) => void;
}

const RowMenu: FC<RowMenuProps> = ({ availableActions, onActionClick }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);

  // Close the menu if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside as unknown as EventListener);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside as unknown as EventListener);
    };
  }, []);

  const getActionTitle = (action: RowMenuAction) => {
    switch (action) {
      case RowMenuAction.ArchiveLead:
        return "Archive lead";
      case RowMenuAction.DeleteComment:
        return "Delete comment";
      case RowMenuAction.CreateContract:
        return "Turn into contract";
      default:
        console.log("RowMenuAction not recognized");
    }
  };

  const getActionIcon = (action: RowMenuAction) => {
    switch (action) {
      case RowMenuAction.ArchiveLead:
        return <Archive size={14} className="mr-2" />;
      case RowMenuAction.DeleteComment:
        return <Trash size={14} className="mr-2" />;
      case RowMenuAction.CreateContract:
        return <Briefcase size={14} className="mr-2" />;
        return;
      default:
        console.log("RowMenuAction not recognized");
    }
  };

  return (
    <div className="relative inline-block text-left" ref={menuRef}>
      <div className="h-full align-center">
        <button
          type="button"
          className="inline-flex items-center justify-center w-full h-full rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={() => setIsOpen(!isOpen)}
        >
          <MoreHorizontal />
        </button>
      </div>

      {isOpen && (
        <div
          className="origin-top-center absolute left-1/2 transform -translate-x-1/2 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10 p-2 text-center"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
          <div className="py-1" role="none">
            {availableActions.map((action, index) => {
              return (
                <div
                  key={index}
                  className="block rounded-md px-4 py-4 text-sm hover:bg-gray-200 justify-center items-center flex cursor-pointer"
                  role="menuitem"
                  onClick={() => {
                    onActionClick(action);
                    setIsOpen(false);
                  }}
                >
                  {getActionIcon(action)}
                  {getActionTitle(action)}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default RowMenu;
