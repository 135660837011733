import { dark } from "../../colors";

interface Props {
  white?: boolean;
  viewStyle?: React.CSSProperties;
}

const MenuDash = (props: Props) => {
  return (
    <div
      style={{
        minHeight: 1,
        background: props.white ? "#fff" : dark,
        minWidth: 21,
        maxWidth: 21,
        ...(props.viewStyle ?? {}),
      }}
    />
  );
};

export default MenuDash;
