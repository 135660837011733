import type { PropsWithChildren } from "react";
import { cn } from "../utils/cn";
import { useAnimate } from "../../hooks";

type Props = PropsWithChildren<{
  className?: string;
}>;

const MenuSection = ({ children, className }: Props) => {
  const [parent] = useAnimate();

  return (
    <ul
      ref={parent}
      className={cn(
        "flex flex-row items-stretch flex-shrink gap-6 p-6 bg-gray-2 rounded-3xl border border-gray-3 transition-all",
        className
      )}
    >
      {children}
    </ul>
  );
};

MenuSection.displayName = "MenuSection";

MenuSection.Separator = () => <div className="bg-gray-3 w-px" />;

export { MenuSection };
