import "../components.css";
import { useState } from "react";
import { motion } from "framer-motion";
import type { UserProfile } from "../../types";
import { ArrowRight } from "react-feather";

interface Props {
  index: number;
  height: number;
  count: number;
  skillName: string;
  clickable: boolean;
  hoverSkillName: string;
  onClick: (skillName: string) => void;
  profiles?: UserProfile[];
  onHoverStart: (skillName: string) => void;
  onHoverEnd: (skillName: string) => void;
}

const SkillBar = (props: Props) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [isHoveringInfoModal, setIsHoveringInfoModal] = useState<boolean>(false);

  function getBottomMargin() {
    if (props.profiles) {
      if (props.profiles.length === 1) {
        return -75;
      } else if (props.profiles.length === 2) {
        return -100;
      } else if (props.profiles.length === 3) {
        return -125;
      }
    }
  }
  return (
    <motion.div
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      onClick={() => {
        props.onClick(props.skillName);
      }}
      initial={{ opacity: 0, y: 10, scaleY: 0 }}
      whileInView={{ opacity: 1, y: 0, scaleY: 1 }}
      viewport={{ once: false }}
      transition={{
        duration: 0.3,
        damping: 80,
        delay: props.index / 20,
      }}
      key={props.count + props.index + props.height}
      style={{ height: `${props.height}%` }}
      className={`w-[35px] bg-op-gray-200 hover:bg-op-dark mr-2 relative text-center ${
        props.clickable ? "cursor-pointer" : ""
      }`}
    >
      <label
        className={"absolute " + (isHovering ? "visible" : "invisible")}
        style={{ top: -25, right: 12 }}
      >
        {props.count}
      </label>
      <div
        onMouseEnter={() => {
          setIsHoveringInfoModal(true);
          props.onHoverStart(props.skillName);
        }}
        onMouseLeave={() => {
          setIsHoveringInfoModal(false);
          props.onHoverEnd(props.skillName);
        }}
        className={
          "min-w-[200px] bg-white absolute left-0 " +
          (props.hoverSkillName === props.skillName || isHoveringInfoModal
            ? "visible"
            : "invisible")
        }
        style={{ bottom: getBottomMargin() }}
      >
        {props.profiles?.map((profile, index) => {
          return (
            <div className="flex flex-1 flex-row min-w-[100px] justify-between" key={index}>
              <label>{profile.profileData.name}</label>
              <ArrowRight />
            </div>
          );
        })}
      </div>
    </motion.div>
  );
};

export default SkillBar;
