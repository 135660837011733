import { colors } from "@material-ui/core";
import { dark } from "../colors";
import { useState } from "react";
import type { ProfileComponentProps, SkillCount, UserProfile } from "../types";
import DefaultIcon from "../assets/op_o.png";

import SkillIcon from "./atoms/SkillIcon";
import ProfileMenuTab from "./ProfileMenuTab";
import ProfileTab from "./ProfileTab";
interface OrgMemberRowProps extends ProfileComponentProps {
  handleClick: () => void;
  userProfile: UserProfile;
}

export default function OrgMemberRow({
  handleClick,
  isDarkModeActive,
  userProfile,
}: OrgMemberRowProps) {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  function compareSkillCount(a: SkillCount, b: SkillCount) {
    if (a.projectIds && b.projectIds) {
      if (a.projectIds.length < b.projectIds.length) {
        return 1;
      } else if (a.projectIds.length > b.projectIds.length) {
        return -1;
      }
    }
    return 0;
  }

  function getTop3Skills(profileSkillSummary: SkillCount[]) {
    const topSkills = profileSkillSummary.sort(compareSkillCount);

    var top3Skills: SkillCount[] = [];
    for (var i = 0; i < 3; i++) {
      if (topSkills[i] != null) {
        top3Skills.push(topSkills[i]);
      }
    }

    return top3Skills;
  }

  return (
    <div className="flex flex-row flex-1 px-2 py-2 min-h-[70px] max-h-[70px] justify-between items-center border-b-[1px] border-b-op-gray cursor-pointer hover:bg-gray-200">
      <div className="flex-1 flex items-center">
        <ProfileTab
          photoAssetId={userProfile.profilePhotoId}
          onClick={() => handleClick()}
          removeable={false}
          inEditor={false}
          className="max-w-[70px] w-[70px]"
        />

        <div className="flex-1">
          <p>{userProfile.profileData.name}</p>
          <p className="text-op-blue font-semibold">{userProfile.isManager ? "Manager" : ""}</p>
        </div>
      </div>
      <div className="flex-1">
        <p>{userProfile.profileData.title}</p>
      </div>
      <div className="inline-flex flex-1 justify-end">
        {userProfile.profileData.profileSkillSummary != null
          ? getTop3Skills(userProfile.profileData.profileSkillSummary!).map((sc: SkillCount) => {
              return <SkillIcon skillName={sc.name} styling="ml-2" />;
            })
          : null}
      </div>
    </div>
  );
}
