import vocsLogo from "../../assets/vocsstudio.webp";
import { faBuilding, faUser } from "@fortawesome/free-regular-svg-icons";
import { MenuSection } from "../menu-section";
import { IconButtonLink } from "./IconButtonLink";
import { ImageButtonLink } from "./ImageButtonLink";
import { getBaseUrl, scrollToTop } from "./utils";
import { cn } from "../utils/cn";
import { useSnapshot } from "valtio";
import { tabsState } from "../../state/tabs";
import { MemberButtonLink } from "./MemberButtonLink";
import { ClientButtonLink } from "./ClientButtonLink";

interface Props {
  organizationId: string;
  className?: string;
}

const NavigationDesktopBar = ({ organizationId, className }: Props) => {
  const tabsSnap = useSnapshot(tabsState);
  const baseUrl = getBaseUrl(organizationId);

  return (
    <div
      className={cn("flex flex-row gap-10 items-center flex-grow flex-shrink min-w-0", className)}
    >
      <ImageButtonLink href={baseUrl} imageUrl={vocsLogo} alt="VOCS Studio" onClick={scrollToTop} />
      <div className="flex flex-row gap-8 items-center flex-grow flex-shrink overflow-x-scroll">
        <MenuSection>
          <IconButtonLink href={`${baseUrl}/members`} icon={faUser} />
          {tabsSnap.members.length ? <MenuSection.Separator /> : null}
          {tabsSnap.members.map((memberId) => (
            <MemberButtonLink key={memberId} organizationId={organizationId} memberId={memberId} />
          ))}
        </MenuSection>
        <MenuSection>
          <IconButtonLink href={`${baseUrl}/clients`} icon={faBuilding} />
          {tabsSnap.clients.length ? <MenuSection.Separator /> : null}
          {tabsSnap.clients.map((clientId) => (
            <ClientButtonLink key={clientId} organizationId={organizationId} clientId={clientId} />
          ))}
        </MenuSection>
      </div>
    </div>
  );
};

NavigationDesktopBar.displayName = "NavigationDesktopBar";

export { NavigationDesktopBar };
