import {
  getYears,
  getSelectedMonthValue,
  getSelectedYearValue,
  monthNames,
  monthValues,
} from "../utils";
import { Briefcase } from "react-feather";

import LinkButton from "./LinkButton";
import type { ProfileComponentProps } from "../types";
import { useState } from "react";
import { darkModeBlack } from "../colors";
import "./components.css";

interface SectionOptionItemProps extends ProfileComponentProps {
  title: string;
  info: string;
  icon: React.ReactNode;
  onSelect: () => void;
}

function SectionOptionItem({
  title,
  info,
  icon,
  isDarkModeActive,
  onSelect,
}: SectionOptionItemProps) {
  const [isMouseHovering, setIsMouseHovering] = useState<boolean>(false);

  const backgroundColor = isDarkModeActive ? darkModeBlack : "white";
  const color = isDarkModeActive ? "white" : darkModeBlack;
  const hoverBackgroundColor = isDarkModeActive ? "gray" : "#e3e3e3";

  return (
    <div
      onClick={() => {
        onSelect();
      }}
      onMouseEnter={(e) => {
        setIsMouseHovering(true);
      }}
      onMouseLeave={(e) => {
        setIsMouseHovering(false);
      }}
      className="section-option-item"
      style={{
        backgroundColor: isMouseHovering ? hoverBackgroundColor : backgroundColor,

        color: color,
      }}
    >
      <div
        style={{
          flex: "1",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        {icon}
      </div>

      <div style={{ flex: "8" }}>
        <h3>{title}</h3>
        <p>{info}</p>
      </div>
    </div>
  );
}

export default SectionOptionItem;
