import type { ResourceComment } from "../../../../../client";
import { EventTemplate } from "./EventTemplate";

interface Props {
  comment: ResourceComment;
  organizationId: string;
}

export const LeadComment = ({ comment, organizationId }: Props) => (
  <EventTemplate
    createdAt={comment.createdAt}
    message={comment.comment}
    organizationId={organizationId}
    profileId={comment.commenterProfileId}
  />
);

LeadComment.displayName = "LeadComment";
