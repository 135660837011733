import type { MouseEventHandler, PropsWithChildren } from "react";
import { cn } from "../../utils/cn";

type Props = PropsWithChildren<{
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}>;

const IndicatorButton = ({ children, className, onClick }: Props) => (
  <span
    className={cn(
      "cursor-pointer flex justify-center items-center w-6 h-6 rounded-lg transition-all duration-200 bg-gray-3",
      "hover:bg-gray-4 active:scale-90",
      className
    )}
    onClick={onClick}
    // TODO: add onKeyDown or onKeyUp
  >
    {children}
  </span>
);

IndicatorButton.displayName = "IndicatorButton";

export { IndicatorButton };
