import { queryOptions, useQuery } from "@tanstack/react-query";
import { getV1OrganizationsByOrganizationIdLeadsOptions } from "../../../client/@tanstack/react-query.gen";
import type { QueryConfig } from "../../utils/query-config";

export const getLeadsOptions = (organizationID: string) =>
  queryOptions({
    ...getV1OrganizationsByOrganizationIdLeadsOptions({
      path: { organizationID },
    }),
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

export const useGetLeads = (organizationID: string) => {
  return useQuery(getLeadsOptions(organizationID));
};

type GetLeadInput = {
  organizationID: string;
  leadID: string;
};

export const useGetLead = ({ organizationID, leadID }: GetLeadInput, config?: QueryConfig) => {
  return useQuery({
    ...getLeadsOptions(organizationID),
    ...config,
    select: (data) => data.find((lead) => lead.id === leadID),
  });
};
