import {
  faTemperatureDown,
  faTemperatureHalf,
  faTemperatureUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "../../../../../components/utils/cn";

interface TemperatureIconProps {
  priority: number;
}

export const TemperatureIcon = ({ priority }: TemperatureIconProps) => (
  <FontAwesomeIcon
    width={20}
    height={20}
    icon={priority === 2 ? faTemperatureUp : priority === 0 ? faTemperatureDown : faTemperatureHalf}
    className={cn(
      "w-5 h-5 shrink-0",
      priority === 2 ? "text-red-3" : priority === 0 ? "text-cold-blue" : "text-neutral-yellow"
    )}
  />
);

TemperatureIcon.displayName = "TemperatureIcon";
