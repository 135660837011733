import { useNavigate } from "react-router-dom";
import OPIllustration from "../assets/op_illustration_full.png";

import Button, { ButtonSize, ButtonStyleType } from "../components/atoms/Button";
import { useAuth0 } from "@auth0/auth0-react";
import LandingPageSection from "../components/LandingPageSection";
import MenuHeader from "../components/MenuHeader";

function Landing() {
  const navigate = useNavigate();

  const { loginWithRedirect } = useAuth0();

  return (
    <div>
      <MenuHeader onTabSelect={() => {}} activeTabs={[]} onTabCloseClick={() => {}} />
      <div className="px-2 md:px-60 py-8">
        <LandingPageSection
          title="Hi!"
          info="Imagine that you, from this day on, only need One Profile that you can use for the rest of your life."
          secondInfo="In this One, all your experiences and skills are included. Your latest project and your first. It’s your professional legacy!"
        >
          <h3 className="text-4xl text-op-dark font-bold mb-4">it's your oneprofile!</h3>
          <Button
            text="Try it!"
            style={ButtonStyleType.primary}
            onButtonClick={() => {
              loginWithRedirect();
            }}
          />
        </LandingPageSection>
        <LandingPageSection
          title="- But how?"
          info="With a few clicks you can create unique versions based on your oneprofile."
          secondInfo="No additional editing, writing or moving stuff around. Simply pick what to include and share it accordingly."
        >
          <Button
            text="Go get that dream job"
            style={ButtonStyleType.link}
            onButtonClick={() => {
              loginWithRedirect();
            }}
            underline
            size={ButtonSize.mega}
            fontWeight="font-regular"
          />
        </LandingPageSection>

        <div className="md:block hidden">
          <LandingPageSection
            title="- But how do you mean?"
            info="You need to try it to feel it’s power! But here is an illustration as well"
          >
            <div className="flex flex-1 justify-end">
              <Button
                text="View example link"
                style={ButtonStyleType.primary}
                onButtonClick={() => {
                  navigate("/p/bxb0u1hb");
                }}
                fontWeight="font-regular"
              />
            </div>

            <img src={OPIllustration} />

            <div className="flex flex-1 flex-col align-center items-center">
              <Button
                text="Create your oneprofile"
                style={ButtonStyleType.primary}
                onButtonClick={() => {
                  loginWithRedirect();
                }}
                fontWeight="font-regular"
              />
              <p className="text-xs mt-2">No credit card required</p>
            </div>
          </LandingPageSection>
        </div>

        <LandingPageSection
          title="Still don't get it?"
          titleStyle="text-op-dark italic   "
          info="Get in touch and we will explain more!"
        >
          <p className="font-semibold text-sm underline">hello@oneprofile.com</p>
        </LandingPageSection>
        <LandingPageSection
          title="Managing a team of engineers?"
          titleStyle="text-op-dark italic"
          info="We have something that you don’t want to live without. Get in touch!"
        >
          <p className="font-semibold text-sm underline">hello@oneprofile.com</p>
        </LandingPageSection>
      </div>
    </div>
  );
}

export default Landing;
