import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { Client } from "../../../../client";
import { Avatar } from "../../../../components";
import { cn } from "../../../../components/utils/cn";
import { getImageUrl } from "../../../../utils/image";
import { LeadButton, type LeadButtonProps } from "./LeadButton";

interface Props extends LeadButtonProps {
  client?: Client;
  contactPersonEmail?: string;
}

export const LeadClientButton = ({ client, contactPersonEmail, className, ...props }: Props) => (
  <LeadButton {...props} className={cn("grow lg:w-48 xl:w-56", className)}>
    {client ? (
      <Avatar
        // TODO: Default image
        imageUrl={client.logoId ? getImageUrl(client.logoId) : undefined}
        alt={client.name}
        className="w-10 h-10 shrink-0"
      />
    ) : (
      <FontAwesomeIcon width={20} height={20} icon={faBuilding} className="w-5 h-5 shrink-0" />
    )}
    <div className="flex-grow min-w-0 overflow-hidden text-left">
      <p className="truncate">{client?.name ?? "client"}</p>
      {contactPersonEmail && <p className="truncate">{contactPersonEmail}</p>}
    </div>
  </LeadButton>
);

LeadClientButton.displayName = "LeadClientButton";
