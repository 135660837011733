import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import isURL from "validator/lib/isURL";
import type { TransformFunction, ValidationFunction } from "./types";

export const trim: TransformFunction = (value: string) => value.trim();

export const isEmailOrEmpty: ValidationFunction = (value: string) => value === "" || isEmail(value);

export const isPhoneNumberOrEmpty: ValidationFunction = (value: string) =>
  value === "" || isMobilePhone(value);

export const isUrlOrEmpty: ValidationFunction = (value: string) => value === "" || isURL(value);

export const isString = (value: unknown): value is string => typeof value === "string";

export const DEFAULT_TYPING_TIMEOUT = 1000;
