import type { ComponentProps } from "react";
import defaultUserImage from "../../assets/op_o.png";
import { useGetClient } from "../../services";
import { removeClientTab } from "../../state/tabs";
import { getImageUrl } from "../../utils/image";
import { IndicatorImageButtonLink } from "./IndicatorImageButtonLink";
import { getBaseUrl } from "./utils";

interface Props extends Partial<ComponentProps<typeof IndicatorImageButtonLink>> {
  organizationId: string;
  clientId: string;
}

export const ClientButtonLink = ({ organizationId, clientId, ...props }: Props) => {
  const baseUrl = getBaseUrl(organizationId);
  const { data, isSuccess } = useGetClient({
    organizationID: organizationId,
    clientId,
  });

  return (
    <IndicatorImageButtonLink
      href={`${baseUrl}/clients/${clientId}`}
      imageUrl={data?.logoId ? getImageUrl(data.logoId) : isSuccess ? defaultUserImage : undefined}
      alt={data?.name ?? "Client"}
      onClose={() => removeClientTab(clientId)}
      {...props}
    />
  );
};

ClientButtonLink.displayName = "ClientButtonLink";
