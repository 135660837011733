import { Mail, Phone, User } from "react-feather";
import EditorTitle from "./EditorTitle";
import EditorTextField from "./EditorTextField";
import type { ProfileContactDetails } from "../types";

interface Props {
  profileContactDetails: ProfileContactDetails;
  editable: boolean;
  onUpdatePhone: (phone: string) => void;
  phone: string;
}

function ContactBox({ profileContactDetails, editable, onUpdatePhone, phone }: Props) {
  return (
    <div className="mt-6">
      <EditorTitle title="Get in touch!" />
      <div className="bg-op-light-gray w-full font-semibold p-4 mt-2 text-base ml-[26px]">
        <div className="flex flex-1 flex-row items-center align-center">
          <User color="#202124" size={16} />
          <label className="ml-2">{profileContactDetails.name}</label>
        </div>
        <div className="flex flex-1 flex-row items-center align-center">
          <Mail color="#202124" size={16} />
          <label className="ml-2">{profileContactDetails.email}</label>
        </div>

        {editable || phone !== "" ? (
          <div className="flex flex-1 flex-row items-center align-center">
            <Phone color="#202124" size={16} className="mr-2" />
            <EditorTextField
              disableEditing={!editable}
              disableHoverUnderline={false}
              isDarkModeActive={false}
              placeholder="Phone"
              onLeaveField={(input) => {
                onUpdatePhone(input);
              }}
              inputStyles={{ fontSize: 16, fontWeight: 600 }}
              value={phone}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
export default ContactBox;
