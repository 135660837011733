import React, { useState } from "react";
import { type ImageUpload, ProfileComponentProps, type ProjectPhoto } from "../types";
import "./components.css";
import animationData from "../assets/spinner-animation.json";
import Lottie from "react-lottie";
import DefaultIcon from "../assets/op_o.png";
import ProjectPhotoItem from "./ProjectPhotoItem";
import { createProjectPhoto, getProjectPhotos } from "../requests";

interface Props {
  projectId: string;
  allowEdit: boolean;
  projectPhotos: ProjectPhoto[];
  onProjectPhotoSelect?: (assetId: string) => void;
  onProjectPhotosUpdate?: (updatedProjectPhotos: ProjectPhoto[]) => void;
}

export default function ProjectPhotosRow(props: Props) {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [newAssetIds, setNewAssetIds] = useState<string[]>([]);

  const onImageSelected = (imageUpload: ImageUpload) => {
    setIsUploading(true);
    createProjectPhoto(props.projectId, imageUpload)
      .then((assetId: string) => {
        setIsUploading(false);
        setNewAssetIds([...newAssetIds, assetId]);

        getProjectPhotos().then((updatedProjectPhotos: ProjectPhoto[]) => {
          if (props.onProjectPhotosUpdate) {
            props.onProjectPhotosUpdate(updatedProjectPhotos);
          }
        });
      })
      .catch((error) => {
        setIsUploading(false);
      });
  };

  return (
    <div className="flex flex-row flex-1 flex-wrap">
      {props.projectPhotos.map((projectPhoto, index) => {
        return (
          <div className="mr-4" key={index}>
            <ProjectPhotoItem
              key={props.projectId + index}
              id={props.projectId + index}
              isLoading={false}
              allowEdit={true}
              round={false}
              onImageSelected={(imageUpload: ImageUpload) => {}}
              photoAssetId={projectPhoto.assetId}
              onProjectPhotoSelect={props.onProjectPhotoSelect}
            />
          </div>
        );
      })}

      {props.allowEdit ? (
        <ProjectPhotoItem
          key={props.projectId + "-upload"}
          id={props.projectId + "-upload"}
          isLoading={isUploading}
          allowEdit={true}
          round={false}
          onImageSelected={(imageUpload: ImageUpload) => onImageSelected(imageUpload)}
        />
      ) : null}
    </div>
  );
}
