import type { ProfileComponentProps } from "../../types";
import { Plus } from "react-feather";
interface ButtonProps extends ProfileComponentProps {
  text: string;
  onButtonClick: () => void;
  style?: ButtonStyleType;
  mega?: boolean;
  viewStyle?: React.CSSProperties;
  outline?: boolean;
  white?: boolean;
  primary?: boolean;
  secondary?: boolean;
  disabled?: boolean;
  size?: ButtonSize;
  fontWeight?: string;
  underline?: boolean;
}

export enum ButtonStyleType {
  primary = 0,
  secondary = 1,
  danger = 2,
  link = 3,
  action = 4,
  actionLink = 5,
}

export enum ButtonSize {
  tiny = 0,
  small = 1,
  mega = 2,
}

export default function Button({
  text,
  onButtonClick,
  isDarkModeActive,
  style,
  mega,
  viewStyle,
  outline,
  white,
  primary,
  secondary,
  disabled,
  size,
  fontWeight,
  underline,
}: ButtonProps) {
  let classNameString = " py-1 font-bold ";
  /*
  background-color: red;
  padding: 5px 15px 5px 15px;
  height: 40px;
  border: 8px solid transparent;
  color: #fff;
  font-weight: 800;
*/
  switch (style) {
    case ButtonStyleType.primary:
      classNameString += " bg-op-dark border-op-dark text-white hover:opacity-70 border-4 px-3.5";
      break;
    case ButtonStyleType.secondary:
      classNameString +=
        " bg-transparent border-op-dark text-op-dark hover:opacity-70 border-4 px-3.5";
      break;
    case ButtonStyleType.danger:
      classNameString +=
        " bg-red-500 text-white border-red-500 hover:bg-transparent hover:opacity-70  border-4 px-3.5";
      break;
    case ButtonStyleType.link:
      classNameString = "hover:underline";

      if (underline) {
        classNameString += " underline";
      }

      if (fontWeight) {
        classNameString += " " + fontWeight;
      } else {
        classNameString += " font-bold";
      }

      break;
    case ButtonStyleType.action:
      classNameString += " bg-op-blue text-white border-op-blue hover:opacity-70  border-4 px-3.5";
      break;
    case ButtonStyleType.actionLink:
      classNameString += " text-op-blue border-0 hover:opacity-[50%] p-0";
      break;
  }

  if (size !== undefined) {
    switch (size) {
      case ButtonSize.tiny:
        classNameString += " text-tiny";
        break;
      case ButtonSize.small:
        classNameString += " text-sm";
        break;
      case ButtonSize.mega:
        classNameString += " text-4xl";
        break;
      default:
        break;
    }
  }

  /*
    background-color: #202124;
  padding: 5px 15px 5px 15px;
  border: 8px solid #202124;
  color: #e8eaed;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  */

  if (mega) {
    classNameString += " button-mega";
  }

  if (isDarkModeActive || white) {
    classNameString += "-white";
  }

  if (outline) {
    classNameString += " button-outline";
  }

  if (disabled) {
    classNameString += " pointer-none opacity-70";
  }

  const styles = viewStyle ?? {};

  return (
    <button
      className={classNameString}
      onClick={() => onButtonClick()}
      style={styles}
      disabled={disabled}
    >
      {text}
    </button>
  );
}

export const SecondaryButton = (props: ButtonProps) => {
  return (
    <Button
      style={ButtonStyleType.secondary}
      text={props.text}
      onButtonClick={props.onButtonClick}
      viewStyle={props.viewStyle}
    />
  );
};

export const PrimaryButton = (props: ButtonProps) => {
  return (
    <Button style={ButtonStyleType.primary} text={props.text} onButtonClick={props.onButtonClick} />
  );
};
