import type React from "react";
import { useEffect, useState } from "react";
import IconInput from "../IconInput";
import { FileText, User } from "react-feather";
import { createLead, getAssignmentInfo, getClientByUrl, updateLead } from "../../requests";
import type { AssignmentInfo, Client, Lead } from "../../types";
import IconTextArea from "../IconTextArea";
import SkillIcon from "../atoms/SkillIcon";
import ExpandableList from "../ExpandableList";
import Lottie from "react-lottie";
import magic_loader from "../../assets/magic_loader.json";
import LockableInput from "../LockableInput";
import { useCurrentOrgId } from "../../hooks/hooks";

interface AssignmentEditorProps {
  lead?: Lead | null;
  onSaveLead: (lead: Lead) => void;
  onLeadCreated: (newLead: Lead) => void;
}

const AssignmentEditor: React.FC<AssignmentEditorProps> = ({ lead, onSaveLead, onLeadCreated }) => {
  const [currentLead, setCurrentLead] = useState<Lead | null>(null);
  const [isLoadingAssignmentInfo, setIsLoadingAssignmentInfo] = useState<boolean>(false);
  const [currentLeadSkills, setCurrentLeadSkills] = useState<string[]>([]);
  const [currentLeadAssignmentInfo, setCurrentLeadAssignmentInfo] = useState<AssignmentInfo | null>(
    null
  );
  const [logoSuggestionUrls, setLogoSuggestionUrls] = useState<string[]>([]);

  const currentOrgId = useCurrentOrgId();

  useEffect(() => {
    if (lead) {
      setCurrentLead({ ...lead });
      if (lead.assignmentInfo) {
        setCurrentLeadAssignmentInfo(lead.assignmentInfo);
        setCurrentLeadSkills(lead.assignmentInfo.skills);
      }
    } else {
      setCurrentLead({
        id: "",
        title: "",
        clientId: "",
        description: "",
        contactPersonName: "",
        contactPersonEmail: "",
        contactPersonPhone: "",
        profileLinkId: "",
        status: "",
        comments: [],
        events: [],
        numberOfUnreadEvents: 0,
      });
    }
  }, [lead]);

  const fetchAssignmentInfo = (url: string) => {};

  const saveLead = () => {
    if (currentLead) {
      updateLead(currentOrgId!, currentLead?.id, currentLead).then((savedLead) => {
        onSaveLead(savedLead);
      });
    }
  };

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex flex-row mt-4">
        <div className="flex-1 p-2">
          <LockableInput
            icon={<User />}
            placeholder="Link to the assigment description"
            className="mt-2"
            onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.value && event.target.value.length > 5) {
                setIsLoadingAssignmentInfo(true);
                setCurrentLeadAssignmentInfo(null);

                var url = event.target.value;
                var cleanedUrl = url.replace(/^(https?:\/\/)?(www\.)?/, "");

                // Remove any path, query parameters, or fragments after the top domain
                cleanedUrl = cleanedUrl.split("/")[0];

                getClientByUrl(currentOrgId!, url)
                  .then((clientData: Client) => {
                    getAssignmentInfo(url).then((info: AssignmentInfo) => {
                      const updatedLead = {
                        ...currentLead!,
                        title: info.title,
                        assignmentInfo: {
                          ...info,
                          assignmentUrl: url,
                        },
                      };
                      setCurrentLead(updatedLead);
                      setCurrentLeadSkills(info.skills);
                      setCurrentLeadAssignmentInfo(info);
                      setIsLoadingAssignmentInfo(false);

                      if (currentLead?.id === "") {
                        //Create the lead
                        createLead(currentOrgId!, updatedLead).then((createdLead: Lead) => {
                          setCurrentLead(createdLead);
                          onLeadCreated(createdLead);
                        });
                      } else {
                        //Update the lead with the assignment info
                        updateLead(currentOrgId!, updatedLead.id, updatedLead).then(
                          (newLead: Lead) => {
                            setCurrentLead(newLead);
                            onSaveLead(newLead);
                          }
                        );
                      }
                    });
                  })
                  .catch((err) => {});
              }
            }}
            locked={isLoadingAssignmentInfo || currentLeadAssignmentInfo !== null}
            onActionButtonClick={() => {}}
          />
        </div>

        <div className="flex-2 p-2">
          <div>
            <IconInput
              icon={<FileText />}
              placeholder="Title of the assignment or role"
              value={currentLead?.title}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCurrentLead({ ...currentLead!, title: event.target.value });
              }}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                saveLead();
              }}
              className="mt-2"
            />
            {currentLeadAssignmentInfo ? (
              <div>
                <h6 className="text-md font-semibold pb-1">Technical skills</h6>
                <div className="flex flex-row flex-1">
                  {currentLeadSkills.map((skillName, index) => {
                    return (
                      <div className="relative">
                        <SkillIcon key={index} skillName={skillName} />
                      </div>
                    );
                  })}
                </div>

                <div>
                  <h6 className="text-md font-semibold mt-4 pb-1">All skills</h6>
                  <ExpandableList
                    items={currentLeadAssignmentInfo && currentLeadAssignmentInfo.allSkills}
                    initialDisplayCount={3}
                  />
                </div>

                <div>
                  <h6 className="text-md font-semibold mt-4 pb-1">Responsibilities</h6>
                  <ExpandableList
                    items={currentLeadAssignmentInfo && currentLeadAssignmentInfo.responsibilities}
                    initialDisplayCount={3}
                  />
                </div>

                <h6 className="text-md font-semibold mt-4 pb-1">Location</h6>
                <div className="flex">
                  {currentLeadAssignmentInfo &&
                    currentLeadAssignmentInfo.location.map((location, index) => {
                      return location + ", ";
                    })}
                </div>
                <h6 className="text-md font-semibold mt-4 pb-1">Remote</h6>
                <p>{currentLeadAssignmentInfo ? currentLeadAssignmentInfo.remote : ""}</p>
              </div>
            ) : isLoadingAssignmentInfo ? (
              <div className="text-center">
                <Lottie style={{ width: 80 }} options={{ animationData: magic_loader }} />
                <p>Setting up your assignment...</p>
              </div>
            ) : null}
            <IconTextArea
              icon={<User />}
              placeholder="Add custom assignment description"
              value={currentLead?.description}
              onChange={() => {}}
              className="mt-12 border-dotted"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentEditor;
