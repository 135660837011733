import { useState, useRef, useEffect } from "react";
import "../../index.css";
import "../../components/components.css";
import Slider from "@material-ui/core/Slider";
import Button, { ButtonStyleType } from "../atoms/Button";

const VkImageCropperControls = ({
  zoom,
  onZoomChange,
  onContinueButtonClick,
  onCancelButtonClick,
  onBackButtonClick,
}) => {
  if (onBackButtonClick !== null) {
    return (
      <div id="vk-crop-tool-controls">
        <button onClick={onBackButtonClick}>Back</button>
        <button onClick={onContinueButtonClick}>Save</button>
      </div>
    );
  }

  return (
    <div id="vk-crop-tool-controls">
      <h3 className="text-xl font-bold">Customize photo</h3>
      <label>Use the slider to zoom in and out of your photo</label>
      <Slider
        value={zoom}
        min={1}
        max={5}
        step={0.1}
        aria-labelledby="Zoom"
        onChange={(e, zoom) => {
          onZoomChange(zoom);
        }}
        style={{ color: "#171212" }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Button onButtonClick={onCancelButtonClick} text="Cancel" style={ButtonStyleType.link} />
        <Button onButtonClick={onContinueButtonClick} text="Save" style={ButtonStyleType.primary} />
      </div>
    </div>
  );
};

export default VkImageCropperControls;
