import type { Account } from "./types";
export interface UserState {
  account: Account | null;
  isSuperAccount: boolean;
}

const initialState: UserState = { account: null, isSuperAccount: false };

type Action = { type: "SIGNED_IN"; payload: UserState };

export const userReducer = (state: UserState = initialState, action: Action) => {
  switch (action.type) {
    case "SIGNED_IN":
      return {
        ...state,
        account: action.payload.account,
        isSuperAccount: action.payload.isSuperAccount,
      };
    default:
      return state;
  }
};
