import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { darkModeBlack } from "../../colors";
import Button, { ButtonStyleType } from "../atoms/Button";
import "../components.css";
import YearSelect from "../YearSelect";
interface Props {
  open: boolean;
  darkModeActive: boolean;
  onBackdropClick: () => void;
  onCancel: () => void;
  onClose: () => void;
  onDateUpdate: (startDate: string, endDate: string) => void;
  selectedStartDate: string;
  selectedEndDate: string;
}

const WorkplaceDatesDialog = (props: Props) => {
  const [selectedStartYearValue, setSelectedStartYearValue] = useState<string>("");
  const [selectedEndYearValue, setSelectedEndYearValue] = useState<string>("");

  useEffect(() => {
    if (props.selectedStartDate !== "") {
      setSelectedStartYearValue(props.selectedStartDate);
    }

    if (props.selectedEndDate !== "") {
      setSelectedEndYearValue(props.selectedEndDate);
    }
  }, []);

  return (
    <Dialog open={props.open} onBackdropClick={props.onClose} fullWidth>
      <DialogContent
        style={{
          backgroundColor: props.darkModeActive ? darkModeBlack : "#fff",
          color: props.darkModeActive ? "#fff" : darkModeBlack,
        }}
      >
        <h6 className="text-xl font-bold">Workplace dates</h6>

        <YearSelect
          firstOption="Start Year"
          label="When did you start here?"
          onYearSelect={(selectedYear: string) => {
            setSelectedStartYearValue(selectedYear);
            props.onDateUpdate(selectedYear, selectedEndYearValue);
          }}
          selected={selectedStartYearValue}
        />

        <YearSelect
          firstOption="End Year"
          label="When (if) did you leave this position? Leave empty if this is your current position"
          onYearSelect={(selectedYear: string) => {
            setSelectedEndYearValue(selectedYear);
            props.onDateUpdate(selectedStartYearValue, selectedYear);
          }}
          selected={selectedEndYearValue}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            justifyContent: "flex-end",
            marginTop: 20,
          }}
        >
          <Button text="Close" onButtonClick={props.onClose} style={ButtonStyleType.primary} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default WorkplaceDatesDialog;
