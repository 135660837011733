import { faBell } from "@fortawesome/free-regular-svg-icons";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { Client, Lead } from "../../../../client";
import { Popover, PopoverTrigger } from "../../../../components";
import { cn } from "../../../../components/utils/cn";
import { homeState } from "../../../../state/home";
import type { LeadRowActiveState } from "../../../../state/leads";
import { LeadAssignmentButton } from "./LeadAssignmentButton";
import { LeadButton } from "./LeadButton";
import { LeadClientButton } from "./LeadClientButton";
import { LeadMemberButton } from "./LeadMemberButton";
import { LeadPanelContent } from "./LeadPanelContent";
import { LeadQRButton } from "./LeadQRButton";
import { NumberOfUnreadEvents } from "./NumberOfUnreadEvents";
import { TemperatureIcon } from "./icon";
import { LeadMenu, TemperatureMenu } from "./menu";

interface Props {
  organizationId: string;
  activeState: LeadRowActiveState;
  toggleActiveState: (state: LeadRowActiveState) => void;
  client?: Client;
  lead?: Lead;
  onUpdateLead?: (payload: Partial<Lead>) => void;
}

export const LeadRow = ({
  organizationId,
  activeState,
  toggleActiveState,
  client,
  lead,
  onUpdateLead,
}: Props) => {
  const leadPriority = lead?.priority ?? 1;
  const leadNumberOfUnreadEvents = lead?.numberOfUnreadEvents ?? 0;

  return (
    <li
      className={cn(
        "flex flex-col p-2 rounded-2xl bg-white transition-colors duration-300 overflow-hidden",
        activeState && "lg:bg-gray-1"
      )}
    >
      <div
        className={cn(
          "hidden w-full lg:flex flex-row gap-4 items-center justify-between overflow-hidden md:overflow-auto",
          !lead && "lg:grid grid-cols-4"
        )}
      >
        <LeadClientButton
          active={activeState === "client"}
          onClick={() => toggleActiveState("client")}
          client={client}
          contactPersonEmail={lead?.contactPersonEmail}
        />

        <LeadAssignmentButton
          active={activeState === "assignment"}
          onClick={() => toggleActiveState("assignment")}
          title={lead?.title || lead?.assignmentInfo?.title}
        />

        <LeadMemberButton
          active={activeState === "member"}
          onClick={() => toggleActiveState("member")}
          profileId={lead?.profileId ?? undefined}
          organizationId={organizationId}
        />

        <LeadQRButton
          active={activeState === "qr"}
          onClick={() => toggleActiveState("qr")}
          profileLinkId={lead?.profileLinkId}
        />

        {lead && (
          <div className="flex flex-row gap-1 items-center self-stretch">
            <LeadButton
              active={activeState === "events"}
              onClick={() => {
                toggleActiveState("events");
                // Reset the number of unread events to 0
                if (leadNumberOfUnreadEvents > 0) onUpdateLead?.({ numberOfUnreadEvents: 0 });
              }}
              className="hidden lg:flex lg:px-2"
            >
              <div className="w-9 h-9 p-2 relative shrink-0">
                <FontAwesomeIcon width={20} height={20} icon={faBell} className="w-5 h-5" />
                <NumberOfUnreadEvents numberOfUnreadEvents={leadNumberOfUnreadEvents} />
              </div>
            </LeadButton>
            <Popover>
              <PopoverTrigger asChild>
                <LeadButton className="hidden lg:flex">
                  <TemperatureIcon priority={leadPriority} />
                </LeadButton>
              </PopoverTrigger>
              <TemperatureMenu
                priority={leadPriority}
                onPriorityChange={(priority) => onUpdateLead?.({ priority })}
              />
            </Popover>
            <Popover>
              <PopoverTrigger asChild>
                <LeadButton className="px-4">
                  <FontAwesomeIcon
                    width={20}
                    height={20}
                    icon={faEllipsis}
                    className="w-5 h-5 shrink-0"
                  />
                </LeadButton>
              </PopoverTrigger>
              <LeadMenu onArchive={() => onUpdateLead?.({ status: "archived" })} />
            </Popover>
          </div>
        )}
      </div>

      <div className="flex flex-col lg:hidden">
        <LeadClientButton
          client={client}
          contactPersonEmail={lead?.contactPersonEmail}
          onClick={() => {
            homeState.selectedLead = {
              leadId: lead?.id,
              state: "client",
            };
          }}
        />
      </div>

      <LeadPanelContent
        state={activeState}
        organizationId={organizationId}
        className="hidden lg:block"
        divider
        leadId={lead?.id}
      />
    </li>
  );
};

LeadRow.displayName = "LeadRow";
