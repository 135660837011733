import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "../utils/cn";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

interface Props {
  className?: string;
  label: string;
}

export const Notice = ({ label, className }: Props) => {
  return (
    <div
      className={cn(
        "flex flex-row items-center p-4 text-black bg-gray-2 gap-1 rounded-2xl",
        className
      )}
    >
      <FontAwesomeIcon icon={faCircleInfo} width={14} height={14} className="p-2" />
      <p className="text-xs">{label}</p>
    </div>
  );
};

Notice.displayName = "Notice";
