import { faCircle, faQuestion, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { ComponentProps, MouseEventHandler } from "react";
import { forwardRef } from "react";
import { Avatar } from "../../avatar";
import { cn } from "../../utils/cn";
import { IndicatorButton } from "../indicator-button";

type Size = "small" | "medium";

type BaseIndicatorProps = {
  onClick?: () => void;
};

type IconIndicatorProps = BaseIndicatorProps & {
  variant: "active" | "close";
};

type ImageIndicatorProps = BaseIndicatorProps & {
  variant: "image";
  imageUrl: string;
  alt: string;
};

type IndicatorProps = IconIndicatorProps | ImageIndicatorProps;

const isIndicatorProps = (indicatorProps: IndicatorProps): indicatorProps is IconIndicatorProps =>
  (indicatorProps as IconIndicatorProps).variant === "active" ||
  (indicatorProps as IconIndicatorProps).variant === "close";

interface Props extends ComponentProps<typeof Avatar> {
  active?: boolean;
  size?: Size;
  indicatorProps?: IndicatorProps;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const ImageButton = forwardRef<HTMLButtonElement, Props>(
  ({ active, size = "medium", className, indicatorProps, onClick, ...avatarProps }, ref) => (
    <button
      ref={ref}
      type="button"
      className={cn(
        "group relative flex items-center justify-center transition-all",
        size === "medium" && "w-14 h-14 p-2",
        size === "small" && "w-8 h-8 p-1",
        active && "p-0",
        className
      )}
      onClick={onClick}
    >
      <div
        className={cn(
          "bg-gray-3 absolute z-0 transition-all duration-200",
          size === "medium" && "top-2 left-2 right-2 bottom-2 rounded-2xl",
          size === "small" && "top-1 left-1 right-1 bottom-1 rounded-xl",
          "group-hover:top-0 group-hover:bottom-0 group-hover:left-0 group-hover:right-0",
          active && "hidden"
        )}
      />
      <Avatar
        {...avatarProps}
        className={cn(
          "group-active:scale-90 transition-all z-10",
          size === "medium" && "rounded-xl",
          size === "medium" && active && "rounded-2xl",
          size === "small" && "rounded-lg"
        )}
        fallbackClassName={cn(
          "transition-all",
          size === "medium" && "text-2xl",
          size === "medium" && active && "text-3xl",
          size === "small" && "text-xl",
          size === "small" && active && "text-2xl"
        )}
      />
      {indicatorProps && (
        <IndicatorButton
          className={cn(
            "absolute top-0 right-0 z-10 overflow-hidden",
            indicatorProps.variant === "active" && "bg-gray-4"
          )}
          onClick={(e) => {
            // Prevent the click event from bubbling up to the parent button
            // if the indicator is a close button
            indicatorProps.variant === "close" && e.stopPropagation();
            indicatorProps.onClick?.();
          }}
        >
          {isIndicatorProps(indicatorProps) ? (
            <FontAwesomeIcon
              icon={
                indicatorProps.variant === "close"
                  ? faXmark
                  : indicatorProps.variant === "active"
                    ? faCircle
                    : faQuestion
              }
              className={cn(
                indicatorProps.variant === "close" && "text-black",
                indicatorProps.variant === "active" && "text-red-3 w-3 h-3"
              )}
            />
          ) : (
            <img
              src={indicatorProps.imageUrl}
              alt={indicatorProps.alt}
              className={cn("bg-gray-3 w-full h-full object-cover z-10")}
            />
          )}
        </IndicatorButton>
      )}
    </button>
  )
);

ImageButton.displayName = "ImageButton";

export { ImageButton };
