import React, { useState, useRef, useEffect, type FC, type MouseEvent } from "react";
import { Archive, Cloud, CloudSnow, MoreHorizontal, Sun, Thermometer } from "react-feather";

interface RowMenuProps {
  priority: number | null;
  onChange: (priority: number) => void;
}

const PriorityMenu: FC<RowMenuProps> = ({ priority, onChange }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);

  // Close the menu if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside as unknown as EventListener);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside as unknown as EventListener);
    };
  }, []);

  const coldIcon = () => {
    return (
      <div className="justify-center align-center items-center relative flex">
        <div className="flex-row max-h-[20px] items-center justify-center align-center text-center pl-[20px]">
          <Thermometer size={22} color={"#48a0f7"} />
          <CloudSnow size={22} color={"#48a0f7"} className="absolute bottom-0 right-4" />
        </div>
      </div>
    );
  };

  const mediumIcon = () => {
    return (
      <div className="justify-center align-center items-center relative flex">
        <div className="flex-row max-h-[20px] items-center justify-center align-center text-center pl-[20px]">
          <Thermometer size={22} color={"#f7e348"} />
          <Cloud size={22} color={"#f7e348"} className="absolute bottom-0 right-4" />
        </div>
      </div>
    );
  };

  const warmIcon = () => {
    return (
      <div className="justify-center align-center items-center relative flex">
        <div className="flex-row max-h-[20px] items-center justify-center align-center text-center pl-[20px]">
          <Thermometer size={22} color={"#f56245"} />
          <Sun size={22} color={"#f56245"} className="absolute bottom-0 right-4" />
        </div>
      </div>
    );
  };

  const noneIcon = () => {
    return (
      <div className="justify-center align-center items-center relative flex">
        <div className="flex-row max-h-[20px] items-center justify-center align-center text-center">
          <Thermometer size={22} color={"#7a7877"} />
        </div>
      </div>
    );
  };

  const renderPriorityIcon = () => {
    if (priority === null) {
      return noneIcon();
    }

    if (priority === 0) {
      return coldIcon();
    }

    if (priority === 1) {
      return mediumIcon();
    }

    if (priority === 2) {
      return warmIcon();
    }
  };

  return (
    <div className="relative inline-block text-left" ref={menuRef}>
      <div className="h-full align-center">
        <button
          type="button"
          className="inline-flex items-center justify-center w-full h-full rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={() => setIsOpen(!isOpen)}
        >
          {renderPriorityIcon()}
        </button>
      </div>

      {isOpen && (
        <div
          className="origin-top-center absolute left-1/2 transform -translate-x-1/2 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10 p-2"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
          <div className="py-1" role="none">
            <div
              className="block rounded-md px-4 py-4 text-sm hover:bg-gray-200 justify-center flex"
              role="menuitem"
              onClick={() => {
                onChange(0);
                setIsOpen(false);
              }}
            >
              {coldIcon()}
              Cold lead
            </div>
            <div
              className="block rounded-md px-4 py-4 text-sm hover:bg-gray-200 justify-center items-center flex"
              role="menuitem"
              onClick={() => {
                onChange(1);
                setIsOpen(false);
              }}
            >
              {mediumIcon()}
              medium lead
            </div>
            <div
              className="block rounded-md px-4 py-4 text-sm hover:bg-gray-200 justify-center items-center flex"
              role="menuitem"
              onClick={() => {
                onChange(2);
                setIsOpen(false);
              }}
            >
              {warmIcon()}
              hot lead
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PriorityMenu;
