import { useMemo } from "react";
import type { EventProps } from "./types";
import { EventTemplate } from "./EventTemplate";
import { isEventTypeSupported } from "./utils";
import { useLeadEventProps } from "./useLeadEventProps";

export const LeadEvent = (props: EventProps) => {
  const isSupported = useMemo(() => isEventTypeSupported(props.eventType), [props.eventType]);

  const eventProps = useLeadEventProps(props);

  return isSupported ? <EventTemplate {...eventProps} /> : null;
};

LeadEvent.displayName = "LeadEvent";
