import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { Client } from "../../../client";
import { putV1OrganizationsByOrganizationIdClientsByClientIdMutation } from "../../../client/@tanstack/react-query.gen";
import { jsonParse } from "../../utils/parse";
import { getClientsOptions } from "./get-clients";

const mutationKey = ["updateClient"];

const getClientsQueryKey = (organizationID: string) => getClientsOptions(organizationID).queryKey;

export const useUpdateClient = () => {
  const queryClient = useQueryClient();
  return useMutation({
    ...putV1OrganizationsByOrganizationIdClientsByClientIdMutation(),
    mutationKey,
    onSuccess: (client, { path: { organizationID } }) => {
      // Update the cache to include the new client
      queryClient.setQueryData(getClientsQueryKey(organizationID), (oldData) => {
        const newClient = jsonParse<Client>(client);
        if (!oldData) return [newClient];
        const index = oldData.findIndex((item) => item.id === newClient.id);
        if (index === -1) return [...oldData, newClient];
        return [...oldData.slice(0, index), newClient, ...oldData.slice(index + 1)];
      });
    },
    onSettled: (_, __, { path: { organizationID } }) => {
      // Invalidate the query to ensure that the changes are reflected in the UI
      queryClient.invalidateQueries({
        queryKey: getClientsQueryKey(organizationID),
      });
    },
  });
};
