import type { PropsWithChildren } from "react";
import { BulletSectionTitle } from "../../../../../components";
import { cn } from "../../../../../components/utils/cn";

type Props = PropsWithChildren<{
  title: string;
  className?: string;
}>;

export const LeadRowCardSection = ({ title, className, children }: Props) => (
  <form className={cn("flex flex-col gap-2", className)}>
    <BulletSectionTitle>{title}</BulletSectionTitle>
    {children}
  </form>
);

LeadRowCardSection.displayName = "LeadRowCardSection";
