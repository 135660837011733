import { Tooltip } from "@mui/material";
import DefaultIcon from "../assets/op_o.png";

interface Props {
  photoId?: string | undefined;
  url?: string | undefined;
  title?: string;
  small?: boolean;
}

const AvatarImage = (props: Props) => {
  const getSource = () => {
    if (props.photoId) {
      if (props.photoId.indexOf("http") > -1) {
        return props.photoId;
      } else {
        return "https://pub-4b15203a4a0f4b26ab71deeffce0164a.r2.dev/" + props.photoId;
      }
    }

    if (props.url) {
      return props.url;
    }

    return DefaultIcon;
  };

  const sizeClasses = props.small === true ? "w-[40px] h-[40px] " : "w-[50px] h-[50px] ";

  return (
    <div className={sizeClasses + "flex items-center justify-center overflow-hidden rounded-md"}>
      <Tooltip title={props.title}>
        <img src={getSource()} alt="Logo" className="object-cover w-full h-full cursor-pointer" />
      </Tooltip>
    </div>
  );
};

export default AvatarImage;
