import { type PropsWithChildren, forwardRef } from "react";
import { cn } from "../utils/cn";
import { BulletSectionTitle } from "./BulletSectionTitle";

type Props = PropsWithChildren<{
  title: string;
  className?: string;
  containerComponent?: React.ElementType;
}>;

export const Section = forwardRef<HTMLDivElement, Props>(
  ({ title, children, className, containerComponent }, ref) => {
    const ContainerComponent: React.ElementType = containerComponent ?? "div";

    return (
      <div className="gap-2">
        <BulletSectionTitle>{title}</BulletSectionTitle>
        <ContainerComponent
          ref={ref}
          className={cn(
            "p-6 rounded-4xl bg-white border border-gray-2 flex flex-col gap-4",
            className
          )}
        >
          {children}
        </ContainerComponent>
      </div>
    );
  }
);

Section.displayName = "Section";
