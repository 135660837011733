import { forwardRef, useCallback } from "react";
import { cn } from "../utils/cn";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  onCheckedChange?: (checked: boolean) => void;
  label?: string;
}

export const Radio = forwardRef<HTMLInputElement, Props>(
  ({ className, onCheckedChange, onChange, label, ...props }, ref) => {
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        onCheckedChange?.(e.target.checked);
        onChange?.(e);
      },
      [onCheckedChange, onChange]
    );

    return (
      <label className="flex items-center gap-2 cursor-pointer">
        <div className="relative flex flex-row items-center">
          <input
            type="radio"
            ref={ref}
            className={cn(
              "peer appearance-none w-8 h-8 border bg-red-1 border-red-2 rounded-full cursor-pointer transition-colors duration-200",
              "checked:bg-red-2",
              "focus:outline-none",
              className
            )}
            {...props}
            onChange={handleChange}
          />
          <div
            className={cn(
              "absolute inset-0 w-3 h-3 pointer-events-none m-2.5 rounded-full bg-red-2",
              "peer-hover:bg-red-3 peer-checked:bg-red-3 peer-checked:scale-150 peer-active:scale-90",
              "transition-all duration-200"
            )}
          />
        </div>
        {label && <span className="text-xs font-black text-black">{label}</span>}
      </label>
    );
  }
);

Radio.displayName = "Radio";
