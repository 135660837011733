import DefaultIcon from "../assets/op_o.png";

interface Props {
  photoId?: string | undefined;
  url?: string | undefined;
  size?: number;
}

const ProfileImage = (props: Props) => {
  const getSource = () => {
    if (props.photoId) {
      if (props.photoId.indexOf("http") > -1) {
        return props.photoId;
      } else {
        return "https://pub-4b15203a4a0f4b26ab71deeffce0164a.r2.dev/" + props.photoId;
      }
    }

    if (props.url) {
      return props.url;
    }

    return DefaultIcon;
  };

  return (
    <div
      className={
        "rounded-xl overflow-hidden " +
        (props.size
          ? `max-w-[${props.size}px] max-h-[${props.size}px]`
          : "max-w-[80px] max-h-[80px]")
      }
    >
      <img className="object-cover" src={getSource()} alt="" />
    </div>
  );
};

export default ProfileImage;
