import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import type React from "react";
import { forwardRef, useState } from "react";
import MenuDash from "./atoms/MenuDash";
import { useAuth0 } from "@auth0/auth0-react";
import EditorTextButton from "./atoms/EditorTextButton";

export interface SiteNavigationProps {
  currentPath: string;
  isSignedIn?: boolean;
  isSuperAccount: boolean;
  onHoverStart?: () => void;
  onHoverEnd?: () => void;
}

interface NavigationLinkItem {
  title: string;
  onClickCallback: () => void;
  active: boolean;
}

function SiteNavigation(props: SiteNavigationProps, ref: React.ForwardedRef<HTMLDivElement>) {
  const navigate = useNavigate();
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" });
  const { loginWithRedirect, getAccessTokenSilently, logout, isAuthenticated } = useAuth0();
  const navigationLinkItems: NavigationLinkItem[] = [];

  /*
  navigationLinkItems.push({
    title: "Changelog",
    onClickCallback: () => {
      navigate("/changelog");
    },
    active: props.currentPath === "changelog",
  });
  */

  const navigateToStartPage = () => {
    const currentOrgId = localStorage.getItem("current-org-id");
    if (currentOrgId !== null && currentOrgId !== "") {
      navigate("/organizations/" + currentOrgId);
      return;
    }

    const currentProfileId = localStorage.getItem("current-profile-id");
    navigate("/profiles/" + currentProfileId);
  };

  if (props.isSignedIn) {
    navigationLinkItems.push({
      title: "OneProfile",
      onClickCallback: () => {
        navigateToStartPage();
      },
      active: props.currentPath === "dashboard",
    });

    navigationLinkItems.push({
      title: "Sign out",
      onClickCallback: () => {
        logout({
          logoutParams: { returnTo: String(process.env.REACT_APP_SITE_URL) },
        });
        localStorage.removeItem("profiletool-auth-token");
        //navigate("/auth");
      },
      active: props.currentPath === "",
    });
  } else {
    navigationLinkItems.push({
      title: "Sign in",
      onClickCallback: () => {
        loginWithRedirect();
      },
      active: props.currentPath === "auth",
    });
  }

  if (props.isSuperAccount) {
    navigationLinkItems.push({
      title: "Admin",
      onClickCallback: () => {
        navigate("/admin");
      },
      active: props.currentPath === "admin",
    });
  }

  navigationLinkItems.push({
    title: "Info",
    onClickCallback: () => {
      navigate("/");
    },
    active: props.currentPath === "landing",
  });

  if (!props.isSignedIn) {
    return (
      <div
        ref={ref}
        className="flex flex-1 bg-blue-400 flex-row overflow-hidden items-center align-center"
      >
        <EditorTextButton text="Sign up" handleClick={() => {}} />
      </div>
    );
  }

  if (isMobile) {
    return (
      <div
        ref={ref}
        id="site-navigation"
        style={{
          background: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          maxHeight: 0,
        }}
      >
        {navigationLinkItems.map((item, index) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "right",
                minHeight: 50,
                paddingTop: 15,
                position: "relative",
                marginRight: index === navigationLinkItems.length - 1 ? 0 : 20,
              }}
              key={index}
            >
              {item.active ? <MenuDash /> : null}
              <span onClick={item.onClickCallback}>{item.title}</span>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div
      ref={ref}
      id="site-navigation"
      style={{
        background: "#f5f5f5",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        overflow: "hidden",
        justifyContent: "flex-end",
        width: "100%",
        maxHeight: 0,
      }}
      onMouseOver={() => {
        setIsHovering(true);
        if (props.onHoverStart) {
          props.onHoverStart();
        }
      }}
      onMouseLeave={() => {
        setIsHovering(false);
        if (props.onHoverEnd) {
          props.onHoverEnd();
        }
      }}
    >
      {navigationLinkItems.map((item, index) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "right",
              minWidth: 120,
              minHeight: 70,
              paddingTop: 10,
              position: "relative",
              marginRight: index === navigationLinkItems.length - 1 ? 0 : 20,
              paddingBottom: 25,
            }}
            key={index}
          >
            <div style={{ position: "absolute", top: 10 }}>{item.active ? <MenuDash /> : null}</div>

            <span onClick={item.onClickCallback}>{item.title}</span>
          </div>
        );
      })}
    </div>
  );
}

export default forwardRef(SiteNavigation);
