import { useState } from "react";
import SideProjectEditor from "../components/SideProjectEditor";
import type { SideProject, Workplace, ProfileSkillTag } from "../types";
import { motion } from "framer-motion";

function ProjectTutorial() {
  const travelLaserworkplace: Workplace = { id: "1", name: "Travel Laser" };
  const roadFinanceWorkplace: Workplace = { id: "1", name: "RoadFinance" };
  const sideProjectWorkplace: Workplace = { id: "1", name: "Side project" };

  const [currentlySelectedWorkplace, setCurrentlySelectedWorkplace] = useState<
    Workplace | null | undefined
  >(travelLaserworkplace);

  const travelLaserTags: ProfileSkillTag[] = [
    { name: "JavaScript" },
    { name: "PHP" },
    { name: "Laravel" },
    { name: "jQuery" },
  ];

  const roadFinanceTags: ProfileSkillTag[] = [
    { name: "iOS" },
    { name: "Swift" },
    { name: "XCode" },
    { name: "PassKit" },
  ];

  const dogCareAssistantTags: ProfileSkillTag[] = [
    { name: "Flutter" },
    { name: "Go" },
    { name: "PostgreSQL" },
    { name: "iOS" },
    { name: "Android" },
  ];

  const sideProject: SideProject = {
    id: "",
    order: 0,
    title: "Integrate a third-party API into an existing web application",
    pitch:
      "Travel Laser runs a travel planning and booking website. The task was to integrate an API offering real-time weather data, empowering users to make informed trip plans.\n\nThe API is a open REST API that responds with weather data when you pass a region and time in your request. Our travel website is built with PHP and Laravel but it was decided to call the weather API using Javascript to avoid reloading the page itself. jQuery was used to make the request and to manipulate the UI based on the response.\n\n The new feature was overall appricated by our users and searches that used weather data actually converted 10% than without it.",
    githubUrl: "",
    websiteUrl: "",
    workplace: travelLaserworkplace,
    skillTags: travelLaserTags,
    isSideProject: false,
  };
  const secondSideProject: SideProject = {
    id: "",
    order: 1,
    title: "Maintain and improve iOS application with over 250k montly active users",
    pitch:
      "RoadFinance is one of the largest fintech company in Sweden. I was hired as a iOS specialist to manage their iOS app which had over 250k MAU.\n\nFor the two years I worked on this assignemnt I developed multiple new features and always made sure not break anything since the app had a high impact on the users financial decisions and actions. One of the major new features was integrating Apple Pay card provisiong directly into the app. App was built in Swift.",
    githubUrl: "",
    websiteUrl: "",
    workplace: roadFinanceWorkplace,
    skillTags: roadFinanceTags,
    isSideProject: false,
  };

  const thirdSideProject: SideProject = {
    id: "",
    order: 1,
    title: "Dog care assistant",
    pitch:
      "My family owns two highly active dogs, and managing their care has been quite a task due to their specific needs. With multiple family members sharing responsibilities, coordinating walks, meals, and medication schedules became a challenge.\n\nTo streamline our efforts, I created a mobile app that consolidates each dog's daily routines. This app synchronizes schedules across devices, and if a task remains incomplete within its designated timeframe, a push notification is sent to the current caregiver (as set in the app). The backend, developed in Go, is supported by a simple PostgreSQL database and a daily cron job for data refresh. The app itself was built using Flutter.\n\nThis solution has had a significant positive impact on our caregiving dynamics. It has eliminated the need for constant communication regarding medication, walks, and other tasks, making it easier for everyone involved to contribute effectively to the dogs' care.",
    githubUrl: "",
    websiteUrl: "",
    workplace: sideProjectWorkplace,
    skillTags: dogCareAssistantTags,
    isSideProject: false,
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row justify-between px-12 pt-4 min-h-[600px] bg-working-area-bg">
        <div className="flex-1 pr-10">
          <motion.h2
            initial={{ opacity: 1, y: 150 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.7, delay: 1 }}
            className="text-2xl font-bold mt-20"
          >
            A project can be any piece of work that you spent time on
          </motion.h2>
          <motion.h6
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.7, delay: 1 }}
            className="text-lg font-semibold mt-4"
          >
            Like a regular project at your workplace
          </motion.h6>
        </div>

        <motion.div
          className="flex-1"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.7, delay: 1 }}
        >
          <div className="flex flex-1 mt-20">
            <SideProjectEditor
              isEditable={false}
              isDarkModeActive={false}
              key={1}
              projectPhotos={[]}
              isBeingEdited={false}
              isNew={false}
              sideProject={sideProject}
              onSaveSideProject={() => {}}
              onCancelEdit={() => {}}
              onRemove={() => {}}
              onShowDialog={(dialogType: string) => {}}
              currentlySelectedWorkplace={currentlySelectedWorkplace}
              isLastInOrder={false}
            />
          </div>
        </motion.div>
      </div>
      <div className="flex flex-col md:flex-row justify-between px-12 pt-4 min-h-[600px]">
        <div className="flex-1 pr-10">
          <motion.h2
            className="text-2xl font-bold mt-20"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.7, delay: 1 }}
          >
            Or maybe an ongoing work that last throughout your entire assignment
          </motion.h2>
          <motion.h6
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.7, delay: 1 }}
            className="text-lg font-semibold mt-4"
          >
            It does not have to be a specific deliverable
          </motion.h6>
        </div>

        <motion.div
          className="flex-1"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.7, delay: 1 }}
        >
          <div className="flex flex-1 mt-20">
            <SideProjectEditor
              isEditable={false}
              isDarkModeActive={false}
              key={1}
              isBeingEdited={false}
              projectPhotos={[]}
              isNew={false}
              sideProject={secondSideProject}
              onSaveSideProject={() => {}}
              onCancelEdit={() => {}}
              onRemove={() => {}}
              onShowDialog={(dialogType: string) => {}}
              currentlySelectedWorkplace={roadFinanceWorkplace}
              isLastInOrder={false}
            />
          </div>
        </motion.div>
      </div>
      <div className="flex flex-col md:flex-row justify-between px-12 pt-4 min-h-[600px] bg-working-area-bg">
        <div className="flex-1 pr-10">
          <motion.h2
            className="text-2xl font-bold mt-20"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.7, delay: 1 }}
          >
            It can also be a side project or a homework assigment
          </motion.h2>
          <motion.h6
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.7, delay: 1 }}
            className="text-lg font-semibold mt-4"
          ></motion.h6>
        </div>

        <motion.div
          className="flex-1"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.7, delay: 1 }}
        >
          <div className="flex flex-1 mt-20">
            <SideProjectEditor
              isEditable={false}
              isDarkModeActive={false}
              key={1}
              isBeingEdited={false}
              isNew={false}
              projectPhotos={[]}
              sideProject={thirdSideProject}
              onSaveSideProject={() => {}}
              onCancelEdit={() => {}}
              onRemove={() => {}}
              onShowDialog={(dialogType: string) => {}}
              currentlySelectedWorkplace={sideProjectWorkplace}
              isLastInOrder={false}
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default ProjectTutorial;
