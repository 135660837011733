import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { Briefcase, Home, Menu, User } from "react-feather";
import headerLogo from "../assets/op_header_black.png";
import type { PageTab } from "../pages/PageContainer";
import type { Organization } from "../types";
import Button, { ButtonStyleType } from "./atoms/Button";
import MenuTab from "./MenuTab";
import ProfileMenuTab from "./ProfileMenuTab";
import ProfileTab from "./ProfileTab";

interface Props {
  organisation?: Organization;
  onTabSelect: (tabId: string) => void;
  onTabCloseClick: (tabId: string) => void;
  activeTabs: PageTab[];
}

const MenuHeader = (props: Props) => {
  const { loginWithRedirect, getAccessTokenSilently, logout, isAuthenticated } = useAuth0();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  return (
    <div
      className={
        "flex flex-1 justify-between items-center bg-red-200 md:bg-transparent px-[20px] pt-[20px] md:px-[60px] md:pt-[60px] " +
        (isMenuOpen ? "flex-col md:flex-row" : "flex-row")
      }
    >
      <div
        className={
          "flex flex-1 flex-row items-center " + (isMenuOpen ? "flex-col md:flex-row" : "flex-row")
        }
      >
        <div className={isMenuOpen ? "flex-col" : "hidden md:flex flex-row"}>
          {isAuthenticated && props.organisation ? (
            <div className="max-h-[70px] w-[70px]">
              <ProfileTab
                photoAssetId={props.organisation.logoId}
                onClick={() => props.onTabSelect("org")}
                inEditor={false}
              />
            </div>
          ) : (
            <img
              alt=""
              src={headerLogo}
              id="page-header-logo"
              onClick={() => {
                //navigateToStartPage();
              }}
            />
          )}
        </div>

        {isAuthenticated ? (
          <div
            className={
              "flex-1 ml-0 md:ml-[100px] flex items-start " +
              (isMenuOpen ? "flex-col md:flex-row mt-2 md:mt-0" : "flex-row")
            }
          >
            <MenuTab
              onClick={() => props.onTabSelect("members")}
              childTabs={props.activeTabs.filter((t) => t.id.indexOf("member-") > -1)}
              onCloseTabClick={(id: string) => props.onTabCloseClick(id)}
              onTabSelect={(tabId: string) => {
                props.onTabSelect(tabId);
              }}
            >
              <User />
            </MenuTab>
            <MenuTab
              onClick={() => props.onTabSelect("clients")}
              childTabs={props.activeTabs.filter((t) => t.id.indexOf("client-") > -1)}
              onCloseTabClick={(id: string) => props.onTabCloseClick(id)}
              onTabSelect={(tabId: string) => {
                props.onTabSelect(tabId);
              }}
            >
              <Briefcase />
            </MenuTab>
            <div className={isMenuOpen ? "mt-2 md:mt-0" : "ml-3"} />
          </div>
        ) : null}
      </div>

      {isAuthenticated ? (
        <div className={isMenuOpen ? "flex mt-2 md:mt-0" : "hidden md:flex"}>
          <ProfileMenuTab photoAssetId="" onClick={() => props.onTabSelect("profile")} />
        </div>
      ) : null}

      {isAuthenticated ? (
        <div className={"flex md:hidden " + (isMenuOpen ? "mt-2 md:mt-0" : "mt-0")}>
          <Menu onClick={() => setIsMenuOpen(!isMenuOpen)} />
        </div>
      ) : (
        <Button
          text="Sign in"
          onButtonClick={() => {
            loginWithRedirect();
          }}
          style={ButtonStyleType.primary}
        />
      )}
    </div>
  );
};

export default MenuHeader;
