import type React from "react";
import { useState } from "react";
import { submitFeedback } from "../requests";
import { useMediaQuery } from "react-responsive";
import Button from "./atoms/Button";
interface Props {
  title: string;
  info: string;
  secondInfo?: string;
  titleStyle?: string;
  children?: React.ReactNode;
}

function LandingPageSection(props: Props) {
  return (
    <div className="mb-24">
      <h3
        className={`${
          props.titleStyle != null ? props.titleStyle : " text-op-blue"
        } font-bold text-4xl`}
      >
        {props.title}
      </h3>
      <div className="pl-10">
        <p className="text-4xl font-regular mt-12">{props.info}</p>
        {props.secondInfo ? (
          <p className="text-4xl font-regular mt-12">{props.secondInfo}</p>
        ) : null}
        <div className="mt-12">{props.children}</div>
      </div>
    </div>
  );
}
export default LandingPageSection;
