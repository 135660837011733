import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DrawerClose } from "../drawer";
import { cn } from "../utils/cn";

type BaseProps = {
  label: string;
  active?: boolean;
  onClick?: () => void;
};

type ImageProps = BaseProps & {
  imageUrl?: string;
  alt: string;
};

type IconProps = BaseProps & {
  icon: IconProp;
};

type Props = ImageProps | IconProps;

const isImageProps = (props: Props): props is ImageProps =>
  (props as ImageProps).imageUrl !== undefined;

const isIconProps = (props: Props): props is IconProps => (props as IconProps).icon !== undefined;

const NavigationDrawerRow = (props: Props) => (
  <DrawerClose asChild>
    <button
      type="button"
      className={cn(
        "p-4 gap-2 flex flex-row items-center w-full rounded-lg transition-colors",
        props.active && "bg-gray-2"
      )}
      onClick={props.onClick}
    >
      {isImageProps(props) ? (
        <img src={props.imageUrl} alt={props.alt} className="w-6 h-6 object-cover rounded-lg" />
      ) : isIconProps(props) ? (
        <div className="w-6 h-6 flex items-center justify-center">
          <FontAwesomeIcon width={20} height={20} icon={props.icon} className="text-black" />
        </div>
      ) : null}
      <p className="lowercase">{props.label}</p>
    </button>
  </DrawerClose>
);

NavigationDrawerRow.displayName = "NavigationDrawerRow";

export { NavigationDrawerRow };
