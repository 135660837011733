import { queryOptions, useQuery } from "@tanstack/react-query";
import type { GetV1OrganizationsByOrganizationIdResponse } from "../../client";
import { getV1OrganizationsByOrganizationIdOptions } from "../../client/@tanstack/react-query.gen";
import { jsonParse } from "../utils/parse";

export const getOrganizationOptions = (id: string) =>
  queryOptions({
    ...getV1OrganizationsByOrganizationIdOptions({
      path: { organizationID: id },
    }),
    staleTime: 1000 * 60 * 5, // 5 minutes
    select: (data) => {
      // Currently, getV1OrganizationsByOrganizationIdOptions returns as string
      // so we need to parse it
      if (data) return jsonParse<GetV1OrganizationsByOrganizationIdResponse>(data);
      return data;
    },
  });

export const useGetOrganization = (id: string) => {
  return useQuery(getOrganizationOptions(id));
};
