import { useState, useEffect } from "react";

export const useCurrentOrgId = (): string | null => {
  const [orgId, setOrgId] = useState<string | null>(null);

  useEffect(() => {
    const storedOrgId = localStorage.getItem("current-org-id");
    if (storedOrgId) {
      setOrgId(storedOrgId);
    }
  }, []);

  return orgId;
};

export const useCurrentProfileId = (): string | null => {
  const [currentProfileId, setCurrentProfileId] = useState<string | null>(null);

  useEffect(() => {
    const storedProfileId = localStorage.getItem("current-profile-id");
    if (storedProfileId) {
      setCurrentProfileId(storedProfileId);
    }
  }, []);

  return currentProfileId;
};
