import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { darkModeBlack } from "../../colors";
import Button, { ButtonStyleType } from "../atoms/Button";
import "../components.css";
import EditorTextField from "../EditorTextField";
import MonthYearSelect from "../MonthYearSelect";
import { iconForName, AvailableSkillTags } from "../TechIcons";
import { ProfileSkillTag, Workplace } from "../../types";
import { getWorkplaces, createWorkplace } from "../../requests";
import EditorSkillTagButton from "../atoms/EditorSkillTagButton";
interface Props {
  open: boolean;
  darkModeActive: boolean;
  onBackdropClick: () => void;
  onSave: (skillTags: string[]) => void;
  selectedSkillTags: string[];
  onCancel: () => void;
  onClose: () => void;
}

const TagsInputDialog = (props: Props) => {
  const [input, setInput] = useState<string>("");
  const [currentValue, setCurrentValue] = useState<string>("");
  const [currentMatchingSkillOptions, setCurrentMatchingSkillOptions] = useState<string[]>([]);
  const [selectedSkillTags, setSelectedSkillTags] = useState<string[]>([]);
  useEffect(() => {
    setSelectedSkillTags(props.selectedSkillTags);
  }, []);

  return (
    <Dialog open={props.open} onBackdropClick={props.onClose} fullWidth>
      <DialogContent
        style={{
          backgroundColor: props.darkModeActive ? darkModeBlack : "#fff",
          color: props.darkModeActive ? "#fff" : darkModeBlack,
        }}
      >
        <div style={{ overflowX: "hidden" }} className="tags-input-dialog-content">
          <div style={{ display: "flex", flexDirection: "column", marginTop: 20 }}>
            <div>
              {selectedSkillTags.length > 0 ? (
                <h3 className="texl-lg font-semibold">
                  Used in this project (Click a tag to remove it)
                </h3>
              ) : (
                <label />
              )}
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {selectedSkillTags.map((item, index) => {
                return (
                  <div key={index} style={{ marginRight: 15 }}>
                    <EditorSkillTagButton
                      isDarkModeActive={props.darkModeActive}
                      text={item}
                      handleClick={() => {
                        const tags = [...selectedSkillTags];
                        tags.splice(index, 1);
                        setSelectedSkillTags(tags);
                        props.onSave(tags);
                        setSelectedSkillTags(tags);
                      }}
                      keepLeftPadding
                    />
                  </div>
                );
              })}
            </div>
          </div>

          <h3 className="texl-lg font-semibold mt-4">Add a new skill </h3>
          <EditorTextField
            isDarkModeActive={props.darkModeActive}
            autofocus={true}
            styles={{ display: "inline", width: "100%" }}
            placeholder="Name of skill / tool."
            onLeaveField={(input: string) => setInput(input)}
            value={currentValue}
            inputStyles={{ fontSize: 18, fontWeight: 600 }}
            onChange={(input: string) => {
              const availabeSkills = AvailableSkillTags.filter(
                (s) => !props.selectedSkillTags?.includes(s)
              );

              var matches = availabeSkills.filter((s) =>
                s.toLowerCase().includes(input.toLowerCase())
              );

              if (input !== "") {
                matches.splice(0, 0, "Add new skill");
              }

              if (input === "") {
                setCurrentMatchingSkillOptions([]);
              } else {
                setCurrentMatchingSkillOptions(matches);
              }

              setCurrentValue(input);
            }}
          />

          <div style={{ overflowX: "scroll", marginTop: 20 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {currentMatchingSkillOptions.map((item, index) => {
                return (
                  <div style={{ marginRight: 15 }} key={index}>
                    <EditorSkillTagButton
                      isDarkModeActive={props.darkModeActive}
                      text={item}
                      handleClick={() => {
                        const newItemString = item === "Add new skill" ? input : item;
                        setSelectedSkillTags([...selectedSkillTags, newItemString]);
                        props.onSave([...selectedSkillTags, newItemString]);
                        setCurrentValue("");
                        setCurrentMatchingSkillOptions([]);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            <Button text="Close" onButtonClick={props.onClose} style={ButtonStyleType.primary} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TagsInputDialog;
