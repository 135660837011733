import type { ComponentProps } from "react";
import { CreatedAt } from "./CreatedAt";
import { EventData } from "./EventData";

interface Props extends ComponentProps<typeof EventData> {
  createdAt: string;
}

export const EventTemplate = ({ createdAt, ...props }: Props) => (
  <li className="flex flex-col md:flex-row md:justify-between md:items-center gap-2 md:gap-4">
    <EventData {...props} />
    <div className="flex flex-row items-center justify-end md:justify-normal shrink-0 grow-0 gap-2">
      <CreatedAt createdAt={createdAt} />
    </div>
  </li>
);

EventTemplate.displayName = "EventTemplate";
