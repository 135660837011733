import { PopoverMenu } from "../../../../../components";
import { cn } from "../../../../../components/utils/cn";
import { TemperatureIcon } from "../icon";

interface Props {
  priority: number;
  onPriorityChange: (priority: number) => void;
}

const HOT = 2;
const NEUTRAL = 1;
const COLD = 0;

export const TemperatureMenu = ({ priority, onPriorityChange }: Props) => {
  return (
    <PopoverMenu>
      <TemperatureMenuRow
        priority={HOT}
        selected={priority === HOT}
        onClick={() => onPriorityChange(HOT)}
      />
      <TemperatureMenuRow
        priority={NEUTRAL}
        selected={priority === NEUTRAL}
        onClick={() => onPriorityChange(NEUTRAL)}
      />
      <TemperatureMenuRow
        priority={COLD}
        selected={priority === COLD}
        onClick={() => onPriorityChange(COLD)}
      />
    </PopoverMenu>
  );
};

TemperatureMenu.displayName = "TemperatureMenu";

interface TemperatureMenuRowProps {
  selected: boolean;
  onClick: () => void;
  priority: number;
}

const TemperatureMenuRow = ({ priority, selected, onClick }: TemperatureMenuRowProps) => (
  <PopoverMenu.Item
    onClick={onClick}
    className={cn(
      "border border-transparent",
      selected && "bg-gray-2 border-gray-3 hover:bg-gray-2"
    )}
  >
    <TemperatureIcon priority={priority} />
    <span className="text-sm text-black">{getTemperatureText(priority)}</span>
  </PopoverMenu.Item>
);

const getTemperatureText = (priority: number) => {
  switch (priority) {
    case 2:
      return "Hot lead";
    case 1:
      return "Medium hot lead";
    case 0:
      return "Cold lead";
  }
};
TemperatureMenuRow.displayName = "TemperatureMenuRow";
