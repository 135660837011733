import { useNavigate } from "react-router-dom";
import type { ProfileComponentProps } from "../types";
import { darkModeBlack } from "../colors";
import TextField from "./TextField";
import { useState } from "react";
import "./components.css";

interface SignUpBottomBannerProps extends ProfileComponentProps {
  onSubmitClick: (email: string) => void;
  onDismissClick: () => void;
  signUpSuccessfull: boolean;
}

export default function SignUpBottomBanner({
  isDarkModeActive,
  onSubmitClick,
  onDismissClick,
  signUpSuccessfull,
}: SignUpBottomBannerProps) {
  const [signupEmail, setSignupEmail] = useState<string>("");

  const navigate = useNavigate();
  return (
    <div
      id="sign-up-bottom-border"
      style={{
        backgroundColor: isDarkModeActive ? "#fff" : darkModeBlack,
        color: isDarkModeActive ? darkModeBlack : "#fff",
      }}
    >
      {signUpSuccessfull ? (
        <div id="sign-up-bottom-border-content">
          <p>
            Awesome, you're all set! You can manage all your Profiles and settings from your
            Profiles page.
          </p>
          <button
            className={isDarkModeActive ? "primary-button" : "secondary-button"}
            onClick={() => onDismissClick()}
            style={{
              marginLeft: "25px",
            }}
          >
            Close
          </button>
        </div>
      ) : (
        <div id="sign-up-bottom-border-content">
          <p>
            You're still in demo mode, make sure so you can access your profile later by creating an
            account
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              marginLeft: "50px",
            }}
          >
            <TextField
              isDarkModeActive={!isDarkModeActive}
              placeholder="Email"
              onLeaveField={(input) => setSignupEmail(input)}
              inputStyles={{ fontSize: "14px", padding: 25 }}
              value={signupEmail}
              useDynamicWidth
              defaultDynamicWeight={30}
            />
            <button
              className={isDarkModeActive ? "primary-button" : "secondary-button"}
              onClick={() => onSubmitClick(signupEmail)}
              style={{
                marginLeft: "25px",
              }}
            >
              Sign up
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
