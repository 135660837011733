import { useEffect } from "react";
import { proxy, subscribe } from "valtio";

type TabsState = {
  members: string[];
  clients: string[];
};

const storageKey = "tabs";

export const tabsState = proxy<TabsState>({
  members: [],
  clients: [],
});

export const initTabsState = () => {
  const value = sessionStorage.getItem(storageKey);
  if (value) Object.assign(tabsState, JSON.parse(value));
};

export const useInitTabsState = () => useEffect(initTabsState, []);

export const addMemberTab = (tab: string) => {
  // Add the tab to the list if it doesn't already exist
  if (!tabsState.members.includes(tab)) tabsState.members.push(tab);
};

export const removeMemberTab = (tab: string) => {
  tabsState.members.splice(tabsState.members.indexOf(tab), 1);
};

export const addClientTab = (tab: string) => {
  // Add the tab to the list if it doesn't already exist
  if (!tabsState.clients.includes(tab)) tabsState.clients.push(tab);
};

export const removeClientTab = (tab: string) => {
  tabsState.clients.splice(tabsState.clients.indexOf(tab), 1);
};

export const clearTabs = () => {
  tabsState.members = [];
  tabsState.clients = [];
};

subscribe(tabsState, () => {
  sessionStorage.setItem(storageKey, JSON.stringify(tabsState));
});
