import type { PropsWithChildren } from "react";
import { cn } from "../../../../../components/utils/cn";

type Props = PropsWithChildren<{
  className?: string;
}>;

export const PanelContainer = ({ children, className }: Props) => (
  <div className={cn("grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-8", className)}>{children}</div>
);

PanelContainer.displayName = "PanelContainer";
