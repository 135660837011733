import type { PropsWithChildren } from "react";
import { cn } from "../utils/cn";

type Props = PropsWithChildren<{
  className?: string;
}>;

export const SectionTitle = ({ children, className }: Props) => (
  <p className={cn("text-xs font-black text-black", className)}>{children}</p>
);

SectionTitle.displayName = "SectionTitle";
