import { type ComponentProps, useCallback } from "react";
import { ImageButton } from "../buttons";
import { useMatch, useNavigate } from "react-router-dom";

type Props = Omit<ComponentProps<typeof ImageButton>, "indicatorProps"> & {
  href: string;
  onClose?: () => void;
};

const IndicatorImageButtonLink = ({ href, onClose, ...props }: Props) => {
  const match = useMatch(href);
  const navigate = useNavigate();

  const active = Boolean(match);

  const handleClose = useCallback(() => {
    if (active) {
      // If the indicator is active, navigate back to the parent page
      const parent = href.split("/").slice(0, -1).join("/");
      navigate(parent);
    }

    onClose?.();
  }, [active, onClose, href, navigate]);

  return (
    <li>
      <ImageButton
        onClick={() => navigate(href)}
        {...props}
        active={active}
        indicatorProps={{
          variant: "close",
          onClick: handleClose,
        }}
      />
    </li>
  );
};

IndicatorImageButtonLink.displayName = "IndicatorImageButtonLink";

export { IndicatorImageButtonLink };
