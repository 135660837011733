import { ArrowLeft } from "react-feather";
import { useNavigate } from "react-router-dom";
import Button, { ButtonStyleType } from "./atoms/Button";

interface NavigationHeaderProps {
  backButtonText: string;
  backButtonPath: string;
  onBackButtonClick?: () => void;
  children?: React.ReactNode;
}

const NavigationHeader = (props: NavigationHeaderProps) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        margin: "0px auto",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
      className="pl-8 pr-8 py-2 border-b-2 border-op-dark min-h-[70px]"
    >
      <div className="flex flex-row flex-1">
        <div
          className="clickable mr-1"
          onClick={() => {
            if (props.onBackButtonClick) {
              props.onBackButtonClick();
            } else {
              navigate(props.backButtonPath);
            }
          }}
        >
          <ArrowLeft size={24} />
        </div>

        <Button
          text={props.backButtonText}
          onButtonClick={() => {
            if (props.onBackButtonClick) {
              props.onBackButtonClick();
            } else {
              navigate(props.backButtonPath);
            }
          }}
          style={ButtonStyleType.link}
        />
      </div>
      <div className="flex-1 flex flex-row justify-end">{props.children}</div>
    </div>
  );
};

export default NavigationHeader;
