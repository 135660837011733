import type React from "react";
import withCustomStyling from "./HOC/withCustomStyling";

interface IconTextAreaProps {
  icon: React.ReactNode;
  placeholder?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onEnterPress?: () => void;
}

const IconTextArea: React.FC<IconTextAreaProps> = ({
  icon,
  placeholder,
  value,
  onChange,
  onBlur,
  onEnterPress,
}) => {
  return (
    <div className="flex items-start border rounded-lg p-2">
      <div className="mr-2">{icon}</div>
      <textarea
        className="flex-1 outline-none bg-transparent text-md resize-none"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
          if (onEnterPress && event.key === "Enter") {
            event.preventDefault(); // Prevent the default action, which is to create a new line
            onEnterPress();
          }
        }}
        onBlur={onBlur}
      />
    </div>
  );
};

export default withCustomStyling(IconTextArea);
