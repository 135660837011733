import React from "react";
import type { PageTab } from "../pages/PageContainer";
import ProfileTab from "./ProfileTab";

interface Props {
  onClick: () => void;
  children?: React.ReactElement;
  childTabs: PageTab[];
  onTabSelect: (id: string) => void;
  onCloseTabClick: (id: string) => void;
}

export default function MenuTab(props: Props) {
  return (
    <div
      className="h-[100px] min-w-[100px] bg-op-element-gray border-op-element-border-gray border-2 rounded-xl flex items-center justify-center px-2 "
      onClick={() => {
        props.onClick();
      }}
    >
      <div className="hover:bg-gray-200 p-4 rounded-xl">{React.cloneElement(props.children!)}</div>
      {props.childTabs.length === 0 ? null : (
        <div className="flex flex-1 flex-row items-center">
          {props.childTabs.map((ct, i) => {
            if (ct.userTabData) {
              return (
                <ProfileTab
                  photoAssetId={ct.userTabData.userProfile?.profilePhotoId}
                  onClick={() => {
                    props.onTabSelect(ct.id);
                  }}
                  size={60}
                  removeable
                  onRemoveClick={() => props.onCloseTabClick(ct.id)}
                  isSelected={ct.selected}
                  inEditor={
                    ct.userTabData!.isEditing || ct.userTabData!.isEditingProfileLink !== ""
                  }
                />
              );
            } else if (ct.clientTabData) {
              return (
                <ProfileTab
                  photoAssetId={ct.clientTabData.logoId}
                  onClick={() => {
                    props.onTabSelect(ct.id);
                  }}
                  size={60}
                  removeable
                  onRemoveClick={() => props.onCloseTabClick(ct.id)}
                  isSelected={ct.selected}
                  inEditor={false}
                />
              );
            }
          })}
        </div>
      )}
    </div>
  );
}
