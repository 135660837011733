import type { ChangeEvent } from "react";

interface Props {
  title: string;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function LinkSetingToggle({ title, checked, onChange }: Props) {
  return (
    <div className="flex items-center mt-3">
      <label className="switch mr-1">
        <input type="checkbox" checked={checked} onChange={(e) => onChange(e)} />
        <span className="slider round"></span>
      </label>
      <label>{title}</label>
    </div>
  );
}
export default LinkSetingToggle;
