import { AnimatePresence, motion } from "framer-motion";
import { cn } from "../../../../components/utils/cn";

interface Props {
  numberOfUnreadEvents: number;
  className?: string;
}

export const NumberOfUnreadEvents = ({ numberOfUnreadEvents, className }: Props) => (
  <AnimatePresence>
    {numberOfUnreadEvents > 0 && (
      <motion.div
        className={cn(
          "absolute top-0 right-0 bg-red-3 text-white text-sm rounded-full w-5 h-5 flex items-center justify-center font-black",
          className
        )}
        initial={{
          translateX: -8,
          translateY: 8,
          scale: 0.2,
          opacity: 0,
        }}
        animate={{
          translateX: 0,
          translateY: 0,
          scale: 1,
          opacity: 1,
        }}
        exit={{
          translateX: -8,
          translateY: 8,
          scale: 0.2,
          opacity: 0,
        }}
        transition={{
          duration: 0.2,
        }}
      >
        {numberOfUnreadEvents}
      </motion.div>
    )}
  </AnimatePresence>
);
