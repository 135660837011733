import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import DefaultIcon from "../assets/op_o.png";
import Button, { ButtonStyleType } from "./atoms/Button";

interface Props {
  onClick: () => void;
  photoAssetId?: string;
  size?: number;
}

export default function ProfileMenuTab(props: Props) {
  const { logout } = useAuth0();

  const [displayDropdown, setDisplayDropdown] = useState<boolean>(false);

  return (
    <div
      className={
        "h-[80px] w-[80px] bg-op-transparent rounded-xl flex items-center justify-center  p-4 hover:p-0 hover:bg-gray-400 relative " +
        (props.size !== null ? `w-[${props.size}px] h-[${props.size}px]` : "w-[80px] h-[80px]")
      }
      onClick={() => setDisplayDropdown(!displayDropdown)}
    >
      <div className="max-h-[60px] max-w-[60px] rounded-xl overflow-hidden">
        <img
          className="object-cover"
          src={
            props.photoAssetId
              ? "https://pub-4b15203a4a0f4b26ab71deeffce0164a.r2.dev/" + props.photoAssetId
              : DefaultIcon
          }
          alt=""
        />
      </div>
      {displayDropdown ? (
        <div className="w-[200px] h-[200px]  absolute top-[65px] right-0 ">
          <Button
            text="Sign out"
            onButtonClick={() => {
              logout();
            }}
            style={ButtonStyleType.link}
          />
        </div>
      ) : null}
    </div>
  );
}
