import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import {
  createContract,
  getClients,
  getLeads,
  getOrganization,
  getProfileLinks,
} from "../requests";
import {
  type Client,
  ClientWithLeads,
  type Contract,
  type Lead,
  type OrganizationOverview,
  type ProfileLink,
  type UserProfile,
} from "../types";
import animationData from "../assets/spinner-animation.json";
import MenuHeader from "../components/MenuHeader";
import EditorTitle from "../components/EditorTitle";
import OrgMemberRow from "../components/OrgMemberRow";

import { User } from "react-feather";
import Card from "../components/Card";
import Editor from "../components/Editor";
import Pill from "../components/atoms/Pill";

import LeadEditor from "../components/LeadEditor";
import LinkEditor from "../components/LinkEditor";
import EditorPreview from "../components/EditorPreview";
import { Dialog, DialogContent, DialogContentText } from "@material-ui/core";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Button, { ButtonSize, ButtonStyleType } from "../components/atoms/Button";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import AvatarImage from "../components/AvatarImage";
import { useCurrentOrgId } from "../hooks/hooks";
import { useParams } from "react-router-dom";

dayjs.extend(utc);

interface RouteProps {
  organizationId?: string;
}

interface UserTabData {
  userProfile?: UserProfile;
  profileLinks: ProfileLink[] | null;
  isEditing: boolean;
  isEditingProfileLink: string;
  backToOrgOnClose: boolean;
}

export interface PageTab {
  id: string; //org, members, profile
  userTabData?: UserTabData;
  clientTabData?: Client;
  selected: boolean;
}

interface RouteParams {
  [key: string]: string | undefined;
  organizationId?: string;
}

const PageContainer: React.FC = () => {
  const { organizationId } = useParams<RouteParams>();

  const [organizationOverview, setOrganizationOverview] = useState<OrganizationOverview | null>(
    null
  );
  const [contractStartDate, setContractStartDate] = useState<string>("");
  const [contractEndDate, setContractEndDate] = useState<string | null>(null);
  const [showCreateContractDialog, setShowCreateContractDialog] = useState<boolean>(false);

  const [orgLeads, setOrgLeads] = useState<Lead[]>([]);
  const [orgClients, setOrgClients] = useState<Client[]>([]);

  const [selectedLeadId, setSelectedLeadId] = useState<string>("");

  const [activeTabs, setActiveTabs] = useState<PageTab[]>([
    { id: "org", selected: true },
    { id: "clients", selected: false },
    { id: "members", selected: false },
  ]);

  const currentOrgId = useCurrentOrgId();

  useEffect(() => {
    console.log(selectedLeadId);
  }, [selectedLeadId]);

  useEffect(() => {
    console.log(selectedLeadId);
  }, [currentOrgId]);

  useEffect(() => {
    if (organizationId) {
      getOrganization(organizationId)
        .then((organizationOverview: OrganizationOverview) => {
          setOrganizationOverview(organizationOverview);

          getLeads(organizationId).then((leads: Lead[]) => {
            setOrgLeads(attachUserProfilesToComments(leads, organizationOverview.profiles));
          });

          getClients(organizationId).then((clients: Client[]) => {
            setOrgClients(clients ?? []);
          });
        })
        .catch((err) => {});
    }

    if (localStorage.getItem("clear-op-tabs-once") === null) {
      localStorage.removeItem("op-tabs");
      localStorage.setItem("clear-op-tabs-once", "true");
    } else {
      if (localStorage.getItem("op-tabs")) {
        const storedUserProfile = localStorage.getItem("op-tabs");
        if (storedUserProfile) {
          setActiveTabs(JSON.parse(storedUserProfile) as PageTab[]);
        }
      }
    }
  }, [organizationId]);

  function attachUserProfilesToComments(leads: Lead[], profiles: UserProfile[]): Lead[] {
    const profileMap: { [key: string]: UserProfile } = {};

    // Create a map for quick lookup of profiles by profileId
    profiles.forEach((profile) => {
      profileMap[profile.profileId] = profile;
    });

    // Iterate through each lead and its comments
    leads.forEach((lead) => {
      if (lead.comments) {
        lead.comments.forEach((comment) => {
          if (comment.commenterProfileId in profileMap) {
            comment.commenterUserProfile = profileMap[comment.commenterProfileId];
          }
        });
      }
      if (lead.events) {
        lead.events.forEach((event) => {
          if (event.triggeredByProfileId && event.triggeredByProfileId in profileMap) {
            event.triggeredByUserProfile = profileMap[event.triggeredByProfileId];
          }
        });
      }
    });

    return leads;
  }

  if (organizationOverview === null) {
    return (
      <div className="flex flex-1 h-[800px] flex-col justify-center align-center ">
        <div>
          <Lottie style={{ width: 80 }} options={{ animationData: animationData }} />
          <label className="font-bold">Booting up manager mode...</label>
        </div>
      </div>
    );
  }

  const indexForTabId = (tabId: string) => {
    return activeTabs.findIndex((t) => t.id === tabId);
  };

  const getUserProfile = (profileId: string) => {
    return organizationOverview.profiles.filter((up) => up.profileId === profileId)[0];
  };

  const saveTabs = (tabs: PageTab[]) => {
    setActiveTabs(tabs);
    localStorage.setItem("op-tabs", JSON.stringify(tabs));
  };

  const closeTabAndMoveToOrg = (selectedTabId: string) => {
    const tabIndex = indexForTabId(selectedTabId);

    const updatedItems = [...activeTabs.filter((t) => t.id !== selectedTabId)];
    updatedItems
      .filter((t) => t.id === "org")
      .forEach((t) => {
        t.selected = true;
      });

    saveTabs(updatedItems);
  };

  const changeSelectedTab = (selectedTabId: string) => {
    const tabIndex = indexForTabId(selectedTabId);

    const updatedItems = [...activeTabs];
    updatedItems[tabIndex] = {
      ...updatedItems[tabIndex],
      selected: true,
    };
    updatedItems
      .filter((t) => t.id !== selectedTabId)
      .forEach((t) => {
        t.selected = false;
      });

    saveTabs(updatedItems);
  };

  const updateTabData = (selectedTabId: string, links: ProfileLink[]) => {
    const tabIndex = indexForTabId(selectedTabId);

    const updatedItems = [...activeTabs];

    updatedItems[tabIndex] = {
      ...updatedItems[tabIndex],
      userTabData: {
        ...updatedItems[tabIndex].userTabData!,
        profileLinks: links,
      },
    };

    saveTabs(updatedItems);
  };

  const addAndSelectTab = (newTab: PageTab) => {
    if (activeTabs.filter((t) => t.id === newTab.id).length > 0) {
      return;
    }
    const updatedItems = [...activeTabs];
    updatedItems.forEach((t) => {
      t.selected = false;
    });
    updatedItems.push(newTab);
    saveTabs(updatedItems);
  };

  const removeTab = (tabId: string) => {
    let updatedItems = [...activeTabs];
    updatedItems = updatedItems.filter((at) => at.id !== tabId);
    updatedItems[updatedItems.length - 1].selected = true;
    saveTabs(updatedItems);
  };

  const toggleEditorOnActiveTab = (tabId: string) => {
    const tabIndex = indexForTabId(tabId);
    const updatedItems = [...activeTabs];
    updatedItems[tabIndex] = {
      ...updatedItems[tabIndex],
      userTabData: {
        ...updatedItems[tabIndex].userTabData!,
        isEditing: !updatedItems[tabIndex].userTabData!.isEditing,
      },
    };
    saveTabs(updatedItems);
  };

  const toggleLinkEditorOnActiveTab = (tabId: string) => {
    const tabIndex = indexForTabId(tabId);
    const updatedItems = [...activeTabs];
    updatedItems[tabIndex] = {
      ...updatedItems[tabIndex],
      userTabData: {
        ...updatedItems[tabIndex].userTabData!,
        isEditingProfileLink: "",
      },
    };
    saveTabs(updatedItems);
  };

  const addOrUpdateClientWithLead = (lead: Lead, client?: Client | null) => {
    if (client) {
      setOrgClients((prevOrgClients) => {
        // Find the index of the client

        const clientIndex = prevOrgClients.findIndex((c) => c.id === client.id);

        if (clientIndex === -1) {
          return [...prevOrgClients, client];
        }

        return prevOrgClients;
      });
    }

    setOrgLeads((prevOrgLeads) => {
      return [...prevOrgLeads, lead];
    });
  };

  const removeLead = (leadToRemove: Lead) => {
    setOrgLeads((prevOrgLeads) => {
      return [...prevOrgLeads.filter((lead) => lead.id !== leadToRemove.id)];
    });
  };

  const isSelectedTabInEditorMode = () => {
    const activeTab = activeTabs.filter((t) => t.selected)[0];
    if (activeTab.userTabData) {
      return activeTab.userTabData.isEditing;
    }
    return false;
  };

  const isSelectedTabInEditingLinkMode = () => {
    const activeTab = activeTabs.filter((t) => t.selected)[0];
    if (activeTab.userTabData) {
      return activeTab.userTabData.isEditingProfileLink !== "";
    }
    return false;
  };

  const handleAddLead = (lead: Lead, client?: Client | null) => {
    addOrUpdateClientWithLead(lead, client);
    setSelectedLeadId(lead.id); // This will be called right after the state update
  };

  const getClientForLead = (clientId: string) => {
    if (orgClients && orgClients.filter((c) => c.id === clientId).length > 0) {
      var c = orgClients.filter((c) => c.id === clientId)[0];
      return c;
    }
    return undefined;
  };

  const renderActiveTab = () => {
    if (activeTabs.filter((t) => t.selected).length === 0) {
      return <div></div>;
    }
    const activeTabId = activeTabs.filter((t) => t.selected)[0].id;
    if (activeTabId === "org") {
      return (
        <Card>
          {orgLeads
            .filter((lead) => !lead.contractId)
            .map((lead, i) => {
              return (
                <LeadEditor
                  key={i}
                  lead={lead}
                  client={getClientForLead(lead.clientId)}
                  onSelect={(leadId: string) => {
                    setSelectedLeadId(lead.id);
                  }}
                  onClose={() => {
                    setSelectedLeadId("");
                  }}
                  selected={selectedLeadId === lead.id}
                  orgUserProfiles={organizationOverview.profiles}
                  onEditProfileLinkClick={(tabId: string) => {
                    //Store the current tabid
                    localStorage.setItem("last-lead-tab-id", tabId);

                    addAndSelectTab({
                      id: "member-" + lead.profileId,
                      userTabData: {
                        userProfile: getUserProfile(lead.profileId!),
                        profileLinks: null,
                        isEditing: false,
                        isEditingProfileLink: lead.profileLinkId!,
                        backToOrgOnClose: true,
                      },
                      selected: true,
                    });
                  }}
                  onViewProfileLinkClick={(tabId: string, profileLink: string) => {
                    localStorage.setItem("last-lead-tab-id", tabId);
                    window.open(
                      window.location.origin + "/p/" + profileLink,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                  lastLeadTabId={
                    selectedLeadId === lead.id
                      ? localStorage.getItem("last-lead-tab-id") ?? ""
                      : undefined
                  }
                  clearLastLeadTab={() => {
                    localStorage.removeItem("last-lead-tab-id");
                  }}
                  onNewLeadCreated={() => {}}
                  onLeadArchived={(lead: Lead) => {
                    removeLead(lead);
                  }}
                  orgClients={orgClients}
                  onShowCreateContractDialog={(leadId: string) => {
                    setSelectedLeadId(leadId);
                    setShowCreateContractDialog(true);
                  }}
                />
              );
            })}
          <EditorTitle title="Create new lead" />
          <LeadEditor
            onSelect={() => setSelectedLeadId("new")}
            onClose={() => {}}
            selected={selectedLeadId === "new"}
            orgUserProfiles={organizationOverview.profiles}
            onEditProfileLinkClick={() => {}}
            onViewProfileLinkClick={() => {}}
            onNewLeadCreated={(lead: Lead, client?: Client | null) => {
              handleAddLead(lead, client);
            }}
            orgClients={orgClients}
            isNew
          />
        </Card>
      );
    }
    if (activeTabId === "members") {
      return (
        <div className="flex flex-1 border-1 border-red-400 flex-col">
          {organizationOverview.profiles.map((p, index) => {
            if (p.profileId !== organizationOverview.currentOrgProfile.profileId) {
              return (
                <OrgMemberRow
                  userProfile={p}
                  key={index}
                  handleClick={() => {
                    //If the member alreay have an active tab up, switch to it

                    addAndSelectTab({
                      id: "member-" + p.profileId,
                      userTabData: {
                        userProfile: p,
                        profileLinks: null,
                        isEditing: false,
                        isEditingProfileLink: "",
                        backToOrgOnClose: false,
                      },
                      selected: true,
                    });
                  }}
                />
              );
            }
          })}
        </div>
      );
    }

    if (activeTabId === "clients") {
      return (
        <Card>
          {orgClients.map((client, index) => {
            return (
              <div
                className={`flex items-center justify-start p-4 rounded-lg cursor-pointer transition-shadow h-full text-gray-800 hover:bg-gray-200 cursor-pointer`}
                onClick={() => {
                  addAndSelectTab({
                    id: "client-" + client.id,
                    clientTabData: client,
                    selected: true,
                  });
                }}
              >
                <div className="flex flex-row justify-center items-center">
                  <AvatarImage photoId={client.logoId} />
                  <h6 className="text-md ml-4 font-medium flex-1">{client.name}</h6>
                </div>
              </div>
            );
          })}
        </Card>
      );
    }
    if (activeTabId.indexOf("client-") > -1) {
      const tab = activeTabs.filter((t) => t.id === activeTabId)[0];
      const tabClientId = tab.clientTabData!.id;
      return (
        <div className="w-full">
          <EditorTitle title="Contracts" />
          <Card>
            {orgLeads
              .filter(
                (lead) => lead.clientId === tabClientId && lead.contractId && lead.contractId !== ""
              )
              .map((lead, i) => {
                return (
                  <LeadEditor
                    key={i}
                    lead={lead}
                    client={getClientForLead(lead.clientId)}
                    onSelect={(leadId: string) => {
                      setSelectedLeadId(lead.id);
                    }}
                    onClose={() => {
                      setSelectedLeadId("");
                    }}
                    selected={selectedLeadId === lead.id}
                    orgUserProfiles={organizationOverview.profiles}
                    onEditProfileLinkClick={(tabId: string) => {
                      //Store the current tabid
                      localStorage.setItem("last-lead-tab-id", tabId);

                      addAndSelectTab({
                        id: "member-" + lead.profileId,
                        userTabData: {
                          userProfile: getUserProfile(lead.profileId!),
                          profileLinks: null,
                          isEditing: false,
                          isEditingProfileLink: lead.profileLinkId!,
                          backToOrgOnClose: true,
                        },
                        selected: true,
                      });
                    }}
                    onViewProfileLinkClick={(tabId: string, profileLink: string) => {
                      localStorage.setItem("last-lead-tab-id", tabId);
                      window.open(
                        window.location.origin + "/p/" + profileLink,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }}
                    lastLeadTabId={
                      selectedLeadId === lead.id
                        ? localStorage.getItem("last-lead-tab-id") ?? ""
                        : undefined
                    }
                    clearLastLeadTab={() => {
                      localStorage.removeItem("last-lead-tab-id");
                    }}
                    onNewLeadCreated={() => {}}
                    onLeadArchived={(lead: Lead) => {
                      removeLead(lead);
                    }}
                    orgClients={orgClients}
                    onShowCreateContractDialog={(leadId: string) => {
                      setSelectedLeadId(leadId);
                      setShowCreateContractDialog(true);
                    }}
                  />
                );
              })}
          </Card>
          <EditorTitle title="Leads" />
          <Card>
            {orgLeads
              .filter((lead) => lead.clientId === tabClientId && !lead.contractId)
              .map((lead, i) => {
                return (
                  <LeadEditor
                    key={i}
                    lead={lead}
                    client={getClientForLead(lead.clientId)}
                    onSelect={(leadId: string) => {
                      setSelectedLeadId(lead.id);
                    }}
                    onClose={() => {
                      setSelectedLeadId("");
                    }}
                    selected={selectedLeadId === lead.id}
                    orgUserProfiles={organizationOverview.profiles}
                    onEditProfileLinkClick={(tabId: string) => {
                      //Store the current tabid
                      localStorage.setItem("last-lead-tab-id", tabId);

                      addAndSelectTab({
                        id: "member-" + lead.profileId,
                        userTabData: {
                          userProfile: getUserProfile(lead.profileId!),
                          profileLinks: null,
                          isEditing: false,
                          isEditingProfileLink: lead.profileLinkId!,
                          backToOrgOnClose: true,
                        },
                        selected: true,
                      });
                    }}
                    onViewProfileLinkClick={(tabId: string, profileLink: string) => {
                      localStorage.setItem("last-lead-tab-id", tabId);
                      window.open(
                        window.location.origin + "/p/" + profileLink,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }}
                    lastLeadTabId={
                      selectedLeadId === lead.id
                        ? localStorage.getItem("last-lead-tab-id") ?? ""
                        : undefined
                    }
                    clearLastLeadTab={() => {
                      localStorage.removeItem("last-lead-tab-id");
                    }}
                    onNewLeadCreated={() => {}}
                    onLeadArchived={(lead: Lead) => {
                      removeLead(lead);
                    }}
                    orgClients={orgClients}
                    onShowCreateContractDialog={(leadId: string) => {
                      setSelectedLeadId(leadId);
                      setShowCreateContractDialog(true);
                    }}
                  />
                );
              })}
          </Card>
        </div>
      );
    }

    if (activeTabId.indexOf("member-") > -1) {
      const tab = activeTabs.filter((t) => t.id === activeTabId)[0];
      const memberUserProfile = tab.userTabData!.userProfile;

      if (tab.userTabData?.isEditing) {
        return (
          <Editor key={memberUserProfile?.profileId} profileId={memberUserProfile!.profileId!} />
        );
      } else if (tab.userTabData?.isEditingProfileLink !== "") {
        return (
          <LinkEditor
            key={memberUserProfile?.profileId}
            profileId={memberUserProfile!.profileId!}
            managerProfileId={localStorage.getItem("current-profile-id")!}
            profileLinkId={tab.userTabData?.isEditingProfileLink}
          />
        );
      }

      if (tab.userTabData!.profileLinks === null) {
        getProfileLinks(memberUserProfile!.profileId)
          .then((profileLinks: ProfileLink[]) => {
            updateTabData(tab.id, profileLinks);
          })
          .catch((err) => {});
      }

      return (
        <Card key={activeTabId + "_card"}>
          <EditorPreview
            key={activeTabId + "_editorPreview"}
            profileId={memberUserProfile!.profileId!}
            className="max-h-[300px] overflow-hidden"
            onEditProfileClick={() => {
              const currentTabs = [...activeTabs];
              currentTabs[indexForTabId(activeTabId)].userTabData!.isEditing = true;
              saveTabs(currentTabs);
            }}
          />
        </Card>
      );
    }
  };

  return (
    <div>
      <MenuHeader
        organisation={{
          id: organizationOverview.id,
          name: organizationOverview.name,
          logoId: organizationOverview.logoId,
        }}
        onTabSelect={(tabId: string) => {
          changeSelectedTab(tabId);
        }}
        activeTabs={activeTabs}
        onTabCloseClick={(tabId: string) => {
          removeTab(tabId);
        }}
      />
      {isSelectedTabInEditorMode() ? (
        <div className="relative mt-4">
          <div className="min-h-[2px] h-[1px] bg-gray-400 min-w-[100%]" />
          <div className="flex flex-1 justify-center">
            <div className="absolute top-[-18px] flex flex-row">
              <div className="bg-op-element-border-gray p-2 flex-row flex rounded-full mr-1">
                <User />
                {"Edit mode"}
              </div>
              <Pill
                text="Close editor"
                handleClick={() => {
                  const activeTabId = activeTabs.filter((t) => t.selected)[0].id;
                  toggleEditorOnActiveTab(activeTabId);
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
      {isSelectedTabInEditingLinkMode() ? (
        <div className="relative mt-4">
          <div className="min-h-[2px] h-[1px] bg-gray-400 min-w-[100%]" />
          <div className="flex flex-1 justify-center">
            <div className="absolute top-[-18px] flex flex-row">
              <div className="bg-op-element-border-gray p-2 flex-row flex rounded-full mr-1">
                <User />
                {"Edit deal version"}
              </div>
              <Pill
                text="Close editor"
                handleClick={() => {
                  const activeTabId = activeTabs.filter((t) => t.selected)[0].id;
                  closeTabAndMoveToOrg(activeTabId);
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
      <div className="flex flex-1 px-[20px] md:px-[50px] lg:px-[220px] py-16">
        {renderActiveTab()}
      </div>
      <Dialog open={showCreateContractDialog} onClose={(event: object, reason: string) => {}}>
        <DialogContent>
          <div className="flex flex-col p-2">
            <div className="flex flex-row justify-between items-end">
              <h4 className="font-semibold text-lg">Turn lead into contract</h4>
              <Button
                text="Create contract"
                disabled={contractStartDate === ""}
                onButtonClick={() => {
                  const contract: Contract = {
                    id: "",
                    startDate: contractStartDate,
                    endDate: contractEndDate,
                  };
                  createContract(selectedLeadId, contract).then((newContract) => {
                    setOrgLeads([...orgLeads.filter((l) => l.id !== selectedLeadId)]);
                  });
                }}
                style={ButtonStyleType.primary}
                size={ButtonSize.small}
              />
            </div>
            <h6 className="mt-4 text-md mb-2">Duration</h6>
            <div className="flex flex-row">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start date"
                  onChange={(value) => {
                    if (value) {
                      const formattedDate = value.utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
                      setContractStartDate(formattedDate);
                    }
                  }}
                />
              </LocalizationProvider>
              <div className="w-6" />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End date"
                  onChange={(value) => {
                    if (value) {
                      const formattedDate = value.utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
                      setContractEndDate(formattedDate);
                    }
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PageContainer;
