import { faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Avatar } from "../../../../../components";
import { useGetProfile } from "../../../../../services";
import { getImageUrl } from "../../../../../utils/image";

interface Props {
  organizationId: string;
  profileId: string;
  message: string;
  title?: string;
}

export const EventData = ({ message, organizationId, profileId, title }: Props) => {
  // TODO: Add a loading & error state
  const { data } = useGetProfile(
    { organizationId, profileId },
    {
      enabled: !!profileId,
    }
  );

  return (
    <div className="flex flex-row items-center gap-2">
      {data?.profilePhotoId ? (
        <Link to={`/dash/${organizationId}/members/${profileId}`} className="shrink-0">
          <Avatar
            className="w-10 h-10 shrink-0"
            imageUrl={getImageUrl(data.profilePhotoId)}
            alt={typeof data?.profileData.name === "string" ? data.profileData.name : "Profile"}
          />
        </Link>
      ) : (
        <FontAwesomeIcon width={16} height={16} className="p-2" icon={faEye} />
      )}
      <div className="flex flex-col text-sm">
        {title ? (
          <p className="font-bold text-red-3">{title}</p>
        ) : data?.profileId ? (
          <Link to={`/dash/${organizationId}/members/${profileId}`} className="font-bold">
            {typeof data.profileData.name === "string" ? data.profileData.name : "Unknown"}
          </Link>
        ) : (
          <p className="font-bold">Unknown</p>
        )}
        <p className="">{message}</p>
      </div>
    </div>
  );
};

EventData.displayName = "EventData";
