import type React from "react";
import { useState } from "react";
import withCustomStyling from "./HOC/withCustomStyling";
import { ResourceComment, type ResourceEvent, type UserProfile } from "../types";
import IconTextArea from "./IconTextArea";
import { Eye, MessageCircle, Send, User } from "react-feather";
import Button, { ButtonStyleType } from "./atoms/Button";
import { createComment } from "../requests";
import AvatarImage from "./AvatarImage";
import RowMenu from "./RowMenu";

interface ResourceEventProps {
  resourceEvent: ResourceEvent;
  orgUserProfiles: UserProfile[];
  onHoverProfileVisitEvent: (profileVisitId: string) => void;
  onLeaveHoverProfileVisitEvent: () => void;
}

const ResourceEventRow: React.FC<ResourceEventProps> = ({
  resourceEvent,
  orgUserProfiles,
  onHoverProfileVisitEvent,
  onLeaveHoverProfileVisitEvent,
}) => {
  const getUserProfile = (profileId: string) => {
    if (orgUserProfiles.filter((up) => up.profileId === profileId).length > 0) {
      return orgUserProfiles.filter((up) => up.profileId === profileId)[0];
    }
  };

  const getDisplaTitleForEventType = (eventType: string, profileName: string) => {
    if (eventType === "profile-link-visit") {
      return <h6 className="font-semibold text-op-red">New view!</h6>;
    }
    if (eventType === "profile-link-visit-recurring") {
      if (resourceEvent && resourceEvent.diff && resourceEvent.diff.city) {
        return <h6 className="font-semibold text-op-red">New view from returning visitor</h6>;
      }
    }
    if (eventType === "lead-profile-change") {
      return <h6 className="font-semibold">{profileName}</h6>;
    }
  };

  const getDisplayTextForEventType = (eventType: string, profileName: string) => {
    if (eventType === "lead-created") {
      return "created lead";
    }
    if (eventType === "profile-link-visit") {
      if (resourceEvent && resourceEvent.diff && resourceEvent.diff.city) {
        return `someone in ${resourceEvent.diff.city} viewed your link`;
      } else {
        return `someone viewed your link`;
      }
    }
    if (eventType === "profile-link-visit-recurring") {
      if (resourceEvent && resourceEvent.diff && resourceEvent.diff.city) {
        return `someone in ${resourceEvent.diff.city} viewed your link again`;
      } else {
        return `someone viewed your link`;
      }
    }
    if (eventType === "priority-change") {
      if (resourceEvent && resourceEvent.diff && resourceEvent.diff.newPriority) {
        var priorityName = "";
        switch (resourceEvent.diff.newPriority) {
          case 0:
            priorityName = "cold";
            break;
          case 1:
            priorityName = "medium";
            break;
          case 2:
            priorityName = "hot";
            break;
        }
        return `changed priority to ` + priorityName;
      } else {
        return `priority was changed`;
      }
    }

    if (eventType === "lead-profile-change") {
      if (resourceEvent.diff && resourceEvent.diff.newProfileId) {
        const userProfile = getUserProfile(resourceEvent.diff.newProfileId);
        return `assigned ${userProfile?.profileData.name} to the lead`;
      }
    }
  };

  const formatDateString = (dateString: string) => {
    const date = new Date(dateString);

    // Options for formatting the date and time
    const options: Intl.DateTimeFormatOptions = {
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };

    // Format the date
    const formattedDate = date.toLocaleDateString("sv-SE", options);
    return formattedDate;
  };

  return (
    <div
      onMouseEnter={() => {
        if (resourceEvent.diff && resourceEvent.diff.profileVisitId) {
          onHoverProfileVisitEvent(resourceEvent.diff.profileVisitId);
        }
      }}
      onMouseLeave={() => {
        onLeaveHoverProfileVisitEvent();
      }}
    >
      <div className="flex flex-row justify-between">
        <div className="flex flex-row justify-center items-center">
          {resourceEvent.triggeredByUserProfile ? (
            <AvatarImage photoId={resourceEvent.triggeredByUserProfile.profilePhotoId} small />
          ) : (
            <div className="flex flex-1 w-[40px] h-[40px] justify-center items-center">
              <Eye />
            </div>
          )}
          <div className="text-sm ml-2">
            {resourceEvent.triggeredByUserProfile ? (
              <h6 className="font-semibold">
                {resourceEvent.triggeredByUserProfile.profileData.name}
              </h6>
            ) : (
              getDisplaTitleForEventType(resourceEvent.eventType, "")
            )}

            <p>
              {resourceEvent.triggeredByUserProfile
                ? getDisplayTextForEventType(
                    resourceEvent.eventType,
                    resourceEvent.triggeredByUserProfile.profileData.name
                  )
                : getDisplayTextForEventType(resourceEvent.eventType, "")}
            </p>
          </div>
        </div>
        <div className="flex flex-row align-center items-center">
          <div className="text-sm">{formatDateString(resourceEvent.createdAt)}</div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default withCustomStyling(ResourceEventRow);
