import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "../../../../components/utils/cn";
import { LeadButton, type LeadButtonProps } from "./LeadButton";

interface Props extends LeadButtonProps {
  title?: string;
}

export const LeadAssignmentButton = ({ title, className, ...props }: Props) => (
  <LeadButton {...props} className={cn("w-40 xl:w-56 flex", className)}>
    <FontAwesomeIcon width={20} height={20} icon={faBriefcase} className="w-5 h-5 shrink-0" />
    <p className="truncate w-full">{title ?? "assignment"}</p>
  </LeadButton>
);

LeadAssignmentButton.displayName = "LeadAssignmentButton";
