import { faBriefcase, faGlobe } from "@fortawesome/free-solid-svg-icons";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { type PropsWithChildren, forwardRef, useMemo, useReducer } from "react";
import { trim } from "validator";
import type { Lead } from "../../../../../client";
import {
  Button,
  Input,
  Notice,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  isUrlOrEmpty,
} from "../../../../../components";
import { iconForName } from "../../../../../components/TechIcons";
import { useAnimate } from "../../../../../hooks";
import { LeadRowCard } from "../card/LeadRowCard";
import { LeadRowCardSection } from "../card/LeadRowCardSection";
import { PanelContainer } from "./PanelContainer";

interface Props {
  lead?: Lead;
  disabled: boolean;
  onMutateLead: (lead: Partial<Lead>) => void;
  onRequestAssignmentInfo: (url: string) => void;
}

const MAX_VISIBLE_SKILLS = 5;

export const AssignmentPanel = ({
  lead,
  disabled,
  onMutateLead,
  onRequestAssignmentInfo,
}: Props) => {
  const [skillsParent] = useAnimate();
  const [expanded, toggleExpanded] = useReducer((expanded) => !expanded, false);

  const skills = useMemo(
    () =>
      lead?.assignmentInfo?.skills
        ?.map((skill) =>
          iconForName(skill) ? { icon: iconForName(skill), name: skill } : undefined
        )
        .filter(Boolean),
    [lead?.assignmentInfo?.skills]
  );

  return (
    <PanelContainer>
      <LeadRowCard type="solid">
        <LeadRowCardSection title="Auto-fill magic">
          <p className="text-sm">Add link to see magic!</p>
          <Input
            disabled={disabled}
            leftIcon={faGlobe}
            label="Link"
            validate={isUrlOrEmpty}
            value={lead?.assignmentInfo?.assignmentUrl}
            rightButton={{
              label: "Go!",
              onClick: onRequestAssignmentInfo,
            }}
          />
        </LeadRowCardSection>
      </LeadRowCard>
      <LeadRowCard type="dashed">
        <Input
          leftIcon={faBriefcase}
          label="Role"
          value={lead?.title || lead?.assignmentInfo?.title}
          onFinishWriting={(title) => onMutateLead({ title })}
          transformValue={trim}
        />
        {lead?.assignmentInfo ? (
          <>
            <LeadRowCardSection title="Technical skills">
              <div className="flex flex-row flex-wrap gap-1 px-4">
                {skills?.map((skill) => (
                  <TooltipProvider key={skill?.name} delayDuration={0}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className="w-6 h-6">{skill?.icon}</div>
                      </TooltipTrigger>
                      <TooltipContent className="mb-2">
                        <p>{skill?.name}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ))}
              </div>
            </LeadRowCardSection>
            <LeadRowCardSection title="All skills">
              <List ref={skillsParent}>
                {lead.assignmentInfo.skills
                  .slice(0, expanded ? undefined : MAX_VISIBLE_SKILLS)
                  .map((skill) => (
                    <li key={skill}>{skill}</li>
                  ))}
                {lead.assignmentInfo.skills.length > MAX_VISIBLE_SKILLS && (
                  <Button className="mt-2 w-max" onClick={toggleExpanded}>
                    {expanded ? "show less" : "show more"}
                  </Button>
                )}
              </List>
            </LeadRowCardSection>
            <LeadRowCardSection title="Responsibilities">
              <List>
                {lead.assignmentInfo.responsibilities.map((responsibility) => (
                  <li key={responsibility}>{responsibility}</li>
                ))}
              </List>
            </LeadRowCardSection>
            <LeadRowCardSection title="Location">
              <List>
                {lead.assignmentInfo.location.map((location) => (
                  <li key={location}>{location}</li>
                ))}
              </List>
            </LeadRowCardSection>
            <LeadRowCardSection title="Remote">
              <List>
                <li>{lead.assignmentInfo.remote}</li>
              </List>
            </LeadRowCardSection>
          </>
        ) : (
          <Notice label="Use auto-fill-magic to add information about the assignment" />
        )}
      </LeadRowCard>
    </PanelContainer>
  );
};

const List = forwardRef<HTMLUListElement, PropsWithChildren>(({ children }, ref) => (
  <ul ref={ref} className="list-disc list-inside text-sm px-4">
    {children}
  </ul>
));

List.displayName = "List";

AssignmentPanel.displayName = "AssignmentPanel";
