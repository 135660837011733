import type { PropsWithChildren } from "react";
import { SectionTitle } from "./SectionTitle";
import { cn } from "../utils/cn";

type Props = PropsWithChildren<{
  className?: string;
}>;

export const BulletSectionTitle = ({ children, className }: Props) => (
  <div className={cn("py-2 flex flex-row gap-2 items-center", className)}>
    <span className="text-base font-black text-black">•</span>
    <SectionTitle>{children}</SectionTitle>
  </div>
);

BulletSectionTitle.displayName = "BulletSectionTitle";
