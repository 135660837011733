import React, { useState } from "react";
import { type ImageUpload, ProfileComponentProps } from "../types";
import "./components.css";
import animationData from "../assets/spinner-animation.json";
import Lottie from "react-lottie";
import DefaultIcon from "../assets/op_o.png";

interface Props {
  id: string;
  allowEdit: boolean;
  photoAssetId?: string;
  isLoading: boolean;
  round: boolean;
  onImageSelected: (imageUpload: ImageUpload) => void;
  onProjectPhotoSelect?: (assetId: string) => void;
}

export default function ProjectPhotoItem(props: Props) {
  const [hover, setHover] = useState<boolean>(false);

  if (props.isLoading) {
    <div
      className={
        "min-w-[110px] min-h-[110px] max-w-[110px] max-h-[110px] border-op-dark relative overflow-hidden mr-2 flex justify-center align-center" +
        (props.allowEdit ? " hover:border-[1px]" : "") +
        (props.round ? " rounded-[55px]" : "")
      }
    >
      <Lottie style={{ maxWidth: 30, maxHeight: 30 }} options={{ animationData: animationData }} />
    </div>;
  }

  return (
    <div
      className={
        "min-w-[65px] min-h-[65px] max-w-[65px] max-h-[65px] border-op-dark relative overflow-hidden p-[1px]" +
        (props.allowEdit ? " hover:border-[1px]" : "") +
        (props.round ? " rounded-[55px]" : "")
      }
      onClick={() => {
        if (props.photoAssetId) {
          if (props.onProjectPhotoSelect) {
            props.onProjectPhotoSelect(props.photoAssetId);
          }
          return;
        }
        document.getElementById("logo-uploader-" + props.id)?.click();
      }}
      onMouseEnter={() => {
        if (!props.allowEdit) {
          return;
        }
        setHover(true);
      }}
      onMouseLeave={() => setHover(false)}
    >
      {!props.photoAssetId && !props.isLoading ? (
        <div className="text-sm text-center flex justify-center items-center align-center flex-1 h-full">
          <label>Add image</label>
        </div>
      ) : null}

      {!props.isLoading ? (
        <div>
          <img
            className="object-contain"
            src={
              props.photoAssetId
                ? "https://pub-4b15203a4a0f4b26ab71deeffce0164a.r2.dev/" + props.photoAssetId
                : ""
            }
          />

          <input
            className="hidden"
            type="file"
            id={"logo-uploader-" + props.id}
            onChange={(event) => {
              if (event.target.files === null) return;

              const file = event.target.files[0];
              const reader = new FileReader();

              reader.onload = (e: ProgressEvent<FileReader>) => {
                const formatInfo = (e.target?.result as string).split(",")[0];
                const base64String = (e.target?.result as string).split(",")[1];
                const format = formatInfo.split(";")[0].split(":")[1];
                const imageUpload: ImageUpload = {
                  fileType: formatInfo,
                  base64File: base64String,
                };
                props.onImageSelected(imageUpload);
              };

              reader.readAsDataURL(file);
            }}
          ></input>
        </div>
      ) : (
        <div className="flex flex-1 justify-center align-center items-center h-full">
          <Lottie style={{ width: 30 }} options={{ animationData: animationData }} />
        </div>
      )}
    </div>
  );
}
