import type { SkillBlock } from "./types";

export function getYears() {
  const currentYear = new Date().getFullYear();
  var years: string[] = [];
  for (var i = 0; i < 50; i++) {
    years.push(String(currentYear - i));
  }
  return years;
}

export function getSelectedMonthValue(selected: string) {
  if (selected) {
    if (selected.indexOf("-") > -1) {
      return selected.split("-")[1];
    }
  }
  return "";
}

export function getSelectedYearValue(selected: string) {
  if (selected) {
    if (selected.indexOf("-") > -1) {
      return selected.split("-")[0];
    }
    return selected;
  }
  return "";
}

export function getDateDisplayString(dateString: string) {
  if (dateString && dateString.indexOf("-") > -1) {
    const monthStringValue = dateString.split("-")[1];
    const monthIndex: number = Number.parseInt(monthStringValue) - 1;
    return monthNames[monthIndex] + " " + dateString.split("-")[0];
  }
  return "now";
}

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const monthValues = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
export const skillLevels = [
  { value: 4, name: "Expert" },
  { value: 3, name: "Advanced" },
  { value: 2, name: "Familiar" },
  { value: 1, name: "Beginner" },
];

export function applyOrderToSkillBlocks(skillBlocks: SkillBlock[]) {
  const sortedSkillBlocks = skillBlocks.sort((a, b) => a.order - b.order);

  for (var i = 0; i < sortedSkillBlocks.length; i++) {
    sortedSkillBlocks[i].order = i;
  }
  return sortedSkillBlocks;
}
