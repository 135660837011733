import React, { useState, useEffect } from "react";
import Button, { ButtonStyleType } from "./atoms/Button";
import {
  type ProfileSkillTag,
  type SideProject,
  type Workplace,
  type ProfileComponentProps,
  ImageUpload,
  type ProjectPhoto,
  type ContentSuggestionRequestBody,
  type ProjectsLinkConfig,
} from "../types";
import { GitHub, Link, Plus, Settings, Target } from "react-feather";
import "./components.css";
import EditorTextButton from "./atoms/EditorTextButton";
import SkillTag from "./SkillTag";
import EditorSkillTagButton from "./atoms/EditorSkillTagButton";
import EditorIconButton from "./atoms/EditorIconButton";
import VkTextArea from "./legacy/VkTextArea";
import EditorTextField from "./EditorTextField";
import { Menu } from "react-feather";
import { createProjectPhoto, getContentImprovementSuggestion } from "../requests";
import Lottie from "react-lottie";
import animationData from "../assets/spinner-animation.json";

import ProjectPhotosRow from "./ProjectPhotosRow";

interface SideProjectEditorProps extends ProfileComponentProps {
  sideProject?: any;
  isEditable: boolean;
  isNew: boolean;
  onSaveSideProject: (sideProject: SideProject) => void;
  onCancelEdit?: () => void;
  onRemove: () => void;
  onShowDialog: (dialogType: string, projectOrder: number) => void;
  isBeingEdited: boolean;
  currentlySelectedWorkplace?: Workplace | undefined | null;
  isLastInOrder: boolean;
  onContentImprovementStarted?: () => void;
  onContentImprovementEnded?: () => void;
  projectPhotos: ProjectPhoto[];
  onProjectPhotoSelect?: (assetId: string) => void;
  onProjectPhotosUpdate?: (updatedProjectPhotos: ProjectPhoto[]) => void;
  workplaceName?: string;
  projectLinkConfig?: ProjectsLinkConfig;
  removePadding?: boolean;
}

function SideProjectEditor({
  isDarkModeActive,
  isEditable,
  sideProject,
  isNew,
  onSaveSideProject,
  isBeingEdited,
  onCancelEdit,
  onRemove,
  onShowDialog,
  currentlySelectedWorkplace,
  isLastInOrder,
  onContentImprovementStarted,
  onContentImprovementEnded,
  projectPhotos,
  onProjectPhotoSelect,
  onProjectPhotosUpdate,
  workplaceName,
  projectLinkConfig,
  removePadding,
}: SideProjectEditorProps) {
  const contentSuggestionActions = [
    "Less formal",
    "More formal",
    "Shorter",
    "Funnier",
    "Less braggy",
  ];

  const [currentSideProject, setCurrentSideProject] = useState<SideProject>({
    ...sideProject,
  });

  useEffect(() => {
    setCurrentSideProject(sideProject);
  }, [sideProject]);
  const [contentSuggestionRequestBody, setContentSuggestionRequestBody] =
    useState<ContentSuggestionRequestBody | null>(null);
  const [isMouseHovering, setIsMouseHovering] = useState<boolean>(false);
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [isLoadingContentSuggestion, setIsLoadingContentSuggestion] = useState<boolean>(false);
  const [currentImproventSuggestions, setCurrentImproventSuggestions] = useState<string>("");

  const [pitchHaveFocus, setPitchHaveFocus] = useState<boolean>(false);

  const onTitleInput = (input: string) => {
    const newSideProject = { ...currentSideProject, title: input };
    onSaveSideProject(newSideProject);
    setCurrentSideProject(newSideProject);
  };

  const onPitchInput = (input: string) => {
    setPitchHaveFocus(false);
    const newSideProject = { ...currentSideProject, pitch: input };
    onSaveSideProject(newSideProject);
    setCurrentSideProject(newSideProject);
  };

  const onGitHubUrlInput = (input: string) => {
    const newSideProject = { ...currentSideProject, githubUrl: input };
    setCurrentSideProject(newSideProject);
  };

  const onWebsiteUrlInput = (input: string) => {
    const newSideProject = { ...currentSideProject, websiteUrl: input };
    setCurrentSideProject(newSideProject);
  };

  let projectSkills = [];
  if (sideProject && sideProject.skillTags) {
    projectSkills = sideProject.skillTags;
  }

  return (
    <div
      className={"project-editor"}
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
        flex: 1,
      }}
      onMouseEnter={() => setIsMouseHovering(true)}
      onMouseLeave={() => setIsMouseHovering(false)}
    >
      <div className="flex flex-1 flex-row">
        {showSidebar ? (
          <div className="flex-1 flex flex-col items-center align-center">
            <EditorTextButton text="Remove" handleClick={() => onRemove()}></EditorTextButton>
          </div>
        ) : null}
        <div className="flex flex-[5] flex-col">
          <div style={{ flex: 1, flexDirection: "row" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              {removePadding === true ? null : (
                <div
                  className={
                    "border-[1px] border-op-dark mb-[4px] max-w-[18px] max-h-[18px] min-w-[18px] min-h-[18px] flex justify-center items-center" +
                    (isMouseHovering && isEditable ? " visible" : " invisible")
                  }
                  onClick={() => {
                    setShowSidebar(!showSidebar);
                  }}
                >
                  <Settings size={12} color="#202124" />
                </div>
              )}
              <div style={{ flex: 1 }} className={removePadding === true ? "" : "pl-[8px]"}>
                {isEditable ? (
                  <EditorTextField
                    disableHoverUnderline={!isEditable}
                    disableEditing={!isEditable}
                    isDarkModeActive={isDarkModeActive}
                    autofocus={false}
                    styles={{ display: "inline", width: "100%" }}
                    placeholder="Project name"
                    onLeaveField={onTitleInput}
                    value={currentSideProject.title}
                    inputStyles={{ fontSize: 18, fontWeight: 600 }}
                    useDynamicWidth
                  />
                ) : (
                  <label style={{ fontSize: 20, fontWeight: "bold" }}>
                    {currentSideProject.title}
                  </label>
                )}
              </div>
            </div>

            {workplaceName || isEditable ? (
              <div
                style={{
                  height: 50,
                  marginBottom: 0,
                  marginTop: 5,
                  paddingLeft: removePadding === true ? "" : "26px",
                }}
              >
                <div style={{ minWidth: "70%", float: "left" }}>
                  <EditorTextButton
                    disableEditing={!isEditable}
                    isDarkModeActive={isDarkModeActive}
                    text={
                      currentSideProject.isSideProject
                        ? "Side project"
                        : workplaceName !== ""
                          ? workplaceName!
                          : "Select workplace"
                    }
                    handleClick={() => onShowDialog("workplace", currentSideProject.order)}
                  />
                </div>
                <div className="flex flex-row justify-end pr-2 float-right">
                  {isEditable ? (
                    isLoadingContentSuggestion ? (
                      <Lottie style={{ width: 30 }} options={{ animationData: animationData }} />
                    ) : null
                  ) : null}
                </div>
              </div>
            ) : (
              <div style={{ height: 10, marginBottom: 0, marginTop: 5 }} />
            )}

            {currentImproventSuggestions != "" ? (
              <div
                className={
                  "relative pt-24 mb-6 pb-4 border-b-[1px] border-op-gray-200 " +
                  (removePadding === true ? "" : "pl-[26px]")
                }
              >
                <VkTextArea
                  isEditable={false}
                  isLocked={true}
                  isDarkModeActive={isDarkModeActive}
                  placeholder="Short description where you can sumarize the project and highlight your contribution, main challanges and present the project outcome."
                  onLeaveField={onPitchInput}
                  value={currentImproventSuggestions}
                  disableHoverUnderline={true}
                />
                <div className="w-full bg-op-blue absolute top-0 p-2 text-white font-semibold ">
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-1 flex-row">
                      <label>
                        {currentImproventSuggestions === "No"
                          ? "The current description does not seem to be a project description. Please update it and try again"
                          : "Suggested changes"}
                      </label>
                      <div className="w-[10px]" />
                    </div>

                    <div className="flex-1 flex flex-row justify-end">
                      <Button
                        text="Close"
                        onButtonClick={() => {
                          setCurrentImproventSuggestions("");
                          setContentSuggestionRequestBody(null);
                          if (onContentImprovementEnded) {
                            onContentImprovementEnded();
                          }
                        }}
                        style={ButtonStyleType.link}
                      />
                      <div className="w-[10px]" />
                      {currentImproventSuggestions !== "No" ? (
                        <Button
                          text="Approve"
                          onButtonClick={() => {
                            onPitchInput(currentImproventSuggestions);
                            setCurrentImproventSuggestions("");
                          }}
                          style={ButtonStyleType.link}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="pt-2">
                    {contentSuggestionActions.map((action, index) => {
                      return (
                        <EditorTextButton
                          text={action}
                          handleClick={() => {
                            var currentMessages = [...contentSuggestionRequestBody!.messages];
                            currentMessages.push({
                              order: currentMessages.length,
                              content: action,
                              fromUser: true,
                            });

                            getContentImprovementSuggestion({
                              ...contentSuggestionRequestBody!,
                              messages: currentMessages,
                            }).then(
                              (contentSuggestionRequestBody: ContentSuggestionRequestBody) => {
                                setIsLoadingContentSuggestion(false);
                                setContentSuggestionRequestBody(contentSuggestionRequestBody);
                                setCurrentImproventSuggestions(
                                  contentSuggestionRequestBody.messages[
                                    contentSuggestionRequestBody.messages.length - 1
                                  ].content
                                );
                              }
                            );
                          }}
                          white
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}

            {currentSideProject.pitch !== "" || isEditable ? (
              <div className="flex flex-row flex-1">
                {removePadding === true ? null : (
                  <div
                    className={
                      "border-[1px] border-op-dark mb-[4px] max-w-[18px] max-h-[18px] min-w-[18px] min-h-[18px] flex justify-center items-center" +
                      (pitchHaveFocus ? " visible" : " invisible")
                    }
                    onClick={() => {
                      if (onContentImprovementStarted) {
                        onContentImprovementStarted();
                      }
                      setIsLoadingContentSuggestion(true);
                      const requestBody: ContentSuggestionRequestBody = {
                        originalText: currentSideProject.pitch,
                        messages: [],
                        type: "project",
                      };
                      getContentImprovementSuggestion(requestBody).then(
                        (contentSuggestionRequestBody: ContentSuggestionRequestBody) => {
                          setIsLoadingContentSuggestion(false);
                          setContentSuggestionRequestBody(contentSuggestionRequestBody);
                          setCurrentImproventSuggestions(
                            contentSuggestionRequestBody.messages[
                              contentSuggestionRequestBody.messages.length - 1
                            ].content
                          );
                        }
                      );
                    }}
                  >
                    <Target size={14} color="#202124" />
                  </div>
                )}
                <div className={"flex-1 " + (removePadding === true ? "" : "pl-[8px]")}>
                  <VkTextArea
                    isEditable={isEditable}
                    isDarkModeActive={isDarkModeActive}
                    placeholder="Short description where you can sumarize the project and highlight your contribution, main challanges and present the project outcome."
                    onLeaveField={onPitchInput}
                    value={currentSideProject.pitch}
                    disableHoverUnderline={!isEditable}
                    onFocus={() => setPitchHaveFocus(true)}
                  />
                </div>
              </div>
            ) : null}

            {projectLinkConfig !== undefined && !projectLinkConfig.displaySkills ? null : (
              <div
                style={{ display: "flex", flexWrap: "wrap", marginTop: 5 }}
                className={removePadding === true ? "" : "pl-[26px]"}
              >
                {projectSkills.map((skillOption: ProfileSkillTag, index: number) => {
                  return (
                    <EditorSkillTagButton
                      disableEditing={!isEditable}
                      isDarkModeActive={isDarkModeActive}
                      key={index}
                      text={skillOption.name}
                      handleClick={() => {
                        onShowDialog("tags", sideProject.order);
                      }}
                    />
                  );
                })}
              </div>
            )}

            {isEditable ? (
              <div className="pl-[26px]">
                <EditorTextButton
                  disableEditing={!isEditable}
                  isDarkModeActive={isDarkModeActive}
                  text="Add tag"
                  handleClick={() => onShowDialog("tags", sideProject.order)}
                />
              </div>
            ) : null}

            {isEditable || projectPhotos.length > 0 ? (
              <div className="mt-2 pl-[26px]">
                <ProjectPhotosRow
                  projectId={currentSideProject.id}
                  projectPhotos={projectPhotos}
                  allowEdit={isEditable}
                  onProjectPhotoSelect={onProjectPhotoSelect}
                  onProjectPhotosUpdate={onProjectPhotosUpdate}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideProjectEditor;
