import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "../buttons";
import { DrawerClose } from "../drawer";

const NavigationDrawerHeader = () => (
  <div className="w-full flex flex-row items-center justify-end py-4 px-5 bg-gray-1 rounded-tl-2xl">
    <DrawerClose asChild>
      <IconButton icon={faXmark} />
    </DrawerClose>
  </div>
);

NavigationDrawerHeader.displayName = "NavigationDrawerHeader";

export { NavigationDrawerHeader };
