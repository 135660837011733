import type React from "react";
import withCustomStyling from "./HOC/withCustomStyling";

interface IconInputProps {
  icon: React.ReactNode;
  placeholder?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const IconInput: React.FC<IconInputProps> = ({ icon, placeholder, value, onChange, onBlur }) => {
  return (
    <div className="flex items-center border rounded-lg p-2">
      <div className="mr-2">{icon}</div>
      <input
        type="text"
        className={"flex-1 outline-none h-[40px] bg-transparent text-md"}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};

export default withCustomStyling(IconInput);
