import type { PropsWithChildren } from "react";
import { Radio } from "../../../../../components";
import { cn } from "../../../../../components/utils/cn";

type Props = PropsWithChildren<{
  title: string;
  className?: string;
  selected?: boolean;
  onCheckedChange?: (checked: boolean) => void;
}>;

export const LeadRadioRowCardSection = ({
  title,
  className,
  selected,
  onCheckedChange,
  children,
}: Props) => (
  <form className={cn("flex flex-col gap-2", className)}>
    <Radio checked={selected} onCheckedChange={onCheckedChange} label={title} />
    {children}
  </form>
);

LeadRadioRowCardSection.displayName = "LeadRadioRowCardSection";
