import { User } from "react-feather";
import Button, { ButtonStyleType } from "./atoms/Button";

interface Props {
  title?: string;
  children: React.ReactNode;
}

const Card = (props: Props) => {
  return (
    <div className="relative bg-op-element-gray px-2 py-4 flex flex-col rounded-xl flex-1">
      <div className="flex flex-1 justify-center">
        {props.title ? (
          <div className="absolute top-[-18px] bg-op-element-border-gray p-2 flex-row flex rounded-full">
            <User />
            {props.title ? props.title : "Card"}
          </div>
        ) : null}
      </div>

      {props.children}
    </div>
  );
};

export default Card;
