import type { QueryClient } from "@tanstack/react-query";
import { type LoaderFunctionArgs, defer } from "react-router-dom";
import { getClientsOptions } from "../../../services";

export const buildClientsLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    if (!params.organizationId) throw new Error("Organization ID is required");

    return defer({
      clients: queryClient.ensureQueryData(getClientsOptions(params.organizationId)),
    });
  };
