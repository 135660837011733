import type { ContainerProps } from "./types";
import { motion } from "framer-motion";

export const FadeInUpContainer = ({ children, ...props }: ContainerProps) => (
  <motion.div
    {...props}
    initial={{ opacity: 0, y: 16 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.25 }}
  >
    {children}
  </motion.div>
);

FadeInUpContainer.displayName = "FadeInUpContainer";
