import { IconButton, Tooltip } from "@mui/material";
import "../components.css";
import type React from "react";

interface Props {
  text: string;
  iconChild?: React.ReactNode;
  handleClick: () => void;
}

const Pill = (props: Props) => {
  return (
    <div
      onClick={props.handleClick}
      className="bg-op-blue rounded-full px-2 border-2 border-op-blue text-white font-semibold flex items-center cursor-pointer"
    >
      {props.iconChild ?? null}
      {props.text}
    </div>
  );
};

export default Pill;
