import { IconButton, Tooltip } from "@mui/material";
import "../components.css";
import React, { useState } from "react";
import { iconForName } from "../TechIcons";
import DefaultSkillIcon from "../../assets/defaultSkillIcon.png";

interface Props {
  skillName: string;
  styling?: string;
}

const SkillIcon = (props: Props) => {
  const [hover, setHover] = useState<boolean>(false);
  return (
    <div
      className={"w-[35px] relative flex rounded-lg overflow-hidden" + (props.styling ?? "")}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <Tooltip title={props.skillName}>
        <div className="w-full">
          {iconForName(props.skillName) ?? <img src={DefaultSkillIcon} />}
        </div>
      </Tooltip>
    </div>
  );
};

export default SkillIcon;
