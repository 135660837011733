import type { ResourceEvent } from "../../../../../client";

export const LEAD_CREATED_EVENT_TYPE = "lead-created";
export const LEAD_PROFILE_CHANGE_EVENT_TYPE = "lead-profile-change";
export const PROFILE_LINK_VISIT_EVENT_TYPE = "profile-link-visit";
export const PROFILE_LINK_VISIT_RECURRING_EVENT_TYPE = "profile-link-visit-recurring";
export const PRIORITY_CHANGE = "priority-change";

const supportedEventTypes = [
  LEAD_CREATED_EVENT_TYPE,
  LEAD_PROFILE_CHANGE_EVENT_TYPE,
  PROFILE_LINK_VISIT_EVENT_TYPE,
  PROFILE_LINK_VISIT_RECURRING_EVENT_TYPE,
  PRIORITY_CHANGE,
] as const;

export type SupportedEventType = (typeof supportedEventTypes)[number];

export const isEvent = (event: unknown): event is ResourceEvent =>
  typeof event === "object" && event !== null && "eventType" in event;

export const isEventTypeSupported = (eventType: string): eventType is SupportedEventType =>
  supportedEventTypes.includes(eventType as SupportedEventType);

interface VisitEvent extends ResourceEvent {
  diff: {
    profileVisitId: string;
  };
}

export const isProfileLinkVisitEvent = (event: ResourceEvent): event is VisitEvent =>
  event.eventType === PROFILE_LINK_VISIT_EVENT_TYPE && !!event.diff?.profileVisitId;

export const isProfileLinkVisitRecurringEvent = (event: ResourceEvent): event is VisitEvent =>
  event.eventType === PROFILE_LINK_VISIT_RECURRING_EVENT_TYPE && !!event.diff?.profileVisitId;
