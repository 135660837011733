import type React from "react";
import withCustomStyling from "./HOC/withCustomStyling";

interface CardTabProps {
  icon: React.ReactNode;
  text: string;
  secondaryText?: string;
  selected?: boolean;
  onClick: () => void;
}

const CardTab: React.FC<CardTabProps> = ({
  icon,
  text,
  secondaryText,
  selected = false,
  onClick,
}) => {
  return (
    <div
      className={`flex items-center justify-center p-4 rounded-lg cursor-pointer transition-shadow h-full
        ${selected ? "bg-gray-200 text-gray-800" : "text-gray-800 hover:bg-gray-200"}`}
      onClick={onClick}
    >
      <div>{icon}</div>

      <div className="flex flex-col flex-1">
        {text !== "" ? <span className="text-md ml-4 font-medium flex-1">{text}</span> : null}
        {secondaryText ? (
          <span className="text-sm ml-4 font-medium flex-1">{secondaryText}</span>
        ) : null}
      </div>
    </div>
  );
};

export default withCustomStyling(CardTab);
