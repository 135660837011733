import { useCallback } from "react";
import { useSnapshot } from "valtio";
import type { Lead } from "../../../../client";
import { useGetClient, useUpdateLead } from "../../../../services";
import {
  type LeadRowActiveState,
  leadsState,
  setLeadRowActiveState,
} from "../../../../state/leads";
import { LeadRow } from "./LeadRow";

interface Props {
  organizationId: string;
  lead: Lead;
}

export const ExistingLeadRow = ({ organizationId, lead }: Props) => {
  if (!lead.id) throw new Error("Missing lead ID");

  const leadsSnap = useSnapshot(leadsState);
  const activeState = leadsSnap.leadRows[lead.id] ?? false;

  const updateLead = useUpdateLead();

  const { data: client } = useGetClient({
    clientId: updateLead.variables?.body.clientId ?? lead.clientId,
    organizationID: organizationId,
  });

  const onUpdateLead = useCallback(
    (payload: Partial<Lead>) => {
      updateLead.mutate({
        path: { organizationID: organizationId, leadID: lead.id },
        body: { ...lead, ...payload },
      });
    },
    [updateLead, organizationId, lead]
  );

  const toggleActiveState = (state: LeadRowActiveState) => setLeadRowActiveState(lead.id, state);

  return (
    <LeadRow
      lead={updateLead.variables?.body ?? lead}
      activeState={activeState}
      toggleActiveState={toggleActiveState}
      organizationId={organizationId}
      client={client}
      onUpdateLead={onUpdateLead}
    />
  );
};

ExistingLeadRow.displayName = "ExistingLeadRow";
