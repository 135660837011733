import type React from "react";
import type { ProfileComponentProps } from "../types";
import "./components.css";

interface ContentToggleProps extends ProfileComponentProps {
  renderToggleOnContent: () => React.ReactNode;
  renderToggleOffContent: () => React.ReactNode;
  onToggleOn?: () => void;
  onToggleOff?: () => void;
  toggled: boolean;
  dragHandle?: () => React.ReactNode;
}

function ContentToggle({
  renderToggleOnContent,
  renderToggleOffContent,
  onToggleOn,
  onToggleOff,
  toggled,
  dragHandle,
}: ContentToggleProps) {
  return (
    <div className="flex pb-2 flex-1">
      <div className="width-80 flex-col" style={{ display: "flex", alignItems: "flex-start" }}>
        <label className="switch">
          <input
            type="checkbox"
            checked={toggled}
            onChange={(e) => {
              if (e.target.checked) {
                if (onToggleOn) {
                  onToggleOn();
                }
              } else if (!e.target.checked) {
                if (onToggleOff) {
                  onToggleOff();
                }
              }
            }}
          />
          <span className="slider round"></span>
        </label>
        {dragHandle !== undefined ? dragHandle!() : null}
      </div>
      {toggled ? renderToggleOnContent() : renderToggleOffContent()}
      <div></div>
    </div>
  );
}

export default ContentToggle;
