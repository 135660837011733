import { motion } from "framer-motion";
import type { PropsWithChildren } from "react";
import { cn } from "../../../../../components/utils/cn";

type Props = PropsWithChildren<{
  className?: string;
}>;

export const ExpandableSection = ({ children, className }: Props) => (
  <motion.div
    initial={{
      height: 0,
      opacity: 0,
    }}
    animate={{
      height: "auto",
      opacity: 1,
    }}
    exit={{
      height: 0,
      opacity: 0,
    }}
    transition={{
      duration: 0.1,
    }}
    className={cn("overflow-hidden", className)}
  >
    {children}
  </motion.div>
);

ExpandableSection.displayName = "ExpandableSection";
