import "../App.css";

import { useState, useEffect } from "react";

import PersonalDetailsSection from "../components/PersonalDetailsSection";

import { useNavigate } from "react-router-dom";

import type {
  Profile,
  SideProject,
  SectionTypeDescription,
  ProfileLinkConfig,
  Branding,
  AccountProfile,
  ProjectPhoto,
  ProfileContactDetails,
} from "../types";
import { createGuestUser, fetchProfileFromLinkId, updateProfileVisit } from "../requests";
import SideProjectSection from "../components/SideProjectSection";

import "../components/components.css";
import { darkModeBlack } from "../colors";
import Button, { ButtonStyleType } from "../components/atoms/Button";
import animationData from "../assets/spinner-animation.json";
import Lottie from "react-lottie";
import NavigationHeader from "../components/NavigationHeader";
import { useMediaQuery } from "react-responsive";
import { motion } from "framer-motion";
import { Dialog } from "@material-ui/core";
import ProjectPhotoViewer from "../components/ProjectPhotoViewer";
import WorkplaceSection from "../components/WorkplaceSection";
import EditorTitle from "../components/EditorTitle";
import ContactBox from "../components/ContactBox";
import { useParams } from "react-router-dom";

interface ProfileViewerProps {
  profileLinkId?: string;
}

interface RouteParams {
  [key: string]: string | undefined;
  profileLinkId?: string;
}

function ProfileViewer() {
  const { profileLinkId } = useParams<RouteParams>();

  const navigate = useNavigate();
  const [darkModeActive, setDarkModeActive] = useState<boolean>(false);
  const [profileLink, setProfileLink] = useState("");
  const [linkCreatorProfileId, setLinkCreatorProfileId] = useState("");
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [isCreator, setIsCreator] = useState<boolean>(false);
  const [viewDialogFullScreen, setViewDialogFullScreen] = useState<boolean>(false);
  const [isManager, setIsManager] = useState<boolean>(false);
  const [profilePhotoId, setProfilePhotoId] = useState<string>("");
  const [accountProfile, setAccountProfile] = useState<AccountProfile | null>(null);

  const [selectedProjectPhotoAssetId, setSelectedProjectPhotoAssetId] = useState<string>("");
  const [profileLinkConfig, setProfileLinkConfig] = useState<ProfileLinkConfig | null>(null);
  const [currentProfileSideProjects, setCurrentProfileSideProjects] = useState<SideProject[]>([]);
  const [profileContactDetails, setProfileContactDetails] = useState<ProfileContactDetails | null>(
    null
  );
  const [profile, setProfile] = useState<Profile>({
    id: "",
    profile_name: "",
    name: "",
    title: "",
    pitch: "",
    ui_config: { dark_mode: false, use_profile_picture: true },
    roles_section: { order: 0, title: "", roles: [] },
    skills_section: { order: 0, title: "", skill_blocks: [] },
    side_projects_section: { order: 0, title: "", side_projects: [] },
    profile_tags: [],
    workplaces: [],
  });

  const isMobile = useMediaQuery({ query: "(max-width: 760px)" });

  useEffect(() => {
    if (profileLinkId !== undefined) {
      fetchProfileFromLinkId(profileLinkId).then((profileLinkResponseBody) => {
        const accountProfile = profileLinkResponseBody.accountProfile;
        setProfileContactDetails(profileLinkResponseBody.profileContactDetails);
        setProfile(accountProfile.profile);
        setIsOwner(accountProfile.isOwner);
        setProfilePhotoId(accountProfile.profilePhotoId ?? "");
        setLinkCreatorProfileId(accountProfile.linkCreatedByProfileId ?? "");
        setIsCreator(accountProfile.isCreator ?? false);
        setProfileLink(profileLinkId);
        setAccountProfile(accountProfile);
        setIsManager(accountProfile.isManager ?? false);
        if (accountProfile.linkConfig) {
          setDarkModeActive(accountProfile.linkConfig.darkMode);
          setProfileLinkConfig(accountProfile.linkConfig);
          if (accountProfile.linkConfig.dynamic) {
            if (localStorage.getItem("op-guest-user-id") != null) {
              navigate(`/links/${profileLinkId}?dynamic=true`);
            } else {
              createGuestUser().then((guestUserId) => {
                localStorage.setItem("op-guest-user-id", guestUserId);
                navigate(`/links/${profileLinkId}?dynamic=true`);
              });
            }
          }
        }

        const sortedSideProjects =
          accountProfile.profile.side_projects_section?.side_projects?.sort(
            (a: SideProject, b: SideProject) => {
              return a.order - b.order;
            }
          ) || [];

        setCurrentProfileSideProjects(sortedSideProjects);

        if (!accountProfile.isOwner && !accountProfile.isCreator && !isManager) {
          updateProfileVisit("", profileLinkId);
        }
      });
    }
  }, []);

  function parseJwt(token: string | null) {
    if (token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      );

      return JSON.parse(jsonPayload);
    }
  }

  function getAssetUrl(branding: Branding | null) {
    if (branding === null) return null;
    if (branding.logotypes.length > 0) {
      return branding.logotypes[0].assetUrl;
    }
    return null;
  }

  function getAssetSize(branding: Branding | null) {
    if (branding === null) return null;
    if (branding.logotypes.length > 0) {
      return branding.logotypes[0].size;
    }
    return null;
  }

  const getSectionsInOrder = () => {
    const availableSections: SectionTypeDescription[] = [];
    if (profile.roles_section !== null && profile.roles_section !== undefined) {
      const role: SectionTypeDescription = {
        title: "Work Experience",
        info: "Describe your previous roles and workplaces",
        type: "roles",
        order: profile.roles_section!.order,
      };
      availableSections.push(role);
    }

    if (profile.skills_section !== null && profile.skills_section !== undefined) {
      const skills: SectionTypeDescription = {
        title: "Skills",
        info: "Express your skills",
        type: "skills",
        order: profile.skills_section!.order,
      };
      availableSections.push(skills);
    }

    if (
      profile.side_projects_section !== null &&
      profile.side_projects_section !== undefined &&
      profile.side_projects_section.side_projects !== null &&
      profile.side_projects_section.side_projects.length > 0
    ) {
      const skills: SectionTypeDescription = {
        title: "Projects",
        info: "Add any other project that does not fit under work experience",
        type: "side-projects",
        order: profile.side_projects_section!.order,
      };
      availableSections.push(skills);
    }

    const sectionsInOrder = availableSections.sort((a, b) => a.order - b.order);
    return sectionsInOrder;
  };

  if (profileLinkConfig === null) {
    return (
      <div className="flex flex-1 h-[800px] flex-col justify-center align-center ">
        <div>
          <Lottie style={{ width: 80 }} options={{ animationData: animationData }} />
          <label className="font-bold">Loading profile...</label>
        </div>
      </div>
    );
  }
  const linkConfig = profileLinkConfig;
  const logoAssetUrl = getAssetUrl(linkConfig.branding || null);

  return (
    <div
      id="wrapper"
      style={{
        color: darkModeActive ? "white" : darkModeBlack,
        backgroundColor: darkModeActive ? darkModeBlack : "#fff",
      }}
    >
      {localStorage.getItem("profiletool-auth-token") ? (
        <div
          style={{
            borderBottom: darkModeActive ? "1px solid #fff" : "1px solid #0A1414",
          }}
        ></div>
      ) : null}
      {isOwner || isCreator || isManager ? (
        <NavigationHeader
          backButtonText={isOwner ? "Dashboard" : profile.name + "´s dashboard"}
          backButtonPath="/controlboard"
          onBackButtonClick={() => {
            const currentProfileId = isOwner
              ? localStorage.getItem("current-profile-id")
              : profile.id;
            navigate("/profiles/" + currentProfileId);
          }}
        >
          {isOwner || isCreator ? (
            <Button
              text="Edit link"
              onButtonClick={() => {
                if (!isOwner && isCreator) {
                  navigate(`/manager/${linkCreatorProfileId}/links/${profileLink}`);
                } else {
                  navigate(`/links/${profileLink}`);
                }
              }}
              style={ButtonStyleType.secondary}
            />
          ) : null}

          <div className="min-w-[20px]"></div>
          <Button
            text="Copy link!"
            onButtonClick={() => {
              const baseUrl = process.env.REACT_APP_SITE_URL
                ? String(process.env.REACT_APP_SITE_URL)
                : "https://oneprofile.dev";
              navigator.clipboard.writeText(baseUrl + "/p/" + profileLink).then(
                () => {
                  /* clipboard successfully set */
                },
                () => {
                  /* clipboard write failed */
                }
              );
            }}
            style={ButtonStyleType.action}
          />
        </NavigationHeader>
      ) : localStorage.getItem("profile-auth-token") != null ? (
        <NavigationHeader
          backButtonText="Back"
          backButtonPath=""
          onBackButtonClick={() => window.history.back()}
        ></NavigationHeader>
      ) : null}
      {document.URL.indexOf("testLink=true") > -1 ? (
        <NavigationHeader backButtonText="Back" backButtonPath="/">
          <Button
            text="Create your own profile"
            onButtonClick={() => {
              navigate(`/auth`);
            }}
            style={ButtonStyleType.secondary}
          />
        </NavigationHeader>
      ) : null}
      <motion.div
        id="profile-viewer-container"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.7 }}
      >
        <div className="profile-viewer-content">
          <PersonalDetailsSection
            isDarkModeActive={linkConfig.darkMode}
            profile={profile}
            isEditable={false}
            useProfilePicture={linkConfig.useProfilePicture}
            roundedProfilePicture={linkConfig.profileLinkPersonalInfoConfig.roundMode}
            profilePhotoId={profilePhotoId}
            onShowCropTool={() => {}}
            onProfileUpdate={() => {}}
            onAddProfileTagClick={() => {}}
            onProfileTagsUpdated={() => {}}
            hidePitch={!linkConfig.profileLinkPersonalInfoConfig.showPitch}
            logoAssetUrl={getAssetUrl(linkConfig.branding || null)}
            logoAssetSize={getAssetSize(linkConfig.branding || null)}
            isUploadingPhoto={false}
            additionalPitches={
              linkConfig.showCustomPitch === true && linkConfig.customPitch !== ""
                ? [linkConfig.customPitch]
                : []
            }
          />
          {linkConfig.profileLinkWorkplaceIds ? (
            linkConfig.profileLinkWorkplaceIds.length > 0 ? (
              <div className="mt-6">
                <EditorTitle title="Work experience" />
              </div>
            ) : null
          ) : null}

          <WorkplaceSection
            key="workplace-section"
            configurable={false}
            workplaces={
              profile.workplaces != undefined
                ? profile.workplaces.filter((wp) => {
                    if (!linkConfig.profileLinkWorkplaceIds) {
                      return false;
                    }
                    return linkConfig.profileLinkWorkplaceIds.indexOf(wp.id) > -1;
                  })
                : []
            }
            sideProjectSection={profile.side_projects_section!}
            isDarkModeActive={darkModeActive}
            sectionCount={getSectionsInOrder().length}
            isEditable={false}
            onAddItem={() => {}}
            onUpdateItem={() => {}}
            onRemoveItem={() => {}}
            onDeleteSectionClick={() => {}}
            onShowDialog={() => {}}
            onProjectOrderChange={() => {}}
            sideProjects={profile.side_projects_section?.side_projects}
            workplaceLinkConfig={linkConfig.workplaceLinkConfig}
          />
          {linkConfig.profileLinkProjects ? (
            linkConfig.profileLinkProjects.length > 0 ? (
              <div className="mt-6">
                <EditorTitle title="Project" />
              </div>
            ) : null
          ) : null}

          <SideProjectSection
            configurable={false}
            sideProjectSection={{
              ...profile.side_projects_section!,
              side_projects: currentProfileSideProjects,
            }}
            isDarkModeActive={darkModeActive}
            sectionCount={0}
            isEditable={false}
            workplaces={profile.workplaces}
            onAddSideProject={(newSideProject) => {}}
            onUpdateSideProject={(updatedSideProject) => {}}
            onRemoveSideProject={(sideProjectToRemove) => {}}
            onDeleteSectionClick={() => {}}
            projectPhotos={accountProfile?.projectPhotos ?? []}
            onShowDialog={() => {}}
            onProjectOrderChange={(projects: SideProject[]) => {
              setCurrentProfileSideProjects(projects);
            }}
            onProjectToggle={(project: SideProject, toggled: boolean) => {
              const config = Object.assign({}, profileLinkConfig);
              if (toggled) {
                const indexOfProject = currentProfileSideProjects.indexOf(project);

                setProfileLinkConfig({
                  ...config,
                  profileLinkProjects: [
                    ...config.profileLinkProjects,
                    { projectId: project.id, order: indexOfProject },
                  ],
                });
              } else {
                const newProjects = config.profileLinkProjects.filter(
                  (p) => p.projectId !== project.id
                );
                setProfileLinkConfig({
                  ...config,
                  profileLinkProjects: newProjects,
                });
              }
            }}
            onProjectPhotoSelect={(assetId: string) => {
              setSelectedProjectPhotoAssetId(assetId);
            }}
            projectLinkConfing={linkConfig.projectsLinkConfig}
          />

          {profileLinkConfig.contactUsLinkConfig &&
          profileLinkConfig.contactUsLinkConfig.displayContactUs === true &&
          profileContactDetails !== null ? (
            <div className="pr-8">
              <ContactBox
                editable={false}
                profileContactDetails={profileContactDetails}
                onUpdatePhone={() => {}}
                phone={profileLinkConfig.contactUsLinkConfig.phone}
              />
            </div>
          ) : null}

          <div className="flex justify-center mt-10 mb-4">
            {logoAssetUrl && isMobile ? (
              <img
                src={
                  logoAssetUrl.length > 100
                    ? logoAssetUrl
                    : "https://pub-4b15203a4a0f4b26ab71deeffce0164a.r2.dev/" + logoAssetUrl
                }
                className={`object-scale-contain`}
                style={{
                  maxHeight: getAssetSize(linkConfig.branding || null) ?? 40,
                }}
              />
            ) : null}
          </div>
        </div>
      </motion.div>
      {selectedProjectPhotoAssetId !== "" ? (
        <Dialog
          fullWidth
          fullScreen={viewDialogFullScreen}
          open={selectedProjectPhotoAssetId !== ""}
          onBackdropClick={() => {
            setSelectedProjectPhotoAssetId("");
            setViewDialogFullScreen(false);
          }}
        >
          <ProjectPhotoViewer
            projectPhotos={accountProfile!.projectPhotos}
            onToggleFullScreen={(toggleFullSceenOn: boolean) => {
              setViewDialogFullScreen(toggleFullSceenOn);
            }}
            isEditable={false}
            assetId={selectedProjectPhotoAssetId}
            onProjectPhotosUpdate={(updatedProjectPhotos: ProjectPhoto[]) => {
              setAccountProfile({
                ...accountProfile!,
                projectPhotos: updatedProjectPhotos,
              });
            }}
            onClose={() => {
              setSelectedProjectPhotoAssetId("");
              setViewDialogFullScreen(false);
            }}
          />
        </Dialog>
      ) : null}
    </div>
  );
}

export default ProfileViewer;
