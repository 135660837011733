import { queryOptions, useQuery } from "@tanstack/react-query";
import { getV1OrganizationsByOrganizationIdClientsOptions } from "../../../client/@tanstack/react-query.gen";
import type { QueryConfig } from "../../utils/query-config";

export const getClientsOptions = (organizationID: string) =>
  queryOptions({
    ...getV1OrganizationsByOrganizationIdClientsOptions({
      path: { organizationID },
    }),
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

export const useGetClients = (organizationID: string) => {
  return useQuery(getClientsOptions(organizationID));
};

type Input = {
  organizationID: string;
  clientId: string;
};

export const useGetClient = ({ organizationID, clientId }: Input, config?: QueryConfig) => {
  return useQuery({
    ...getClientsOptions(organizationID),
    ...config,
    select: (data) => data.find((client) => client.id === clientId),
  });
};
