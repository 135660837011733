import type { ComponentProps } from "react";
import { ImageButton } from "../buttons";
import { Link, useMatch } from "react-router-dom";
import { scrollToTop } from "./utils";

const ImageButtonLink = ({
  href,
  ...props
}: ComponentProps<typeof ImageButton> & {
  href: string;
}) => {
  const match = useMatch(href);
  const isMatch = Boolean(match);
  return (
    <Link to={href}>
      <ImageButton active={isMatch} onClick={isMatch ? scrollToTop : undefined} {...props} />
    </Link>
  );
};

ImageButtonLink.displayName = "ImageButtonLink";

export { ImageButtonLink };
