import { colors } from "@material-ui/core";
import { dark } from "../colors";
import { useState } from "react";
import type { ProfileComponentProps } from "../types";

interface LinkButtonProps extends ProfileComponentProps {
  title: string;
  handleClick: () => void;
  white?: boolean;
  fontSize?: number;
  viewStyle?: React.CSSProperties;
}

export default function LinkButton({
  title,
  handleClick,
  isDarkModeActive,
  white,
  fontSize,
  viewStyle,
}: LinkButtonProps) {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  return (
    <button
      className="link-button"
      onClick={handleClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={{
        color: white ? "#fff" : dark,
        fontSize: fontSize ?? 18,
        fontWeight: !isHovering ? "bold" : 900,
        ...(viewStyle ?? {}),
      }}
    >
      {title}
    </button>
  );
}
