import { useGetClient, useGetLead, useUpdateLead } from "../../../../services";
import { homeState } from "../../../../state/home";
import type { LeadRowActiveState } from "../../../../state/leads";
import { LeadAssignmentButton } from "./LeadAssignmentButton";
import { LeadClientButton } from "./LeadClientButton";
import { LeadMemberButton } from "./LeadMemberButton";
import { LeadQRButton } from "./LeadQRButton";

interface Props {
  organizationId: string;
  leadId?: string;
  state: LeadRowActiveState;
}

export const LeadDrawerMenu = ({ organizationId, leadId, state }: Props) => {
  const updateLead = useUpdateLead();

  const { data: lead } = useGetLead(
    { leadID: leadId ?? "", organizationID: organizationId },
    { enabled: !!leadId }
  );

  const { data: client } = useGetClient({
    clientId: updateLead.variables?.body.clientId ?? lead?.clientId ?? "",
    organizationID: organizationId,
  });

  const leadProfileId = updateLead.variables?.body.profileId ?? lead?.profileId ?? undefined;

  return (
    <div className="grid grid-cols-2 gap-2">
      <LeadClientButton
        active={state === "client"}
        onClick={() => {
          homeState.selectedLead = {
            leadId,
            state: "client",
          };
        }}
        client={client}
        contactPersonEmail={lead?.contactPersonEmail}
        className="px-4"
      />
      <LeadAssignmentButton
        active={state === "assignment"}
        onClick={() => {
          homeState.selectedLead = {
            leadId,
            state: "assignment",
          };
        }}
        title={lead?.title || lead?.assignmentInfo?.title}
        className="px-4"
      />
      <LeadMemberButton
        active={state === "member"}
        onClick={() => {
          homeState.selectedLead = {
            leadId,
            state: "member",
          };
        }}
        profileId={leadProfileId}
        organizationId={organizationId}
        className="px-4"
      />
      <LeadQRButton
        active={state === "qr"}
        onClick={() => {
          homeState.selectedLead = {
            leadId,
            state: "qr",
          };
        }}
        profileLinkId={lead?.profileLinkId}
        className="px-4"
      />
    </div>
  );
};
