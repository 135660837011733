import {
  getYears,
  getSelectedMonthValue,
  getSelectedYearValue,
  monthNames,
  monthValues,
} from "../utils";
import "./components.css";
import type { FocusEvent } from "react";
interface MonthYearSelectProps {
  firstOption: string;
  selected?: string;
  label: string;
  isEndDateSelector: boolean;
  onYearSelect: (selectedYear: string) => void;
  onMonthSelect: (selectedMonth: string) => void;
}

const years = getYears();

function MonthYearSelect({
  firstOption,
  selected,
  label,
  isEndDateSelector,
  onYearSelect,
  onMonthSelect,
}: MonthYearSelectProps) {
  return (
    <div className="month-year-select">
      <label className="control-label">{label}</label>
      <div style={{ marginTop: "5px" }}>
        <select
          value={getSelectedYearValue(selected ?? "")}
          onChange={(e: FocusEvent<any>) => {
            onYearSelect(e.target.value);
          }}
        >
          <option>{firstOption}</option>

          {years.map((year: string) => {
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </select>
        {isEndDateSelector && selected === undefined ? (
          <span />
        ) : (
          <select
            value={getSelectedMonthValue(selected ?? "")}
            onChange={(e: FocusEvent<any>) => {
              onMonthSelect(e.target.value);
            }}
          >
            {monthNames.map((monthName: string, index: number) => {
              return (
                <option key={monthName} value={monthValues[index]}>
                  {monthName}
                </option>
              );
            })}
          </select>
        )}
      </div>
    </div>
  );
}

export default MonthYearSelect;
