import { useEffect } from "react";
import { proxy, subscribe } from "valtio";

export type LeadRowActiveState = "client" | "assignment" | "member" | "qr" | "events" | false;

type LeadsState = {
  leadRows: Record<string, LeadRowActiveState>;
};

const storageKey = "leads";

export const leadsState = proxy<LeadsState>({
  leadRows: {},
});

export const initLeadsState = () => {
  const value = sessionStorage.getItem(storageKey);
  if (value) Object.assign(leadsState, JSON.parse(value));
};

export const useInitLeadsState = () => useEffect(initLeadsState, []);

export const getNextLeadRowActiveState = (prev: LeadRowActiveState, state: LeadRowActiveState) =>
  prev === state ? false : state;

export const setLeadRowActiveState = (leadId: string, state: LeadRowActiveState) => {
  leadsState.leadRows[leadId] = getNextLeadRowActiveState(leadsState.leadRows[leadId], state);
};

subscribe(leadsState, () => {
  sessionStorage.setItem(storageKey, JSON.stringify(leadsState));
});
