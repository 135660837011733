import type { Options } from "@hey-api/client-fetch";
import { type UseMutationOptions, useMutation, useQueryClient } from "@tanstack/react-query";
import type {
  Lead,
  PostV1OrganizationsByOrganizationIdLeadsData,
  PostV1OrganizationsByOrganizationIdLeadsError,
  PostV1OrganizationsByOrganizationIdLeadsResponse,
} from "../../../client";
import { postV1OrganizationsByOrganizationIdLeadsMutation } from "../../../client/@tanstack/react-query.gen";
import { jsonParse } from "../../utils/parse";
import { getLeadsQueryKey } from "./utils";

const mutationKey = ["createLead"];

export const useCreateLead = (
  options: UseMutationOptions<
    PostV1OrganizationsByOrganizationIdLeadsResponse,
    PostV1OrganizationsByOrganizationIdLeadsError,
    Options<PostV1OrganizationsByOrganizationIdLeadsData>
  > = {}
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...postV1OrganizationsByOrganizationIdLeadsMutation(),
    mutationKey,
    ...options,
    onSuccess: (lead, vars, context) => {
      queryClient.setQueryData(getLeadsQueryKey(vars.path.organizationID), (oldData) => {
        const newLead = jsonParse<Lead>(lead);
        if (!oldData) return [newLead];
        return [...oldData, newLead];
      });

      options?.onSuccess?.(lead, vars, context);
    },
    onSettled: (data, error, vars, context) => {
      queryClient.invalidateQueries({
        queryKey: getLeadsQueryKey(vars.path.organizationID),
      });

      options?.onSettled?.(data, error, vars, context);
    },
  });
};
