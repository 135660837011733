import { type PropsWithChildren, forwardRef } from "react";
import { cn } from "../../../../components/utils/cn";

export type LeadButtonProps = PropsWithChildren<{
  onClick?: () => void;
  className?: string;
  active?: boolean;
}>;

export const LeadButton = forwardRef<HTMLButtonElement, LeadButtonProps>(
  ({ onClick, children, className, active }, ref) => (
    <button
      type="button"
      onClick={onClick}
      ref={ref}
      className={cn(
        "py-4 lg:px-4 h-[4.5rem] flex gap-2 items-center text-sm text-black lg:hover:bg-gray-2 active:lg:bg-gray-3 transition-all duration-200 rounded-2xl self-stretch lg:shrink-0 text-left overflow-hidden active:scale-95",
        active && "bg-gray-3 hover:bg-gray-3",
        className
      )}
    >
      {children}
    </button>
  )
);

LeadButton.displayName = "LeadButton";
