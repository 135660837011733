import type { Options } from "@hey-api/client-fetch";
import { type UseMutationOptions, useMutation, useQueryClient } from "@tanstack/react-query";
import type {
  Lead,
  PutV1OrganizationsByOrganizationIdLeadsByLeadIdData,
  PutV1OrganizationsByOrganizationIdLeadsByLeadIdError,
  PutV1OrganizationsByOrganizationIdLeadsByLeadIdResponse,
} from "../../../client";
import { putV1OrganizationsByOrganizationIdLeadsByLeadIdMutation } from "../../../client/@tanstack/react-query.gen";
import { jsonParse } from "../../utils/parse";
import { getLeadsQueryKey } from "./utils";

const mutationKey = ["updateLead"];

export const useUpdateLead = (
  options: UseMutationOptions<
    PutV1OrganizationsByOrganizationIdLeadsByLeadIdResponse,
    PutV1OrganizationsByOrganizationIdLeadsByLeadIdError,
    Options<PutV1OrganizationsByOrganizationIdLeadsByLeadIdData>
  > = {}
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...putV1OrganizationsByOrganizationIdLeadsByLeadIdMutation(),
    mutationKey,
    ...options,
    onSuccess: (lead, vars, context) => {
      // Update the cache to include the new lead
      queryClient.setQueryData(getLeadsQueryKey(vars.path.organizationID), (oldData) => {
        const newLead = jsonParse<Lead>(lead);
        if (!oldData) return [newLead];
        const index = oldData.findIndex((item) => item.id === newLead.id);
        if (index === -1) return [...oldData, newLead];
        return [...oldData.slice(0, index), newLead, ...oldData.slice(index + 1)];
      });

      options?.onSuccess?.(lead, vars, context);
    },
    onSettled: (data, error, vars, context) => {
      // Invalidate the query to ensure that the changes are reflected in the UI
      queryClient.invalidateQueries({
        queryKey: getLeadsQueryKey(vars.path.organizationID),
      });

      options?.onSettled?.(data, error, vars, context);
    },
  });
};
