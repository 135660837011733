import type { ComponentProps } from "react";
import { Link, useMatch } from "react-router-dom";
import { IconButton } from "../buttons";
import { scrollToTop } from "./utils";

const IconButtonLink = ({
  href,
  ...props
}: ComponentProps<typeof IconButton> & {
  href: string;
}) => {
  const match = useMatch(href);
  const isMatch = Boolean(match);
  return (
    <Link to={href}>
      <IconButton active={isMatch} onClick={isMatch ? scrollToTop : undefined} {...props} />
    </Link>
  );
};

IconButtonLink.displayName = "IconButtonLink";

export { IconButtonLink };
