import type { ComponentProps } from "react";
import defaultUserImage from "../../assets/op_o.png";
import { useGetProfile } from "../../services";
import { removeMemberTab } from "../../state/tabs";
import { getImageUrl } from "../../utils/image";
import { IndicatorImageButtonLink } from "./IndicatorImageButtonLink";
import { getBaseUrl } from "./utils";

interface Props extends Partial<ComponentProps<typeof IndicatorImageButtonLink>> {
  organizationId: string;
  memberId: string;
}

export const MemberButtonLink = ({ organizationId, memberId, ...props }: Props) => {
  const baseUrl = getBaseUrl(organizationId);
  const { data, isSuccess } = useGetProfile({
    organizationId,
    profileId: memberId,
  });

  return (
    <IndicatorImageButtonLink
      href={`${baseUrl}/members/${memberId}`}
      imageUrl={
        data?.profilePhotoId
          ? getImageUrl(data.profilePhotoId)
          : isSuccess
            ? defaultUserImage
            : undefined
      }
      alt={typeof data?.profileData.name === "string" ? data.profileData.name : "Profile"}
      onClose={() => removeMemberTab(memberId)}
      {...props}
    />
  );
};

MemberButtonLink.displayName = "MemberButtonLink";
