import type React from "react";
import { useState } from "react";
import withCustomStyling from "./HOC/withCustomStyling";
import type { ResourceComment } from "../types";
import IconTextArea from "./IconTextArea";
import { MessageCircle, Send } from "react-feather";
import Button, { ButtonStyleType } from "./atoms/Button";
import { createComment, deleteComment } from "../requests";
import AvatarImage from "./AvatarImage";
import RowMenu, { RowMenuAction } from "./RowMenu";
import { useCurrentProfileId } from "../hooks/hooks";

interface ResourceCommentProps {
  resourceComment: ResourceComment | null;
  onCommentSaveClick: (comment: ResourceComment) => void;
  onCommentRemoved: (comment: ResourceComment) => void;
}

const ResourceCommentRow: React.FC<ResourceCommentProps> = ({
  resourceComment,
  onCommentSaveClick,
  onCommentRemoved,
}) => {
  const currentProfileId = useCurrentProfileId();

  const formatDateString = (dateString: string) => {
    const date = new Date(dateString);

    // Options for formatting the date and time
    const options: Intl.DateTimeFormatOptions = {
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };

    // Format the date
    const formattedDate = date.toLocaleDateString("sv-SE", options);
    return formattedDate;
  };

  const [commentInput, setCommentInput] = useState<string>("");
  const [editMode, setEditMode] = useState<boolean>(false);
  if (resourceComment === null) {
    return editMode ? (
      <div className="max-w-[50%] pb-4 ">
        <IconTextArea
          value={commentInput}
          onChange={(event) => {
            setCommentInput(event.target.value);
          }}
          placeholder="Add a comment to the lead..."
          icon={<MessageCircle />}
          onEnterPress={() => {
            if (resourceComment === null) {
              const newResourceComment: ResourceComment = {
                comment: commentInput,
                id: "",
                resourceId: "",
                commenterProfileId: "",
                createdAt: "2006-01-02T15:04:05Z", //dummy date
              };
              onCommentSaveClick(newResourceComment);
              setCommentInput("");
              setEditMode(false);
            }
          }}
        />
        <label className="text-xs text-gray-400">Press enter to submit your comment</label>
      </div>
    ) : (
      <Button
        text="Add comment"
        style={ButtonStyleType.link}
        onButtonClick={() => setEditMode(true)}
      />
    );
  }
  return (
    <div>
      {resourceComment.commenterUserProfile ? (
        <div className="flex flex-row justify-between">
          <div className="flex flex-row">
            <AvatarImage photoId={resourceComment.commenterUserProfile.profilePhotoId} small />
            <div className="text-sm ml-2">
              <h6 className="font-semibold">
                {resourceComment.commenterUserProfile.profileData.name + " commented"}
              </h6>
              <p>{resourceComment.comment}</p>
            </div>
          </div>
          <div className="flex flex-row align-center items-center">
            <div className="text-sm">{formatDateString(resourceComment.createdAt)}</div>
            <div>
              {resourceComment.commenterProfileId === currentProfileId! ? (
                <RowMenu
                  availableActions={[RowMenuAction.DeleteComment]}
                  onActionClick={(action: RowMenuAction) => {
                    switch (action) {
                      case RowMenuAction.DeleteComment:
                        deleteComment(resourceComment.id).then(() => {
                          onCommentRemoved(resourceComment);
                        });
                        break;
                      default:
                        console.log("RowMenuAction not recognized");
                    }
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default withCustomStyling(ResourceCommentRow);
