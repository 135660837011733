import type { ProfileComponentProps } from "../../types";
import "../components.css";

interface Props extends ProfileComponentProps {
  text: string;
  handleClick: () => void;
  disableEditing?: boolean;
  centerText?: boolean;
  white?: boolean;
}

const EditorTextButton = (props: Props) => {
  var classNames = "editor-text-button";
  if (props.isDarkModeActive) {
    classNames += " editor-text-button-light light-color";
  }
  if (props.disableEditing) {
    classNames += " no-hover";
  }

  if (props.white) {
    classNames += " text-op-white hover:border-op-white";
  } else {
    classNames += " text-op-dark hover:border-op-dark";
  }

  if (props.centerText) {
    classNames += " align-center";
  }
  return (
    <div className={classNames} onClick={props.handleClick}>
      <label>{props.text}</label>
    </div>
  );
};

export default EditorTextButton;
