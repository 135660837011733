import { useEffect, useState, useRef } from "react";
import TextField from "../components/TextField";
import { useNavigate } from "react-router-dom";
import type { Account } from "../types";

import { fetchAccount, updateAccount } from "../requests";
import type { UserState } from "../userReducer";
import SiteMenu from "../components/SiteMenu";

function AccountSettings() {
  const navigate = useNavigate();
  const infoBoxRef = useRef<HTMLDivElement>(null);
  const [emailInput, setEmailInput] = useState<string>("");
  const [account, setAccount] = useState<Account | null>(null);

  useEffect(() => {
    fetchAccount()
      .then((userState: UserState) => {
        setAccount(userState.account);
        setEmailInput(userState.account!.email);
      })
      .catch((error) => {
        localStorage.removeItem("profiletool-auth-token");
        navigate("/auth");
      });
  }, []);

  const showSavedInfo = () => {
    if (infoBoxRef.current) {
      infoBoxRef.current.classList.add("info-box-show-animation");
      infoBoxRef.current.classList.remove("info-box-hide-animation");
      setTimeout(() => {
        if (infoBoxRef.current) {
          infoBoxRef.current.classList.add("info-box-hide-animation");
          infoBoxRef.current.classList.remove("info-box-show-animation");
        }
      }, 2000);
    }
  };

  if (account === null) {
    return (
      <div>
        <label>Fetching profile...</label>
      </div>
    );
  }

  if (account) {
    return (
      <div>
        <SiteMenu currentPath="account" isSignedIn={true} />
        <div style={{ paddingLeft: "50px", paddingRight: "50px" }}>
          <h4>Update profile settings</h4>

          <div style={{ width: "400px" }}>
            <label>Email</label>
            <TextField
              isDarkModeActive={false}
              placeholder="Your full name"
              onChange={(input) => setEmailInput(input)}
              onLeaveField={() => {}}
              inputStyles={{ padding: 25 }}
              value={emailInput}
            />
            <button
              style={{ marginTop: "30px" }}
              className="primary-button"
              onClick={() => {
                updateAccount(emailInput).then(() => {
                  showSavedInfo();
                });
              }}
            >
              Update settings
            </button>
            <button
              style={{ marginTop: "30px", marginLeft: "50px" }}
              className="secondary-button"
              onClick={() => {
                localStorage.removeItem("profiletool-auth-token");
                navigate("/auth");
              }}
            >
              Sign out
            </button>
          </div>
        </div>
        <div
          ref={infoBoxRef}
          className="info-box-hidden"
          style={{
            width: "300px",
            height: "100px",
            position: "absolute",
            bottom: "20px",
            right: "20px",
            borderRadius: "5px",
          }}
        >
          <label>Profile updated</label>
        </div>
      </div>
    );
  }
}

export default AccountSettings;
