import React, { useState } from "react";
import { X, XCircle } from "react-feather";
import DefaultIcon from "../assets/op_o.png";
import ProfileImage from "./ProfileImage";
import withCustomStyling from "./HOC/withCustomStyling";

interface Props {
  onClick: () => void;
  photoAssetId?: string;
  removeable?: boolean;
  size?: number;
  onRemoveClick?: () => void;
  isSelected?: boolean;
  inEditor: boolean;
}

function ProfileTab(props: Props) {
  const getSize = () => {
    const size = props.size ?? 70;

    return size;
  };

  const getSizeString = () => {
    return "max-h-[" + getSize() + "px]" + " max-w-[" + getSize() + "px]";
  };

  return (
    <div
      className={
        "bg-op-transparent rounded-xl flex items-center justify-center relative hover:bg-gray-200 " +
        getSizeString() +
        (props.isSelected ? "" : " p-2")
      }
      onClick={(e) => {
        e.stopPropagation();
        props.onClick();
      }}
    >
      <div className={"rounded-xl overflow-hidden"}>
        <ProfileImage photoId={props.photoAssetId} size={getSize()} />
      </div>

      {props.removeable ? (
        <div
          className={
            "absolute bg-gray-200 " +
            (props.isSelected ? "top-0 right-0" : "top-1 right-1") +
            (props.inEditor ? " p-[2px]" : " hover:p-[1px] hover:bg-gray-400")
          }
          onClick={(e) => {
            if (props.inEditor) {
              return;
            }
            e.stopPropagation();
            if (props.onRemoveClick) {
              props.onRemoveClick();
            }
          }}
        >
          {props.inEditor ? (
            <div className="bg-op-blue rounded-lg" style={{ minWidth: 12, minHeight: 12 }} />
          ) : (
            <X color="black" size={12} />
          )}
        </div>
      ) : null}
    </div>
  );
}

export default withCustomStyling(ProfileTab);
