import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { AccountProfile, AdminOverview } from "../types";
import { getAdminView } from "../requests";
import LogoDefault from "../assets/profile-default.png";

import Avatar from "../components/AvatarOld";
import NavigationHeader from "../components/NavigationHeader";

function Admin() {
  const navigate = useNavigate();
  const [adminOverview, setAdminOverview] = useState<AdminOverview | null>(null);
  const [accountProfiles, setAccountProfiles] = useState<AccountProfile[]>([]);
  function createData(email: string, linkId: string) {
    return { email, linkId };
  }

  useEffect(() => {
    getAdminView()
      .then((adminOverview: AdminOverview) => {
        setAdminOverview(adminOverview);
      })
      .catch((err) => {
        navigate("/landing");
        console.log(err);
      });
  }, []);

  if (adminOverview === null) {
    return <div />;
  }

  return (
    <div>
      <NavigationHeader backButtonPath="/controlboard" backButtonText="Back" />
      <div className="container mx-auto px-[50px] pt-2 pb-10">
        <div className="flex flex-1 flex-col">
          {adminOverview?.organizations.map((org, index) => {
            return (
              <div
                className="flex flex-1  items-center mb-1"
                key={index}
                onClick={() => navigate("/organizations/" + org.id)}
              >
                <div className="min-w-[60px] max-w-[60px">
                  <Avatar
                    isEditable={false}
                    onShowCropTool={() => {}}
                    imageString={
                      org.logoId !== ""
                        ? "https://pub-4b15203a4a0f4b26ab71deeffce0164a.r2.dev/" + org.logoId
                        : LogoDefault
                    }
                    roundMode={true}
                  />
                </div>
                <label>{org.name}</label>
              </div>
            );
          })}
        </div>
        <div className="flex flex-row mb-2 flex-1 px-2 font-semibold min-h-[40px] max-h-[70px] justify-between items-center border-b-[1px] border-b-op-dark">
          <div className="flex-1">
            <h6>Name</h6>
          </div>
          <div className="flex-1">
            <h6>Email</h6>
          </div>
          <div className="flex-1">
            <h6>Title</h6>
          </div>
          <div className="flex-1">
            <h6>Project count</h6>
          </div>
          <div className="flex-1 text-right">
            <h6>Link count</h6>
          </div>
        </div>
        {adminOverview.adminViewProfileSummaries.map((profileSummary, index) => {
          return (
            <div
              className="flex flex-row flex-1 px-2 py-2 min-h-[70px] max-h-[70px] justify-between items-center border-b-[1px] border-b-op-gray cursor-pointer hover:bg-gray-200 text-sm"
              onClick={() => {
                navigate("/admin/profiles/" + profileSummary.profileId);
              }}
              key={index}
            >
              <div className="flex-1 flex items-center">
                <div className="min-w-[60px] max-w-[60px]">
                  <Avatar
                    isEditable={false}
                    onShowCropTool={() => {}}
                    imageString={
                      profileSummary.profilePhotoId !== ""
                        ? "https://pub-4b15203a4a0f4b26ab71deeffce0164a.r2.dev/" +
                          profileSummary.profilePhotoId
                        : LogoDefault
                    }
                    roundMode={true}
                  />
                </div>
                <div className="flex flex-1 flex-col ">
                  <p>{profileSummary.profile.name}</p>
                  <p className="text-op-blue font-semibold">
                    {profileSummary.isManager ? "Manager" : ""}
                  </p>
                </div>
              </div>
              <div className="flex-1">
                <p>{profileSummary.email}</p>
              </div>
              <div className="flex-1">
                <p>{profileSummary.profile.title}</p>
              </div>
              <div className="flex-1">
                <p>
                  {profileSummary.profile.side_projects_section != null
                    ? profileSummary.profile.side_projects_section!.side_projects.length
                    : "0"}
                </p>
              </div>
              <div className="flex-1 text-right">
                <p>{profileSummary.linkCount - 1}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Admin;
