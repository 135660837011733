import type { ProfileComponentProps } from "../types";
import "./components.css";
import React, { type ReactNode } from "react";

interface Props extends ProfileComponentProps {
  text: string;
  handleClick: () => void;
  disableEditing?: boolean;
  centerText?: boolean;
  white?: boolean;
  iconChild: ReactNode;
}

const EditorTextIconButton = (props: Props) => {
  var classNames =
    "py-2 pr-2 border-t-[1px] border-r-[1px] border-b-[1px] border-transparent hover:pl-2 hover:border-l-[1px] border-solid inline-flex items-center";
  if (props.isDarkModeActive) {
    classNames += " editor-text-button-light light-color";
  }
  if (props.disableEditing) {
    classNames += " no-hover";
  }

  if (props.white) {
    classNames += " text-op-white hover:border-op-white";
  } else {
    classNames += " text-op-dark hover:border-op-dark";
  }

  if (props.centerText) {
    classNames += " align-center";
  }
  return (
    <div className={classNames} onClick={props.handleClick}>
      {props.iconChild}
      <label className="ml-2">{props.text}</label>
    </div>
  );
};

export default EditorTextIconButton;
