import { proxy } from "valtio";
import type { LeadRowActiveState } from "./leads";

type SelectedLead = {
  leadId?: string;
  state: LeadRowActiveState;
};

export const homeState = proxy<{
  selectedLead: SelectedLead | null;
  createNewLeadState: LeadRowActiveState;
}>({
  selectedLead: null,
  createNewLeadState: false,
});
