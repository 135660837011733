import type { QueryClient } from "@tanstack/react-query";
import { type LoaderFunctionArgs, defer } from "react-router-dom";
import { getClientsOptions, getOrganizationOptions, getUserOptions } from "../../../services";

export const buildLayoutLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    if (!params.organizationId) throw new Error("Organization ID is required");

    return defer({
      organization: queryClient.ensureQueryData(getOrganizationOptions(params.organizationId)),
      clients: queryClient.ensureQueryData(getClientsOptions(params.organizationId)),
      user: queryClient.ensureQueryData(getUserOptions()),
    });
  };
