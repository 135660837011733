import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { useParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHandle,
  DrawerTitle,
  Notice,
  Section,
  SectionTitle,
} from "../../../components";
import { useAnimate } from "../../../hooks";
import { useGetLeads } from "../../../services";
import { homeState } from "../../../state/home";
import {
  type LeadRowActiveState,
  getNextLeadRowActiveState,
  useInitLeadsState,
} from "../../../state/leads";
import { ExistingLeadRow } from "./components/ExistingLeadRow";
import { LeadDrawerMenu } from "./components/LeadDrawerMenu";
import { LeadPanelContent } from "./components/LeadPanelContent";
import { LeadRow } from "./components/LeadRow";

const Component = () => {
  const { organizationId } = useParams();
  if (!organizationId) throw new Error("Organization ID is required");

  const { data, isLoading } = useGetLeads(organizationId);

  const [parent] = useAnimate();

  const { selectedLead, createNewLeadState } = useSnapshot(homeState);

  useInitLeadsState();

  // TODO: Add loading state
  if (isLoading) return <p>Loading...</p>;
  if (!data) return <p>No data</p>;

  const handleClose = () => {
    homeState.selectedLead = null;
  };

  const toggleCreateNewLeadState = (state: LeadRowActiveState) => {
    homeState.createNewLeadState = getNextLeadRowActiveState(createNewLeadState, state);
  };

  return (
    <Drawer
      direction="bottom"
      open={!!selectedLead}
      onClose={handleClose}
      shouldScaleBackground={false}
    >
      <Section title="Leads" className="lg:overflow-x-scroll" containerComponent="ul" ref={parent}>
        {data.map((lead) => (
          <ExistingLeadRow key={lead.id} organizationId={organizationId} lead={lead} />
        ))}

        <SectionTitle>Create new lead</SectionTitle>

        <LeadRow
          organizationId={organizationId}
          activeState={createNewLeadState}
          toggleActiveState={toggleCreateNewLeadState}
        />
      </Section>

      <Notice
        className="bg-transparent"
        label="Contracts and Archived leads can be found on client page"
      />

      <DrawerContent className="h-full max-h-[90vh] gap-2" direction="bottom" onClose={handleClose}>
        <DrawerHandle />
        <VisuallyHidden.Root>
          <DrawerTitle>Lead</DrawerTitle>
          <DrawerDescription>Information about the lead</DrawerDescription>
        </VisuallyHidden.Root>
        {selectedLead && (
          <div className="p-4 overflow-y-auto">
            <LeadDrawerMenu
              organizationId={organizationId}
              leadId={selectedLead.leadId}
              state={selectedLead.state}
            />
            <LeadPanelContent
              organizationId={organizationId}
              leadId={selectedLead.leadId}
              state={selectedLead.state}
            />
          </div>
        )}
      </DrawerContent>
    </Drawer>
  );
};

Component.displayName = "DashboardHome";

export default Component;
