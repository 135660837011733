import { queryOptions, useQuery } from "@tanstack/react-query";
import type { GetV1CommentsByResourceIdResponse } from "../../../client";
import { getV1CommentsByResourceIdOptions } from "../../../client/@tanstack/react-query.gen";
import { jsonParse } from "../../utils/parse";
import type { QueryConfig } from "../../utils/query-config";

export const getCommentsOptions = (resourceID: string) =>
  queryOptions({
    ...getV1CommentsByResourceIdOptions({
      path: { resourceID },
    }),
  });

export const useGetComments = (resourceID: string, config?: QueryConfig) => {
  return useQuery({
    ...getCommentsOptions(resourceID),
    select: (data) => jsonParse<GetV1CommentsByResourceIdResponse | null>(data),
    ...config,
  });
};
