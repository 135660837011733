import { Image } from "../image";
import { cn } from "../utils/cn";
import { getFirstChar } from "./utils";

interface Props {
  imageUrl?: string;
  alt: string;
  className?: string;
  fallback?: boolean;
  fallbackClassName?: string;
}

const defaultClassName = "w-full h-full rounded-xl";

export const Avatar = ({ imageUrl, alt, className, fallback = true, fallbackClassName }: Props) =>
  imageUrl || !fallback ? (
    <Image src={imageUrl} alt={alt} className={cn(defaultClassName, className)} />
  ) : (
    <div
      className={cn(
        defaultClassName,
        "bg-gradient-to-tr from-red-2 to-red-3 flex items-center justify-center",
        className
      )}
    >
      <span className={cn("text-white text-2xl font-black", fallbackClassName)}>
        {getFirstChar(alt)}
      </span>
    </div>
  );

Avatar.displayName = "Avatar";
