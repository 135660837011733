import { Code } from "react-feather";
import type { ProfileComponentProps } from "../../types";
import "../components.css";
import { iconForName } from "../TechIcons";
import DefaultSkillIcon from "../../assets/defaultSkillIcon.png";
import { useState } from "react";
import { getSkillInfo } from "../../requests";
interface Props extends ProfileComponentProps {
  text: string;
  handleClick: () => void;
  disableEditing?: boolean;
  keepLeftPadding?: boolean;
}

const EditorSkillTagButton = (props: Props) => {
  const [isHovering, setIsHovering] = useState(false);
  const [skillDescription, setSkillDescription] = useState<string | null>(null);

  const renderIcon = () => {
    const iconName = props.isDarkModeActive ? props.text + "-light" : props.text;
    if (props.isDarkModeActive && iconForName(iconName) === null) {
      return iconForName(props.text);
    }

    return iconForName(iconName);
  };

  var classNames = "editor-skill-tag-button";

  if (props.keepLeftPadding) {
    var classNames = "editor-skill-tag-button-left-padding";
  }

  if (props.isDarkModeActive) {
    classNames += " editor-skill-tag-button-light light-color";
  }

  const handleMouseEnter = async () => {
    setIsHovering(true);
    if (!skillDescription) {
      try {
        getSkillInfo(props.text).then((skillInfo) => {
          setSkillDescription(skillInfo);
        });
      } catch (error) {
        console.error("Failed to fetch skill info:", error);
        setSkillDescription("Unable to load skill information.");
      }
    }
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <div
      className={classNames}
      onClick={props.handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{
          marginTop: "2px",
          width: props.text === "" ? "0px" : "20px",
          marginRight: props.text === "" ? "0px" : "5px",
        }}
      >
        {renderIcon() ?? (props.text !== "" ? <img src={DefaultSkillIcon} /> : null)}
      </div>
      <label>{props.text}</label>
      {isHovering && props.disableEditing ? (
        <div
          className={`
          absolute top-full left-0 z-50
          bg-op-dark
          text-white
          border border-gray-200 dark:border-gray-700
          rounded-md shadow-md
          p-2 mt-1
          max-w-xs text-sm
          whitespace-normal
        `}
        >
          {skillDescription}
        </div>
      ) : null}
    </div>
  );
};

export default EditorSkillTagButton;
