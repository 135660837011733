import type React from "react";

interface WithTailwindClassProps {
  className?: string;
}

const withCustomStyling = <P extends object>(
  WrappedComponent: React.ComponentType<P>
): React.FC<P & WithTailwindClassProps> => {
  return ({ className, ...props }: WithTailwindClassProps & P) => {
    return (
      <div className={className}>
        <WrappedComponent {...(props as P)} />
      </div>
    );
  };
};

export default withCustomStyling;
