import { format } from "@formkit/tempo";

interface Props {
  createdAt: string;
}

export const CreatedAt = ({ createdAt }: Props) => (
  <p className="italic md:not-italic md:font-semibold text-sm">
    {format(createdAt, { date: "medium", time: "short" })}
  </p>
);

CreatedAt.displayName = "CreatedAt";
