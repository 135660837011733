import { useEffect, useState } from "react";
import type { InviteResponseBody } from "../types";

import SiteMenu from "../components/SiteMenu";
import Button, { ButtonStyleType } from "../components/atoms/Button";
import { fetchInvite, acceptOrganizationInvite } from "../requests";
import Lottie from "react-lottie";
import animationData from "../assets/spinner-animation.json";
import type { AxiosError } from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

interface RouteProps {
  inviteCode?: string;
}

function ManagerRequestPage({ inviteCode }: RouteProps) {
  const navigate = useNavigate();
  const [inviteResponseBody, setInviteResponseBody] = useState<InviteResponseBody | null>(null);

  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const navigateToStartPage = () => {
    const currentOrgId = localStorage.getItem("current-org-id");
    if (currentOrgId !== null && currentOrgId !== "") {
      navigate("/org/" + currentOrgId);
      return;
    }

    const currentProfileId = localStorage.getItem("current-profile-id");
    navigate("/profiles/" + currentProfileId);
  };

  useEffect(() => {
    if (!inviteCode) return;
    fetchInvite(inviteCode)
      .then((inviteResponseBody: InviteResponseBody) => {
        setInviteResponseBody(inviteResponseBody);
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status === 401) {
          localStorage.removeItem("profiletool-auth-token");
        }
      });
  }, [inviteCode]);

  if (inviteResponseBody === null) {
    return (
      <div className="flex flex-1 h-[800px] flex-col justify-center align-center ">
        <div>
          <Lottie style={{ width: 80 }} options={{ animationData: animationData }} />
          <label className="font-bold">Grabbing that invite for you...</label>
        </div>
      </div>
    );
  }

  return (
    <div>
      <SiteMenu currentPath="dashboard" isSignedIn={true} />

      <div className="container mx-auto flex flex-col max-w-[700px] pt-10">
        <h3 className="text-2xl font-bold mt-4">
          {`${inviteResponseBody.inviterName} have invited you to join ${inviteResponseBody.organization.name}`}
        </h3>

        {isAuthenticated ? (
          <div className="flex flex-row w-full flex-1 mt-6 justify-end">
            <div>
              <Button
                text="No thanks"
                style={ButtonStyleType.secondary}
                onButtonClick={() => {
                  navigateToStartPage();
                }}
              />
            </div>
            <div className="min-w-[20px]" />
            <div>
              <Button
                text="Approve"
                style={ButtonStyleType.primary}
                onButtonClick={() => {
                  if (!inviteCode) return;
                  acceptOrganizationInvite(inviteCode).then((orgId: string) => {
                    navigate("/organizations/" + orgId);
                  });
                }}
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-row w-full flex-1 mt-6 justify-end">
            <div>
              <Button
                text="Sign in to continue"
                style={ButtonStyleType.primary}
                onButtonClick={() => {
                  if (inviteCode) {
                    localStorage.setItem("op-pending-org-invite-code", inviteCode);
                  }
                  loginWithRedirect();
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ManagerRequestPage;
