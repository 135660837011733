import type React from "react";
import withCustomStyling from "./HOC/withCustomStyling";
import AvatarImage from "./AvatarImage";
import type { UserProfile } from "../types";

interface ProfileCardProps {
  userProfile: UserProfile;
  onClick: () => void;
  selected: boolean;
  secondaryText?: string;
}

const ProfileCard: React.FC<ProfileCardProps> = ({ userProfile, onClick, selected }) => {
  return (
    <div
      className={`flex items-center p-4 rounded-lg cursor-pointer transition-shadow
        ${selected ? "bg-gray-300 text-gray-800" : "text-gray-800 hover:bg-gray-200"}`}
      onClick={onClick}
    >
      <AvatarImage photoId={userProfile.profilePhotoId} />
      <div className="flex flex-col flex-1 ml-4">
        <span className="text-md font-medium flex-1">{userProfile.profileData.name}</span>
        {userProfile.profileData.title != "" ? (
          <span className="text-sm font-medium flex-1">{userProfile.profileData.title}</span>
        ) : null}
      </div>
    </div>
  );
};

export default withCustomStyling(ProfileCard);
