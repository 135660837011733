import { useState, type FocusEvent, type CSSProperties, useRef } from "react";
import { darkModeBlack } from "../colors";
import type { ProfileComponentProps } from "../types";
import "./components.css";
interface EditorTextFieldProps extends ProfileComponentProps {
  placeholder: string;
  value?: string;
  onLeaveField: (input: string) => void;
  onChange?: (input: string) => void;
  onLeaveValidatedField?: (input: string, isValid: boolean) => void;
  styles?: CSSProperties;
  inputStyles: CSSProperties;
  maxLenght?: number;
  showValidation?: boolean;
  validationRules?: string[];
  autofocus?: boolean;
  onTextFieldFocus?: (inputType: string) => void;
  inputType?: string;
  useDynamicWidth?: boolean;
  disableHoverUnderline?: boolean;
  disableEditing?: boolean;
  blurOnEnter?: boolean;
  validationError?: string;
  handleEnterClick?: (input: string, isValid: boolean) => void;
  defaultDynamicWeight?: number;
}

const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

function EditorTextField({
  value,
  onLeaveField,
  onLeaveValidatedField,
  onChange,
  onTextFieldFocus,
  placeholder,
  styles,
  inputStyles,
  maxLenght,
  showValidation,
  validationRules,
  isDarkModeActive,
  autofocus,
  inputType,
  useDynamicWidth,
  disableHoverUnderline,
  disableEditing,
  blurOnEnter,
  validationError,
  handleEnterClick,
  defaultDynamicWeight,
}: EditorTextFieldProps) {
  const [currentInput, setCurrentInput] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [isMouseHovering, setIsMouseHovering] = useState<boolean>(false);
  const [isCurrentFocus, setIsCurrentFocus] = useState<boolean>(false);

  const taRef = useRef<any>();

  const onInputFocus = (e: FocusEvent<any>) => {
    //bottomBorderLine.current.classList.add("vk-bottom-border-line-animate");
    //bottomBorderLine.current.classList.remove("vk-bottom-border-line-animate-blur");
    if (value) {
      setCurrentInput(value);
    }

    if (onTextFieldFocus && inputType) {
      onTextFieldFocus(inputType);
    }

    setIsCurrentFocus(true);
  };

  const handleEnterKey = (onKeyDownEvent: React.KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDownEvent.key === "Enter") {
      onKeyDownEvent.preventDefault();

      setIsCurrentFocus(false);

      if (validationRules !== null && validationRules !== undefined && validationRules.length > 0) {
        if (handleEnterClick) {
          const inputIsValid = validate(currentInput ?? "", validationRules);
          handleEnterClick(currentInput ?? "", inputIsValid);
        }
      } else {
        if (handleEnterClick) {
          handleEnterClick(currentInput ?? "", false);
        }
      }
      setCurrentInput(null);
    }
  };

  const onBlur = (e: FocusEvent<any>) => {
    //bottomBorderLine.current.classList.remove("vk-bottom-border-line-animate");
    //bottomBorderLine.current.classList.add("vk-bottom-border-line-animate-blur");

    setIsCurrentFocus(false);

    if (validationRules !== null && validationRules !== undefined && validationRules.length > 0) {
      const inputIsValid = validate(currentInput ?? "", validationRules);
      if (onLeaveValidatedField !== undefined) {
        onLeaveValidatedField(currentInput ?? "", inputIsValid);
      }
    } else {
      onLeaveField(currentInput ?? "");
    }
    setCurrentInput(null);
  };

  const validate = (input: string, rules: string[]) => {
    const validationErrors: string[] = [];

    for (var i = 0; i < rules.length; i++) {
      const validationRule = rules[i];
      if (validationRule === "NOT-EMPTY" && input === "") {
        validationErrors.push("Email cannot be empty");
      }
      if (validationRule === "EMAIL" && !emailRegex.test(input)) {
        validationErrors.push("Invalid email format");
      }
    }

    setValidationErrors(validationErrors);
    return validationErrors.length === 0;
  };

  const borderColor = isDarkModeActive ? "#fff" : darkModeBlack;
  var containerStyles = { ...styles };
  if (useDynamicWidth) {
    let activeInput = currentInput ? currentInput.length : value?.length;
    if (activeInput !== undefined && activeInput < (defaultDynamicWeight ?? 4)) {
      activeInput = defaultDynamicWeight ?? 4;
    }
    containerStyles.width = activeInput + "ch";
  }

  if (disableEditing) {
    inputStyles.pointerEvents = "none";
  }

  const showValidationError = () => {
    if (validationErrors.length > 0) {
      return (
        <div>
          {validationErrors.map((error, index) => {
            const suffix = index < validationErrors.length - 1 ? ", " : "";
            return <label style={{ color: "red", fontSize: "12px" }}>{error + suffix}</label>;
          })}
        </div>
      );
    }

    if (validationError && validationError !== "") {
      return <label style={{ color: "red", fontSize: "12px" }}>{validationError}</label>;
    }
  };

  return (
    <div className="op_textfield">
      <div
        style={containerStyles}
        onMouseEnter={() => setIsMouseHovering(true)}
        onMouseLeave={() => setIsMouseHovering(false)}
      >
        <input
          className="editor_textfield_input"
          style={{
            backgroundColor: "transparent",
            color: isDarkModeActive ? "white" : "black",
            borderImage: "none",
            borderWidth: 0,
            borderStyle: "none",
            borderColor: "#202124",
            outline: "none",
            ...inputStyles,
          }}
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          onFocus={onInputFocus}
          onBlur={onBlur}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (blurOnEnter) {
              handleEnterKey(e);
            }
          }}
          onChange={(inputEvent) => {
            setCurrentInput(inputEvent.target.value);
            if (onChange) {
              onChange(inputEvent.target.value);
            }
          }}
          maxLength={maxLenght ?? 100}
          placeholder={placeholder}
          value={currentInput ?? value}
          autoComplete="chrome-off"
          autoFocus={autofocus}
          ref={taRef}
        />
      </div>
      {isMouseHovering && !disableHoverUnderline ? (
        <div style={{ minHeight: 1, width: "100%", background: borderColor }} />
      ) : null}
    </div>
  );
}
export default EditorTextField;
