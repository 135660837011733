import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import React, { useState } from "react";
import { darkModeBlack } from "../../colors";
import Button, { ButtonStyleType } from "../atoms/Button";
import "../components.css";
import MonthYearSelect from "../MonthYearSelect";
interface Props {
  open: boolean;
  darkModeActive: boolean;
  onBackdropClick: () => void;
}

const DateInputDialog = (props: Props) => {
  const [selectedMonthValue, setSelectedMonthValue] = useState<string>("");
  const [selectedYearValue, setSelectedYearValue] = useState<string>("");

  return (
    <Dialog
      open={props.open}
      onClose={(event: object, reason: string) => {
        props.onBackdropClick();
      }}
      fullWidth
    >
      <DialogTitle
        style={{
          backgroundColor: props.darkModeActive ? darkModeBlack : "#fff",
          color: props.darkModeActive ? "#fff" : darkModeBlack,
        }}
      >
        Date
      </DialogTitle>
      <DialogContent>
        <MonthYearSelect
          isEndDateSelector={false}
          firstOption="Start Date"
          label="When did you work on this project?"
          onMonthSelect={(selectedMonth: string) => setSelectedMonthValue(selectedMonth)}
          onYearSelect={(selectedYear: string) => {
            setSelectedYearValue(selectedYear);
          }}
          selected={selectedYearValue + "-" + selectedMonthValue}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <Button text="Cancel" onButtonClick={() => {}} style={ButtonStyleType.danger} />
          <Button text="Save" onButtonClick={() => {}} style={ButtonStyleType.primary} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DateInputDialog;
