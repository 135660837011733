import type { UserProfile } from "../../../../../client";
import { Image } from "../../../../../components";
import { cn } from "../../../../../components/utils/cn";
import { getImageUrl } from "../../../../../utils/image";

interface Props extends UserProfile {
  selected: boolean;
  onClick: () => void;
}

export const MemberItem = ({ profileData, profilePhotoId, selected, onClick }: Props) => (
  <button
    type="button"
    className={cn(
      "flex flex-row gap-2 items-center max-w-fit p-4 hover:bg-gray-2 rounded-2xl border border-transparent transition-all active:scale-95",
      selected && "bg-gray-3 border-gray-4"
    )}
    onClick={onClick}
  >
    <Image
      src={profilePhotoId ? getImageUrl(profilePhotoId) : undefined}
      alt={typeof profileData.name === "string" ? profileData.name : "Profile"}
      className="rounded-xl w-10 h-10 flex-shrink-0"
    />
    <p className="text-sm truncate">
      {typeof profileData.name === "string" ? profileData.name : "Unknown"}
    </p>
  </button>
);

MemberItem.displayName = "MemberItem";
