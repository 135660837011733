import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchGuestUserOverview } from "../requests";
import type {
  ProfilePreview,
  ProfileVisitDisplayModel,
  Profile,
  GuestUserDynamicProfile,
} from "../types";
import EditorTextField from "../components/EditorTextField";
import VkProfilePicture from "../components/legacy/VkProfilePicture";

function GuestUserOverview() {
  const navigate = useNavigate();

  const [accountProfileId, setAccountProfileId] = useState<string>("");
  const [guestUserDynamicProfile, setGuestUserDynamicProfile] = useState<GuestUserDynamicProfile[]>(
    []
  );
  const [darkModeActive, setDarkModeActive] = useState<boolean>(false);
  const [displayProfilePicture, setDisplayProfilePicture] = useState<boolean>(false);
  const [newProfileLinkName, setNewProfileLinkName] = useState<string>("");
  const [isOnboarding, setIsOnboarding] = useState<boolean>(false);
  const [profilePreviews, setProfilePreviews] = useState<ProfilePreview[]>([]);
  const [profileVisitDisplayModels, setProfileVisitDisplayModels] = useState<
    ProfileVisitDisplayModel[]
  >([]);
  const [isAnalyticsPanelOpen, setIsAnalyticsPanelOpen] = useState<boolean>(false);
  const [isNewPaneLinkVisisble, setIsNewPaneLinkVisisble] = useState<boolean>(false);
  const [profile, setProfile] = useState<Profile | null>(null);

  useEffect(() => {
    fetchGuestUserOverview().then((guestUserDynamicProfiles) => {
      setGuestUserDynamicProfile(guestUserDynamicProfiles);
    });
  }, []);

  return (
    <div>
      <label>hello</label>
      {guestUserDynamicProfile.map((profile, index) => {
        return (
          <div key={index}>
            <div className="flex flex-row justify-between">
              <div>
                {profile.profilePictureImageBase64 != "" ? (
                  <VkProfilePicture
                    isEditable={false}
                    onShowCropTool={() => {}}
                    imageString={profile.profilePictureImageBase64}
                    roundMode={false}
                  />
                ) : (
                  <span />
                )}

                <div
                  className={
                    "flex flex-col justify-center min-h-[100%] " +
                    (profile.profilePictureImageBase64 != "" ? "pl-2" : "")
                  }
                >
                  <EditorTextField
                    disableEditing={false}
                    disableHoverUnderline={true}
                    isDarkModeActive={false}
                    placeholder="Your full name"
                    onLeaveField={() => {}}
                    inputStyles={{ fontSize: 18, fontWeight: 600 }}
                    value={profile.name}
                  />
                </div>
              </div>
            </div>
            {profile.guestUserProfileLinks.map((guestUserProfileLink) => {
              return (
                <div
                  className="flex flex-row flex-1 py-2 justify-between text-center border-b-2 border-b-op-dark cursor-pointer hover:bg-gray-200"
                  onClick={() => {
                    navigate("/p/" + guestUserProfileLink.link);
                  }}
                  key={index}
                >
                  <div className="flex-1">
                    <p>{guestUserProfileLink.config.name}</p>
                  </div>
                  <div className="flex-1">
                    <p>{guestUserProfileLink.link}</p>
                  </div>
                  <div className="flex-1">
                    <p>{guestUserProfileLink.config.profileLinkProjects.length}</p>
                  </div>

                  <div className="flex-1">
                    <p>{guestUserProfileLink.totalVisitCount}</p>
                  </div>

                  <div className="flex-1">
                    <p>{guestUserProfileLink.visitCount}</p>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default GuestUserOverview;
